import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';

import CurrentNews from './CurrentNews';
import News from './News';
import Articles from './Articles';
import KajaveLinks from '../Common/KajaveLinks';

// Types.
import { NewsCollectionProps } from '../../types/news';


const NewsCollection: React.FunctionComponent<NewsCollectionProps> = (props: NewsCollectionProps) => {
  const { t } = useTranslation([]);
  const { news, articles } = props;
  const location = useLocation();
  const subLocation = location.pathname.split('/')[2];

  let subPage = (<News benefits = { false } teaser = { false } news = { news } />);

  if (subLocation === 'benefits') {
    subPage = (<News benefits = { true } teaser = { false } news = { news } />);
  }
  else if (subLocation === 'articles') {
    subPage = (<Articles teaser = { false } news = { articles }  />);
  }

  return (
    <div className="row">
        <KajaveLinks />
    </div>
  );

}

export default NewsCollection;
