// Actions.
import { getChildCustomerDetails } from '../actions';
// Types.
import { CustomerRoleChild, DetailedChildUser } from '../types/customer';


const fetchCustomerChildrenDetails = async (children: CustomerRoleChild[], endpoint: string): Promise<CustomerRoleChild[]> => {

  const returnChildren = await Promise.all(children.map(async (child): Promise<CustomerRoleChild>  => {

    const childDetails: DetailedChildUser = await getChildCustomerDetails(child.customerIdChild, endpoint);
    child = {
      ...child,
      email: childDetails.email,
      mobileNumber: childDetails.number,
      address: childDetails.address,
      endpoint: endpoint
    }
    return child;
  }));

  return returnChildren
}

export const combineMyInfoChildren = async (ret: CustomerRoleChild[], dso: CustomerRoleChild[], dh: CustomerRoleChild[]): Promise<CustomerRoleChild[]> => {

  const retChildren = await fetchCustomerChildrenDetails(ret, 'ret');
  const dsoChildren = await fetchCustomerChildrenDetails(dso, 'dso');
  const dhChildren = await fetchCustomerChildrenDetails(dh, 'dh');

  const customerRoleChildren = retChildren.concat(dsoChildren, dhChildren);

  return customerRoleChildren;
}