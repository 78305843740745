import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import liftupImage from '../../images/liftup-default-2.jpg';

const SidebarLiftup: React.FunctionComponent = () => {
  const { t } = useTranslation([]);

  return (
    <div className="liftup sidebar direction-column contact">
      <div className="top">
        <img src={liftupImage} alt=""/>
      </div>
      <div className="bottom">
        <h3> { t('customerService:contactLiftUp.title') } </h3>
        <p>{ t('customerService:contactLiftUp.p1') }</p>
        <p>
          { t('customerService:contactLiftUp.p2_1') }
          <Link to={t('customerService:contactLiftUp.p2_link1_internalurl')}>{ t('customerService:contactLiftUp.p2_link1_txt') }</Link>
          { t('customerService:contactLiftUp.p2_2') }
          <Link to={t('customerService:contactLiftUp.p2_link2_internalurl')}>{ t('customerService:contactLiftUp.p2_link2_txt') }</Link>
          { t('customerService:contactLiftUp.p2_3') }
        </p>
      </div>
    </div>
  );
}

export default SidebarLiftup;