import { AxiosResponse } from 'axios';

import API from '../Api';
// Types:
import { loginCustomer } from '../types/customer';


export const validateToken = async (): Promise<loginCustomer> => {

  const customer: AxiosResponse<loginCustomer> = await API({
      method: 'get',
      url: `/validateToken/`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customer.data;
}
