import { CustomerAddress, MeteringPointAddress } from '../types/address';

export const generateAddress = (addressSource: MeteringPointAddress | CustomerAddress, onlyAddress: boolean, invoiceComment?: string): string => {

  let addressDetails = [
    addressSource.streetName,
    addressSource.houseNumber,
    addressSource.houseLetter,
    addressSource.residence,
    addressSource.zipCode,
    addressSource.cityName
  ];

  if(onlyAddress) {
    addressDetails = addressDetails.splice(0, 4);
  }

  let address = '';

  for (const component of addressDetails) {
    if (component) {
      address += component + ' ';
    }
  };

  if (invoiceComment) {
    address += '(' + invoiceComment + ')';
  }

  return address;
}
