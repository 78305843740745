import React from 'react';
import { useLocation } from '@reach/router';

import InfoBlock from './InfoBlock';
import NewsCollection from './NewsCollection';
import Loading from '../Common/Loading';
import SubMenu from '../Common/SubMenu';
// Types.
import { NewsContainerProps } from '../../types/news';
// Actions.
import { fetchNews, fetchArticles } from '../../actions';


const NewsContainer:  React.FunctionComponent<NewsContainerProps> = (props: NewsContainerProps) => {
  const [ prevEndPoint, setPrevEndPoint ] = React.useState('');
  const [ prevUserId, setPrevUserId ] = React.useState(0);
  const { currentUser, newsDetails } = props;
  const { activeUser } = currentUser.users;
  const { userId, endpoint } = activeUser;
  const { news, setNews, articles, setArticles } = newsDetails;
  const location = useLocation();
  const mainLocation = location.pathname.split('/')[1];

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchAvailableNews(): Promise<void> {

      const news = await fetchNews(userId, endpoint);

      const sortedNews = news.sort((a, b) => {
        const aDate = new Date(a.publishDate);
        const bDate = new Date(b.publishDate);
        if(aDate < bDate) return 1;
        if(aDate > bDate) return -1;

        return 0;
      });

      setNews(sortedNews);
    }
    if (endpoint !== prevEndPoint || userId !== prevUserId) {
      setPrevUserId(userId);
      setPrevEndPoint(endpoint);
      // Execute the created function directly
      fetchAvailableNews();
    }
  }, [userId, endpoint, news, prevEndPoint, prevUserId, setNews]);

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchAvailableArticles(): Promise<void> {

      const articles = await fetchArticles(userId, endpoint);

      setArticles(articles);
    }
    if (endpoint !== prevEndPoint || userId !== prevUserId) {
      setPrevUserId(userId);
      setPrevEndPoint(endpoint);
      // Execute the created function directly
      fetchAvailableArticles();
    }
  }, [userId, endpoint, articles, prevEndPoint, prevUserId, setArticles]);

  let toDisplay: JSX.Element = (<div></div>);

  if(mainLocation === 'news' && (!news || !articles)) { return <Loading />; }
  if (news && articles) {
    switch (mainLocation) {
      case 'news':
        toDisplay = (<><SubMenu endpoint={endpoint} /><NewsCollection news = { news } articles = { articles } /></>);
        break;
      default:
        toDisplay = (<InfoBlock currentUser={ currentUser } news = { news } articles = { articles } />);
    };
  }

  return(
    <>
      { toDisplay }
    </>
  );
}

export default NewsContainer;
