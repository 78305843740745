import React from 'react';
import { useTranslation } from 'react-i18next';

import Barcode from './Barcode';
// Types.
import { InvoiceDetailsObject } from '../../types/consumptionRates';
import { Ledger } from '../../types/billing';
// Utils.
import { getVirtualBarCode } from '../../utils/billing';

type barCodeProps = {
  invoiceDetails: InvoiceDetailsObject;
  ledgerDetails: Ledger;
}

const LatestInvoiceBarCode:  React.FunctionComponent<barCodeProps> = (props: barCodeProps) => {
  const { t } = useTranslation([]);
  const { invoiceDetails, ledgerDetails } = props;
  const [ copyButtonText, setCopyButtonText ] = React.useState<string>(t('billing:barcode.copy'));

  let toDisplay = (<p>{t('billing:barcode.issue')}</p>);

  const code = getVirtualBarCode(ledgerDetails.BankAccount, invoiceDetails.amount, invoiceDetails.reference, invoiceDetails.invoiceDue);

  // The string passed to generate the barcode needs to be exactly 54 characters long.
  if (code.length === 54) {
    const copyToClipboard = (str: string): void => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopyButtonText(t('billing:barcode.copied'));
      setTimeout((): void => {
        setCopyButtonText(t('billing:barcode.copy'));
      }, 3000);
    }
    toDisplay = <><Barcode code = { code } /><button className="button button-white button-small center" onClick={(): void => copyToClipboard(code)}>{copyButtonText}</button></>
  }

  return toDisplay;
}

export default LatestInvoiceBarCode;
