import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import UserInformationMobile from './UserInformationMobile';
import NewsContainer from '../News/NewsContainer';
import Register from './Register';
import Loading from '../Common/Loading';
import {useWindowSize} from '../Navigation/Header';
// Types.
import { Contract } from '../../types/contract';
import { SubLandingPageProps } from '../../types/customer';
// Images.
import person from '../../images/greetingPerson.jpg';
import { Link } from '@reach/router';
// Actions.
import { fetchContractDetails } from '../../actions';
// Utils.
import { filterGreetingsContracts, getContractDateTo, contractRename } from '../../utils/contracts';
import { gaEventIfAllowed } from '../../utils/googleAnalytics';


const Greetings: React.FunctionComponent<SubLandingPageProps> = (props: SubLandingPageProps) => {
  const { t } = useTranslation([]);
  const [ contractToShow, setContractToShow ] = React.useState<string | undefined>();
  const [ dateToShow, setDateToShow ] = React.useState<string | undefined>();
  const { currentUser, newsDetails, userHasToken } = props;
  const { activeUser } = currentUser.users;
  const { mobileUserSwap, toggleMobileUserSwap } = props;

  React.useEffect(() => {
    let unMounted = false;
    async function fetchContracts(): Promise<void> {
      let contracts: (Contract | undefined)[] = await fetchContractDetails(activeUser.userId, activeUser.endpoint);
      if(activeUser.userId) {
        const contractsRet = await fetchContractDetails(activeUser.userId, 'ret');
        contracts = contracts.concat(contractsRet);
      }
      if(activeUser.userIdDSO) {
        const contractsDso = await fetchContractDetails(activeUser.userIdDSO, 'dso');
        contracts = contracts.concat(contractsDso);
      }
      if(activeUser.userIdDH) {
        const contractsDh = await fetchContractDetails(activeUser.userIdDH, 'dh');
        contracts = contracts.concat(contractsDh);
      }
      const desiredContract = filterGreetingsContracts(contracts, activeUser.endpoint);
      let desiredDate;

      if (desiredContract) {
        desiredDate = getContractDateTo(desiredContract);

        if (moment(desiredDate).isSameOrAfter('2073-01-06')) {
          if (activeUser.endpoint === 'ret') {
            desiredDate = t('billing:billing.forNow');
          }
          else {
            desiredDate = undefined;
          }
        }
      }
      if(!unMounted) {
        setDateToShow(desiredDate);

        if (desiredContract) {
          const groupName = contractRename(desiredContract.contractGroup.contractGroupName);

          setContractToShow(groupName);
        }
        else {
          setContractToShow(t('contracts:contracts.noActiveContracts'));
        }
      }
    }

    if(!unMounted) {
      if (activeUser.userId !== 0) {
        setDateToShow(undefined);
        setContractToShow(undefined);
        fetchContracts();
      }
      else {
        setContractToShow(t('contracts:contracts.noOwnAccountShort'));
      }
    }

    return (): void => {
      unMounted = true;
    };
  }, [activeUser.userId, activeUser.userIdDSO, activeUser.userIdDH, activeUser.endpoint, t]);

  let userIdentified = false;
  let userAddress = false;
  if (activeUser.userName !== '') {
    userIdentified = true;
    if (activeUser.userAddress.length > 0) {
      userAddress = true;
    }
  }

  const windowWidth = useWindowSize()[0];

  const isMobile = windowWidth < 768;

  let greetings = (
    <div className="right bg-white">
      <p>{ t('common:greetings.datahubLink') } <a href="https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/" target="_blank" rel="noopener noreferrer">https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/</a></p>
      <p>{ t('common:greetings.loginMessage') }</p>
      <Loading />
      <p className="login-bottom-links">
        <a href={`${process.env.REACT_APP_BASE_URL_LOGIN}/changepwd`} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Login sivun klikkaukset',
              action: 'Vaihda salasana'
            });
          }}>{ t('landingPage:login.changePW') } &rsaquo;</a>
        <a href={`${process.env.REACT_APP_BASE_URL_LOGIN}/lostpwd`} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Login sivun klikkaukset',
              action: 'Unohtuiko tunnus'
            });
          }}>{ t('landingPage:login.forgotPW') } &rsaquo;</a>
        <Link to="/customerService/faq">{ t('landingPage:login.faqLink') } &rsaquo;</Link>
      </p>
    </div>
  );

  if (!userHasToken) {
    greetings = (
      <div className="right bg-white">
        <p>{ t('common:greetings.datahubLink') } <a href="https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/" target="_blank" rel="noopener noreferrer">https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/</a></p>
        <p>{ t('common:greetings.loginMessage') }</p>
        <iframe id="login-iframe" title="login" width="100%" height="322px" src={ process.env.REACT_APP_LOGIN_URL } sandbox="allow-same-origin allow-top-navigation allow-scripts allow-forms">
        </iframe>
        <p className="login-bottom-links">
          <a href={`${process.env.REACT_APP_BASE_URL_LOGIN}/changepwd`} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Login sivun klikkaukset',
              action: 'Vaihda salasana'
            });
          }}>{ t('landingPage:login.changePW') } &rsaquo;</a>
          <a href={`${process.env.REACT_APP_BASE_URL_LOGIN}/lostpwd`} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Login sivun klikkaukset',
              action: 'Unohtuiko tunnus'
            });
          }}>{ t('landingPage:login.forgotPW') } &rsaquo;</a>
          <Link to="/customerService/faq">{ t('landingPage:login.faqLink') } &rsaquo;</Link>
        </p>
      </div>
    );
  }
  else if (userHasToken && userIdentified) {
    greetings = (
      <div className="right">
        <h3>{ t('common:greetings.contracts') }</h3>
        { userAddress && <p>{ activeUser.userAddress[0].streetAddress }</p>}
        { userAddress && <p>{ activeUser.userAddress[0].cityDetails }</p>}
        {contractToShow && <p>{ contractToShow }</p>}
        {dateToShow && dateToShow !== t('billing:billing.forNow') && <p>{ `${t('common:greetings.valid')} ${dateToShow} ${t('common:greetings.until')}` }</p>}
        {dateToShow === t('billing:billing.forNow') && <p>{ t('billing:billing.forNow') }</p>}
        {!contractToShow && <Loading size = 'small' />}
        <Link to="/contracts" className='button button-white button-small'>{ t('common:greetings.viewContracts') }</Link>
        <Link to="/myinfo" className='button button-white button-small'>{ t('common:greetings.updateMyInfo') }</Link>
        <Link to='/customerService/faq/moving' className='button button-white button-small'>{ t('common:greetings.moving') }</Link>
      </div>
    );
  }

  /**
   * For now is created separate blocks that are showing in mobile and desktop
   * - userInformationDesktop shows Greetings box on desktop view
   * - userInformationMobile shows accordion element with user information on mobile
   * */
  let missingEmail = false;
  let missingNumber = false;
  if (!activeUser.userEmail) {
    missingEmail = true;
  }
  if (!activeUser.userNumber) {
    missingNumber = true;
  }
  let shouldCheckDetails = false;
  // If the user has details for DSO endpoints.
  if (!missingEmail && !missingNumber && activeUser.userDetailsDSO && activeUser.userId !== 0 && activeUser.userIdDSO !== 0) {
    // We check to see if the contact details are different.
    if (activeUser.userDetailsDSO.email !== activeUser.userEmail || activeUser.userDetailsDSO.number !== activeUser.userNumber) {
      // If they are we want to direct the user to check their info on the myInfo page.
      shouldCheckDetails = true;
    }
  }
  const userInformationDesktop = (
    <div className="col-6 col-lg-7">
      <div className="person">
        <div className='person-crop'>
          <img src={person} alt=""></img>
        </div>
        <div className="welcome">
          <span className="text-welcome1">{t('common:greetings.welcome')}</span>
          {userIdentified && <span className="text-welcome2">{ activeUser.userName }</span>}
          {userIdentified && 
            <div className="user-details">
              <span>{activeUser.userEmail}</span>
              <span>{activeUser.userNumber}</span>
              {shouldCheckDetails && <Link to="/myinfo">{t('common:greetings.contactIssue')}</Link>}
              {missingEmail && missingNumber && <Link to="/myinfo">{t('common:greetings.missingBoth')}</Link>}
              {missingEmail && !missingNumber && <Link to="/myinfo">{t('common:greetings.missingEmail')}</Link>}
              {!missingEmail && missingNumber && <Link to="/myinfo">{t('common:greetings.missingNumber')}</Link>}
            </div>
          }
          {!userIdentified && <span className="text-welcome2">{ t('common:greetings.omaEnergiaLink')}</span>}
        </div>
      </div>
      { greetings }
    </div>
  );

  /**
   * Details accordion element which shows by clicking accordion-header element
   */
  const details = (
    <div className="accordion-content">
      <p>
        { userAddress && <>{activeUser.userAddress[0].streetAddress}<br /></> }
        { userAddress && <>{activeUser.userAddress[0].cityDetails}<br /></> }
        { activeUser.userEmail && <>{activeUser.userEmail}<br /></> }
        { activeUser.userNumber && <>{activeUser.userNumber}</> }
      </p>

      {contractToShow && <p>{ contractToShow }</p>}
      {dateToShow && dateToShow !== t('billing:billing.forNow') && <p>{ `${t('common:greetings.valid')} ${dateToShow} ${t('common:greetings.until')}` }</p>}
      {dateToShow === t('billing:billing.forNow') && <p>{ t('billing:billing.forNow') }</p>}
      {!contractToShow && <Loading size = 'small' />}

      <Link to="/contracts" className='button button-white button-small'>{ t('common:greetings.viewContracts') }</Link>
      <Link to="/myinfo" className='button button-white button-small'>{ t('common:greetings.updateMyInfo') }</Link>
      <Link to='/customerService/faq/moving' className='button button-white button-small'>{ t('common:greetings.moving') }</Link>
      <Link to="/" className='button button-green button-small' onClick={(): void => toggleMobileUserSwap(!mobileUserSwap)}>{t('header:functional.swapUser')}</Link>
    </div>
  );

  const [ activeTab, setActiveTab] = React.useState('login');
  
  return(
    <div className={isMobile ? "greetings greetings-mobile col" : "greetings col"}>

      {/* If user is not logged tabs will show for login and register form in mobile view*/}
      { isMobile && !userIdentified &&
        <div className='tab-container green-tabs col'>
          <div className='tabs'>
            <div className={activeTab === 'login' ? 'tablink active': 'tablink'} onClick={(): void => setActiveTab('login')}> <h2> { t('common:register.loginLink') } </h2></div>
            <div className={activeTab === 'register' ? 'tablink active': 'tablink'} onClick={(): void => setActiveTab('register')}> <h2> { t('common:register.registerLink') } </h2></div>
          </div>
        </div>
      }

      {/* On mobile user information dropdown is shown and login/register on different tabs */}
      { isMobile && userIdentified && <UserInformationMobile userName = { activeUser.userName } userNumber = { activeUser.userId } details = { details } />}
      { isMobile && !userIdentified && activeTab === 'login' && greetings }
      { isMobile && activeTab === 'register' && <Register /> }
      {/* Desktop view there is no tabs and block are display side by side */}
      { !isMobile && userInformationDesktop}
      { !isMobile && !userIdentified && <Register /> }
      {newsDetails && <NewsContainer currentUser = { currentUser } newsDetails = { newsDetails } />}
    </div>
  );
}

export default Greetings;
