import React from 'react';
import { useTranslation } from 'react-i18next';

import { flipcardProps } from '../../types/flipcards';


/**
 * Flipcard component.
 * This component shows multiple sub components in order when a next button is pressed or goes back to the first.
 */
const Flipcard: React.FunctionComponent<flipcardProps> = (props: flipcardProps) => {
  const [ currentCard, setCurrentCard ] = React.useState(0);
  const { t } = useTranslation([]);
  
  const totalElements = props.components.length - 1;

  let topElement;
  if (props.components[currentCard].text) {
    topElement = props.components[currentCard].text;
  }
  else {
     topElement = <button onClick = {(): void => setCurrentCard(0)}>First card</button>
  }

  let bottomElement;
  let bottomLeft;
  if (currentCard >= totalElements) {
    if (props.components[currentCard].goTo) {
      bottomElement = props.components[currentCard].goTo;
    }
    else if (totalElements > 0) {
      bottomElement = <button className="button button-white button-small center" onClick = {(): void => setCurrentCard(0)}>Back</button>
    }
  }
  else {
    bottomElement = <button className="button button-white button-small center" onClick = {(): void => setCurrentCard(currentCard + 1)}> {t('common:flipcard:flipcardLink')} </button>
  }

  if (props.components[currentCard].goToLeft) {
    bottomLeft = props.components[currentCard].goToLeft;
  }

  // Background color are handled from here. Notice that inner class should always be shown.
  // That is the reason for else statement
  let bgColor;
  if (props.components[currentCard].bgColor) {
    bgColor = props.components[currentCard].bgColor + ' inner'
  }
  else {
    bgColor = 'inner'
  }

  

  return (
    <div className="liftup flipcard direction-column col-6">
      <div className={bgColor} aria-live="polite">
        {topElement}
        {props.components[currentCard].component}
        {bottomLeft}
        {bottomElement}
      </div>
    </div>
  
  );
}

export default Flipcard;
