import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@reach/router';

type SubMenuProps = {
  endpoint: string;
};

type LinkProps = {
  to: string;
}
type NavLinkProps = {
  className: string;
}

const NavLink: any = (props: LinkProps) => ( // eslint-disable-line
  <Link
    {...props}
    getProps={({ isCurrent }): NavLinkProps => {
      return {
        className: isCurrent ? 'active' : ''
        }
      }
    }
  />
);

const SubMenu: React.FunctionComponent<SubMenuProps> = (props: SubMenuProps) => {
  const { t } = useTranslation([]);
  const { endpoint } = props;

  const location = useLocation();
  const mainLocation = location.pathname.split('/')[1];

  let desiredSubmenu;
  switch (mainLocation) {
    case 'news':
      desiredSubmenu = (
        <div className="links">
          <NavLink to="/news">{ t("header:navigation.newsSublink") }</NavLink>
          {endpoint === 'ret' && <NavLink to="/news/benefits">{ t("header:navigation.benefitsSublink") }</NavLink>}
          <NavLink to="/news/articles">{ t("header:navigation.articlesSublink") }</NavLink>
        </div>
      );
      break;
    case 'customerService':
      desiredSubmenu = (
        <div className="links">
          <NavLink to="/customerService/faq">{ t("header:navigation.faqSublink") }</NavLink>
          <NavLink to="/customerService">{ t("header:navigation.contactSublink") }</NavLink>
        </div>
      );
      break;
    default:
      desiredSubmenu= (
        <></>
      );
      break;
  }

  return (
    <div className="sub-menu">
      <div className="tab-container">
        {desiredSubmenu}
      </div>
    </div>
  );
}

export default SubMenu;
