import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import UpdateMyInfoForm from './UpdateMyInfoForm';
// Types.
import { UpdateInfo } from '../../types/customer';


const UpdateMyInfo:  React.FunctionComponent<UpdateInfo> = (props: UpdateInfo) => {
  const { customer, customerChild, toggleForm, setUpdateUsers } = props;
  const { t } = useTranslation([]);

  let forms: JSX.Element[] = ([<></>]);
  if (customer) {
    forms = [(
      <div key = { customer.endpoint }>
        <h3>
          <Trans i18nKey="myInfo:customerInfo.contact">
            . <>{{company: t(`common:company.${customer.endpoint}`)}}</>
          </Trans>
        </h3>
        <UpdateMyInfoForm customer = { customer } endpoint = { customer.endpoint } setUpdateUsers = { setUpdateUsers } />
      </div>
    )];

    if (customer.customerInfoDSO) {
      forms.push(
        <div key = 'dso'>
          <h3>
            <Trans i18nKey="myInfo:customerInfo.contact">
              . <>{{company: t(`common:company.dso`)}}</>
            </Trans>
          </h3>
          <UpdateMyInfoForm customer = { customer.customerInfoDSO } endpoint = { 'dso' } setUpdateUsers = { setUpdateUsers } />
        </div>
      )
    }
  }
  else if (customerChild && customerChild.endpoint) {
    forms = [(
      <div key = { customerChild.endpoint }>
        <UpdateMyInfoForm customer = { customerChild } endpoint = { customerChild.endpoint } setUpdateUsers = { setUpdateUsers } />
      </div>
    )];
  }

  return(
    <div className="form">
      { forms }
      <button onClick = { (): void => { toggleForm(false) }} className="button-green close">{ t('myInfo:customerInfo.closeForm') }</button>
    </div>
  )
}

export default UpdateMyInfo;
