import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../Common/Loading';

// Actions.
import { getInvoicesPostponable } from '../../actions/invoices';
// Types.
import { postPonableObject } from '../../types/billing';
import { InvoiceDetails } from '../../types/consumptionRates';
// Utils.
import { formatDate } from '../../utils/billing';

const LatestInvoice:  React.FunctionComponent<InvoiceDetails> = (props: InvoiceDetails) => {
  const { t } = useTranslation([]);
  const { customerId, contractNumber, address, invoiceGroup, invoiceState, invoiceDue, invoiceNumber, paymentType } = props.invoiceDetails;
  const { postponed, postponeTemplatesVisibility, postponeTemplatesLoading, postponeTemplateSelect, fetchPostponeTemplates } = props.postpone;
  const [ invoiceDate, setInvoiceDate ] = React.useState<string>(invoiceDue);
  const [ invoicePostponable, setInvoicePostponable ] = React.useState<postPonableObject>();

  // Make sure that we update the displayed date if a new one is supplied (caused initial load issues).
  React.useEffect(() => {
    setInvoiceDate(invoiceDue);
  }, [invoiceDue]);

  React.useEffect(() => {
    let unMounted = false;

    async function postPonable(customerId: number, contractNo: string, invoiceNo: string, endpoint: string): Promise<void> {
      const postponableRequest = await getInvoicesPostponable( customerId, contractNo, invoiceNo, endpoint );

      if(typeof postponableRequest === 'object' && !unMounted) {
        postponableRequest.invoiceNo = invoiceNo;
        setInvoicePostponable(postponableRequest);
      }
    }
    if(customerId !== 0 && contractNumber !== '' && !unMounted) {
      postPonable(customerId, contractNumber, invoiceNumber, invoiceGroup);
    }

    return (): void => {
      unMounted = true;
    };
  }, [customerId, contractNumber, invoiceNumber, invoiceGroup]);

  const flippedDate = formatDate(invoiceDate);

  return(
    <div className="flipcard direction-column">
      <div className="flipcard-header">
        <div className="flipcard-header-right">
        </div>
      </div>
      <div className="flipcard-details">
        <div className="address"><span>{ address }</span></div>
        <div className="detail">
          <span>{ t('billing:billing.receiver') }</span>
          <span className="bold-text">{ t(`common:company.${invoiceGroup}`) } Oy</span>
        </div>
        <div className="detail">
          <span>
            { t('billing:billing.billState') }
            {invoiceState !== t('billing:billing.unpaid') && invoiceState !== t('billing:billing.paid') && invoicePostponable?.postponable === 1 && props.customerType !== 'business' && postponed !== invoiceNumber && postponeTemplatesVisibility !== invoiceNumber && !postponeTemplatesLoading && <button className="postponeInvoice" onClick={(): void => fetchPostponeTemplates(customerId, invoiceNumber, invoiceGroup)}>{ t("contracts:consumption.postponeBill") } &rsaquo;</button>}
            {invoicePostponable?.postponable === 1 && props.customerType !== 'business' && postponed === invoiceNumber && <span className="postponeInvoice postponed">{ t("contracts:consumption.postponed") }</span>}
          </span>
          <span className="bold-text">{ invoiceState }</span>
        </div>
        {postponeTemplatesLoading && <Loading size="small" />}
        {invoicePostponable?.postponable === 1 && props.customerType !== 'business' && postponeTemplatesVisibility === invoiceNumber && postponeTemplateSelect({customerId, invoiceNumber, invoiceGroup, invoiceDate})}
        <div className="detail"><span>{ t('billing:billing.dueDate') }</span><span className="bold-text">{ flippedDate }</span></div>
        <div className="detail"><span>{ t('billing:billing.billNumber') }</span><span className="bold-text">{ invoiceNumber }</span></div>
        {paymentType && <div className="detail"><span>{ t('billing:billing.paymentMethod') }</span><span className="bold-text">{ t(`billing:paymentTypes.${paymentType}`) }</span></div>}
      </div>
    </div>
  )
}

export default LatestInvoice;
