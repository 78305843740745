import { SimpleUser, CustomerRoleChild, loginCustomer, AvailableUsers, basicAdddress } from '../types/customer';

const prepChildDetails = (childUsers: SimpleUser[], children: CustomerRoleChild[], endpoint: string): SimpleUser[] => {

  // We set the array of available users.
  if (children) {
    for (const child of children) {

      // Companies don't have first names so we check if it exists.
      let childName = child.customerLastNameChild;
      if (child.customerFirstNameChild) {
        childName = `${child.customerFirstNameChild} ${child.customerLastNameChild}`;
      }

      let companyName = '(myynti)';
      if (endpoint === 'dso') {
        companyName = '(verkko)';
      }
      else if (endpoint === 'dh') {
        companyName = '(kaukolämpö)';
      }


      const childUser: SimpleUser = {
        userId: child.customerIdChild,
        userName: `${childName} ${companyName}`,
        userAddress: [],
        endpoint: endpoint,
        userNumber: child.mobileNumber,
        userEmail: child.email,
      };

      // Make sure we don't add duplicate users.
      const childIds = childUsers.map(child => child.userId+child.endpoint);
      if (!childIds.includes(childUser.userId+endpoint)) {
        childUsers.push(childUser);
      }
    }
  }
  return childUsers;
}

export const setUserDetails = (userDetails: loginCustomer, availableUsers: AvailableUsers): AvailableUsers => {
  const preppedAddress: Array<basicAdddress> = [];
  let addressDetails;
  let parentUser: SimpleUser;
  let children: SimpleUser[] = [];

  // We only want 1 parent user and RET takes prio.
  // If the ret user is fake we check if the dso user is too. If both are fake we will show ret
  // if not then we will show dso.
  if (userDetails.ret && (userDetails.ret.customerId !== 0 || userDetails.dso?.customerId === 0)) {
    addressDetails = userDetails.ret.addresses;

    // Companies don't have first names so we check if it exists.
    let name = userDetails.ret.lastName;
    if (userDetails.ret.firstName) {
      name = `${userDetails.ret.firstName} ${userDetails.ret.lastName}`;
    }

    parentUser = {
      loginEmail: userDetails.email,
      loginPhone: userDetails.phone,
      userId: userDetails.ret.customerId,
      userName: name,
      userAddress: preppedAddress,
      userEmail: userDetails.ret.email,
      userNumber: userDetails.ret.mobileNumber,
      endpoint: 'ret'
    }

    if (userDetails.dso) {
      parentUser.userIdDSO = userDetails.dso.customerId;
      parentUser.userDetailsDSO = {
        email: userDetails.dso.email,
        number: userDetails.dso.mobileNumber
      }
    }
    // Even though these are the same, due to contract filtering we need
    // to also include this customerid.
    if (userDetails.dh) {
      parentUser.userIdDH = userDetails.dh.customerId;
    }
  }
  else if (userDetails.dso) {
    if (!addressDetails) {
      addressDetails = userDetails.dso.addresses;
    }
    let name = userDetails.dso.lastName;
    if (userDetails.dso.firstName) {
      name = `${userDetails.dso.firstName} ${userDetails.dso.lastName}`;
    }

    parentUser = {
      userId: userDetails.dso.customerId,
      userName: name,
      userAddress: preppedAddress,
      userEmail: userDetails.dso.email,
      userNumber: userDetails.dso.mobileNumber,
      endpoint: 'dso'
    }
    // Even though these are the same, due to contract filtering we need
    // to also include this customerid.
    if (userDetails.dh) {
      parentUser.userIdDH = userDetails.dh.customerId;
    }
  }
  else if (userDetails.dh) {
    if (!addressDetails) {
      addressDetails = userDetails.dh.addresses;
    }
    let name = userDetails.dh.lastName;
    if (userDetails.dh.firstName) {
      name = `${userDetails.dh.firstName} ${userDetails.dh.lastName}`;
    }

    parentUser = {
      userId: userDetails.dh.customerId,
      userName: name,
      userAddress: preppedAddress,
      userEmail: userDetails.dh.email,
      userNumber: userDetails.dh.mobileNumber,
      endpoint: 'dso'
    }
  }
  else {
    // No user set, remove the token and reload the page.
    sessionStorage.removeItem('token');
    window.top.location.reload();

    // Even though we are reloading the page, typescript gets anoyed if we don't have
    // something set for this.
    parentUser = {
      userId: 0,
      userName: '',
      userAddress: preppedAddress,
      userEmail: '',
      userNumber: '',
      endpoint: ''
    }
  }

  // We do want all child users available.
  if (userDetails.ret && userDetails.ret.customerRoleChild) {
    children = prepChildDetails(children, userDetails.ret.customerRoleChild, 'ret');
  }
  if (userDetails.dso && userDetails.dso.customerRoleChild) {
    const dsoChildren = prepChildDetails(children, userDetails.dso.customerRoleChild, 'dso');
    children.concat(dsoChildren);
  }
  if (userDetails.dh?.customerRoleChild) {
    const dhChildren = prepChildDetails(children, userDetails.dh.customerRoleChild, 'dh');
    children.concat(dhChildren);
  }

  availableUsers = {
    parentUser,
    activeUser: parentUser,
    childUsers: children,
  }

  return availableUsers;
}

export const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
