import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Moment from 'moment';
import 'moment/locale/fi';

import * as fi from './translations/fi/';

Moment.locale('fi');

// Translations.
i18next
  .use(initReactI18next)
  .init({
    lng: 'fi',
    resources: {
      fi: {
        common: fi.common,
        landingPage: fi.landingPage,
        myInfo: fi.myInfo,
        billing:fi.billing,
        contracts:fi.contracts,
        customerService:fi.customerService,
        header:fi.header,
        news: fi.news,
        kajaveLinks: fi.kajaveLinks,
      }
    }
  });

ReactDOM.render(
    <App />
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
