import React from 'react';
import { useTranslation } from 'react-i18next';

import ContactForm from './ContactForm';
import { userGroupIcon, greenElectric } from '../../icons';
import ContactLiftups from './ContactLiftups';
import Infobox from './Infobox';
import { CurrentUser } from '../../types/customer';
// Utils.
import { gaPageviewIfAllowed } from '../../utils/googleAnalytics';

type ContactProps = {
  currentUser: CurrentUser;
}

const ContactPage: React.FunctionComponent<ContactProps> = (props: ContactProps) => {
  const { users, setActiveUser } = props.currentUser;
  const { activeUser } = users;
  const { endpoint } = activeUser
  const { t } = useTranslation([]);
  const [ pageview, setPageview ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!pageview) {
      gaPageviewIfAllowed('/contactPage');
      setPageview(true);
    }
  }, [pageview]);

  const contact = (
    <div className="contact-us">
      <div className="left col-2">
        <div className="icon">
          <img src= { userGroupIcon } alt=""/>
        </div>
      </div>
      <Infobox endpoint = { endpoint }/>
    </div>
  );

  const buttons = (
    <div className="contact-buttons">
      <div className="buttons">
        <div className="button button-large button-white" onClick={(): void => document.getElementById("contact-form")?.scrollIntoView({behavior: 'smooth'})}>{ t('customerService:contact-us.messageButton') }</div>
        <div className="icon"><img src={greenElectric} alt=""/></div>
      </div>
    </div>
  );

  const liftups = <ContactLiftups activeUser = { activeUser }/>;

  return (
    <div className="row">
      {contact}
      {buttons}
      {liftups}
      <ContactForm currentUser = {{ users, setActiveUser }} />
    </div>
  );
}

export default ContactPage;
