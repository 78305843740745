import { AxiosResponse } from 'axios';

import API from '../Api';
// Types.
import { Invoice, postPonableObject, postponeTemplates } from '../types/billing';


export const fetchInvoices = async (userId: number, ledgerNo: number, contractNo: number, endpoint: string, PageNo = 1): Promise<Invoice[]> => {

  const customer: AxiosResponse<Invoice[]> = await API({
      method: 'get',
      url: `/getInvoices/${endpoint}/${userId}/${contractNo}/${ledgerNo}/${PageNo}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customer.data;
}
export const fetchInvoicesByLedger = async (userId: number, ledgerNo: number, endpoint: string, PageNo = 1): Promise<Invoice[]> => {

  const customer: AxiosResponse<Invoice[]> = await API({
      method: 'get',
      url: `/getInvoicesByLedger/${endpoint}/${userId}/${ledgerNo}/${PageNo}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customer.data;
}
export const getInvoicesPostponable = async (userId: number, contractNo: string, invoiceNo: string, endpoint: string): Promise<postPonableObject> => {

  const customer: AxiosResponse<postPonableObject> = await API({
      method: 'get',
      url: `/invoicesPostponable/${endpoint}/${userId}/${contractNo}/${invoiceNo}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customer.data;
}
export const getPostponeTemplates = async (customerId: number, invoiceNo: string, endpoint: string): Promise<postponeTemplates[]> => {

  const getPostponeTemplates: AxiosResponse<postponeTemplates[]> = await API({
      method: 'get',
      url: `/invoicesPostponeTemplates/${endpoint}/${customerId}/${invoiceNo}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });
  return getPostponeTemplates.data;
}
export const getPostponedPayments = async (customerId: number, invoiceNo: string, endpoint: string): Promise<Invoice> => {

  const getPostponedPayments: AxiosResponse<Invoice> = await API({
      method: 'get',
      url: `/invoicesPostponedPayments/${endpoint}/${customerId}/${invoiceNo}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });
  return getPostponedPayments.data;
}
export const postponeInvoice = async (customerId: number, invoiceNo: string, endpoint: string): Promise<XMLHttpRequest> => {

  const postPostponeInvoice: AxiosResponse<Invoice> = await API({
      method: 'post',
      url: `/invoicesPostpone/${endpoint}/${customerId}/${invoiceNo}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });
  return postPostponeInvoice.request;
}
