import React from 'react';
import { Location, navigate, RouteComponentProps, Router } from '@reach/router';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import LandingPage from '../LandingPage/LandingPage';
import Login from '../LandingPage/Login';
import MyInfo from '../MyInfo/MyInfo';
import ContractsContainer from '../Contracts/ContractsContainer';
import Consumption from '../Contracts/Consumption';
import Faq from '../Contact/Faq';
import NewsContainer from '../News/NewsContainer';
import Article from '../News/Article';
import CustomerService from '../Contact/CustomerService';
import Footer from '../Common/Footer';
import Loading from '../Common/Loading';
import { ScrollToTop } from './ScrollToTop';
// Types.
import { NewsEntry } from '../../types/news';
// Utils.
import { initialUsers } from '../../utils/currentPage';
import { inIframe, setUserDetails } from '../../utils/login';
//Actions.
import { validateToken } from '../../actions';

const CurrentPage:  React.FunctionComponent = () => {
  const { t } = useTranslation([]);
  const [ users, setActiveUser ] = React.useState(initialUsers);
  const [ news, setNews ] = React.useState<NewsEntry[]>();
  const [ articles, setArticles ] = React.useState<NewsEntry[]>();
  const [ userTokenTimestamp, setUserTokenTimestamp ] = React.useState(0);
  const [ userHasToken, setUserHasToken ] = React.useState(false);
  const [ shouldValidateUser, setShouldValidateUser ] = React.useState(false);
  const [ mobileUserSwap, toggleMobileUserSwap ] = React.useState(false);

  // We default to an hour.
  let tokenDuration = 3600000;
  // If the REACT_TOKEN_TIMEOUT value is set in .env we use that value instead.
  if (process.env.REACT_APP_TOKEN_TIMEOUT) {
    tokenDuration = parseInt(process.env.REACT_APP_TOKEN_TIMEOUT);
  }

  // Validate the token and log the user out when needed.
  React.useEffect(() => {

    async function validateStoredToken(): Promise<void> {
      const userDetails = await validateToken();

      if (userDetails) {
        if (users === initialUsers) {
          const usersToSet = setUserDetails(userDetails, users);
          setActiveUser(usersToSet);
        }
        if (userDetails.ret || userDetails.dso || userDetails.dh) {
          setUserTokenTimestamp(Date.now());
          setShouldValidateUser(false);
        }
        else {
          sessionStorage.removeItem('token');
          setUserHasToken(false);
          setUserTokenTimestamp(0);
        }
      }
      else {
        sessionStorage.removeItem('token');
        setUserHasToken(false);
        setUserTokenTimestamp(0);
      }
      // User has been validated.
      setShouldValidateUser(false);
    }

    const token = sessionStorage.getItem('token');
    // If the user has a token.
    if (token) {
      setUserHasToken(true);

      // and it is older than an hour.
      if ((Date.now() - userTokenTimestamp) > tokenDuration) {
        // Validate the token.
        validateStoredToken();
      }
      else if (users === initialUsers) {
        validateStoredToken();
      }
    }
    else {
      setUserHasToken(false);
      setUserTokenTimestamp(0);
      setActiveUser(initialUsers);

      const anonUrls = ['article', 'terms', 'accessibility'];
      const baseUrl = window.location.pathname.split('/')[1];

      if(!anonUrls.includes(baseUrl)) {
        navigate('/');
      }
    }

  }, [users, userTokenTimestamp, shouldValidateUser, tokenDuration]);

  const isIframe = inIframe();
  const contractsContainer = <ContractsContainer currentUser = {{ users, setActiveUser }} />;

  /* Changing theme based on user endpoint */
  const kajaveTheme = true;
  const newsMenuText = t('header:navigation.newsLink');

  // Done like this for translations to be easily added.
  const linkItems = [
    {
      key:'myinfo',
      text: t('header:navigation.myInfoLink')
    },
    {
      key:'billing',
      text: t('header:navigation.billingLink')
    },
    {
      key:'contracts',
      text: t('header:navigation.contractsLink')
    },
    {
      key:'consumption',
      text: t('header:navigation.consumptionLink')
    },
    {
      key:'customerService/faq',
      text: t('header:navigation.customerServiceLink')
    },
    {
      key:'news',
      text: newsMenuText
    },
  ];

  if (isIframe) {
    let iframeComponent = <Loading />

    if (!userHasToken) {
      iframeComponent = <Login currentUser = { { users, setActiveUser } } userHasToken = { userHasToken } setUserHasToken = { setUserHasToken }/>
    }
    return iframeComponent;
  }
  else {
    return(
      <div className={`wrapper${kajaveTheme ? ' kajave' : ''}`}>
        <a className="skip-link" href="#main-content">{t('common:general.skiplink')}</a>
        <Header links={linkItems} currentUser={{ users, setActiveUser }} mobileUserSwap={mobileUserSwap} toggleMobileUserSwap={toggleMobileUserSwap} />
        <Location>
          {({ location }): RouteComponentProps => {
            // If the token is older than the set duration we should validate it. This should ensure it is fired when the page is changed.
            if (userTokenTimestamp > 0 && ((Date.now() - userTokenTimestamp) > tokenDuration)) {
              // Validate the token.
              setShouldValidateUser(true);
            }
            return (
              <div id="main-content" className="container-content container">
                <Router location={location} primary={false}>
                  <ScrollToTop location={location} path="/">
                    <LandingPage default path="/" currentUser = {{ users, setActiveUser }} newsDetails = {{ news, setNews, articles, setArticles }} contractsContainer = { contractsContainer } userHasToken = { userHasToken } mobileUserSwap={mobileUserSwap} toggleMobileUserSwap={toggleMobileUserSwap} />
                    <ContractsContainer path="billing" currentUser = {{ users, setActiveUser }} />
                    <ContractsContainer path="contracts" currentUser = {{ users, setActiveUser }} />
                    <MyInfo path="myinfo" currentUser = {{ users, setActiveUser }} />
                    <Consumption path="consumption" />
                    <CustomerService path="customerService" currentUser = {{ users, setActiveUser }}>
                      <Faq path="faq" activeUser = { users.activeUser } />
                    </CustomerService>
                    <NewsContainer path="news" currentUser = {{ users, setActiveUser }} newsDetails = {{ news, setNews, articles, setArticles }}>
                      <NewsContainer path="benefits" currentUser = {{ users, setActiveUser }} newsDetails = {{ news, setNews, articles, setArticles }} />
                      <NewsContainer path="articles" currentUser = {{ users, setActiveUser }} newsDetails = {{ news, setNews, articles, setArticles }} />
                    </NewsContainer>
                    <Article path="/article/:slug" currentUser = {{ users, setActiveUser }} />
                    <Article path="/accessibility" currentUser = {{ users, setActiveUser }} static={true} />
                    <Article path="/terms" currentUser = {{ users, setActiveUser }} static={true} />
                  </ScrollToTop>
                </Router>
              </div>
            );
          }}
        </Location>
        <div className="container-footer container">
          <Footer links={linkItems} kajaveTheme={kajaveTheme} currentUser={{ users, setActiveUser }} />
        </div>
      </div>
    );
  }
}

export default CurrentPage;
