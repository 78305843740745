import React from 'react';
import { useTranslation } from 'react-i18next';

import News from './News';
import Articles from './Articles';
// Types.
import { NewsPageProps } from '../../types/news';


const InfoBlock: React.FunctionComponent<NewsPageProps> = (props: NewsPageProps) => {
  const { t } = useTranslation([]);
  const [ infoBlock, setInfoBlock ] = React.useState('news');
  const { currentUser, news, articles } = props;
  const { activeUser } = currentUser.users;
  const { endpoint } = activeUser;

  let userIdentified = false;
  if (activeUser.userName !== '') {
    userIdentified = true;
  }

  let infoBlockContent = (
    <></>
  );

  if (userIdentified) {
    infoBlockContent = (
      <div className={`tab-container col-6 col-lg-5`}>
        <div className='tabs'>
          <div className={infoBlock === 'news' ? 'tablink active': 'tablink'} onClick={(): void => setInfoBlock('news')}> <h2> { t('common:news.newsTab') } </h2></div>
          { endpoint === 'ret' && <div className={infoBlock === 'benefits' ? 'tablink active': 'tablink'} onClick={(): void => setInfoBlock('benefits')}> <h2> {t('common:news.benefitsTab')} </h2></div> }
          <div className={infoBlock === 'articles' ? 'tablink active': 'tablink'} onClick={(): void => setInfoBlock('articles')}> <h2> { t('common:news.articlesTab') } </h2></div>
        </div>
        {infoBlock === 'news' && <News benefits = { false } teaser = { true } news = { news } />}
        {infoBlock === 'benefits' && <News benefits = { true } teaser = { true } news = { news } />}
        {infoBlock === 'articles' && <Articles teaser = { false } news = { articles }  />}
      </div>
    );
  }

  return(
    infoBlockContent
  )
}

export default InfoBlock;
