import { AxiosResponse } from 'axios';

import API from '../Api';
// Types.
import { Ledger } from '../types/billing';


export const fetchLedgers = async (userId: number, endpoint: string): Promise<Ledger[]> => {

  const customer: AxiosResponse<Ledger[]> = await API({
      method: 'get',
      url: `/ledgers/` + endpoint + '/' + userId,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customer.data;
}
