import React from 'react';
import parse from 'html-react-parser';

import { SingleFaqQuestionProps } from '../../types/faq';


const SingleFaqQuestion: React.FunctionComponent<SingleFaqQuestionProps> = (props: SingleFaqQuestionProps) => {
  const [ showQuestion, setShowQuestion ] = React.useState(false);
  const { question, category } = props;

  const answers = question.answer.map((question) => {
    return parse(question);
  })

  return (
    <button key={ `${question.question}-${category}` } className={`question${showQuestion ? ' open' : ''}`} onClick = { (): void => setShowQuestion(!showQuestion) }>
      <div className="question-title">
        <div className="arrow" />
        <h3 className="title">{question.question}</h3>
      </div>
      <div className="answer">
        {showQuestion && answers}
      </div>
    </button>
  );
};



export default SingleFaqQuestion;