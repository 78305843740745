import React from 'react';

type ScrollProps = {
  path: string;
  children?: any; // eslint-disable-line
  location: any; // eslint-disable-line
}

export const ScrollToTop: any = ({ children, location }: ScrollProps) => { // eslint-disable-line
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
}