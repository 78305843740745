/**
 * A helper function to validate a supplied string as a valid email address.
 * We also alow for empty string.
 *
 * @param suppliedEmail
 */
export const validateEmail = (suppliedEmail: string): boolean => {
  let isValid = false;
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(suppliedEmail) || suppliedEmail === '') {
    isValid = true;
  }

  return isValid;
}

/**
 * A helper function to validate a supplied string as a phone number.
 * We also alow for empty string.
 *
 * @param suppliedNumber
 */
export const validatePhoneNumber = (suppliedNumber: string): boolean => {
  let isValid = false;

  // Check that phone number starts with + and six or more digits
  if (/^(\+)(\d{6,})$/.test(suppliedNumber) || suppliedNumber === '') {
    isValid = true;
  }

  return isValid;
}