import React, { FormEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../Common/Loading';

// Actions.
import { postCustomerDetails } from '../../actions/customerData';
// Types.
import { Customer, UpdateInfoFormProps, UpdateInfoForm, CustomerRoleChild } from '../../types/customer';
// Utils.
import { validateEmail, validatePhoneNumber } from '../../utils/contact';
import { gaEventIfAllowed } from '../../utils/googleAnalytics';


const initialState = {
  number: '',
  email: ''
};

const UpdateMyInfo:  React.FunctionComponent<UpdateInfoFormProps> = (props: UpdateInfoFormProps) => {
  const [ formState, updateFormState ] = React.useState<UpdateInfoForm>(initialState);
  const [ updateUserData, setUpdateUserData ] = React.useState<boolean>(false);
  const [ dataUpdated, setDataUpdated ] = React.useState<boolean>(false);
  const [ updateError, setUpdateError ] = React.useState<boolean>(false);
  const [ validEmail, setValidEmail ] = React.useState<boolean>(true);
  const [ validNumber, setValidNumber ] = React.useState<boolean>(true);
  const { customer, endpoint, setUpdateUsers } = props;
  const { t } = useTranslation([]);

  React.useEffect(() => {

    let customerNumber = customer.mobileNumber;
    let customerEmail = customer.email;
    if (!customerNumber) {
      customerNumber = '';
    }
    if (!customerEmail) {
      customerEmail = '';
    }

    const formDetails = {
      number: customerNumber,
      email: customerEmail,
    };

    updateFormState(formDetails);
  }, [customer]);

  React.useEffect(() => {
    async function updateUserDetails(): Promise<void> {
      const detailsToUpdate = {
        number: formState.number,
        email: formState.email
      }

      let customerId = customer.customerId;
      if ((customer as CustomerRoleChild).customerIdChild) {
        customerId = (customer as CustomerRoleChild).customerIdChild;
      }
      const updatedUserData = await postCustomerDetails(customerId, endpoint, detailsToUpdate);

      if ((updatedUserData as Customer)) {
        updateFormState({number: (updatedUserData as Customer).mobileNumber, email: (updatedUserData as Customer).email})
        setDataUpdated(true);
        setUpdateUserData(false);
        setUpdateUsers(true);
      }
      else {
        setUpdateUserData(true);
      }
    }
    if (updateUserData) {
      updateUserDetails();
    }
  }, [updateUserData, formState, customer, endpoint, setUpdateUsers]);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    gaEventIfAllowed({
      category: 'Omien tietojen päivittäminen',
      action: 'Yhteystiedot tallennettu',
      label: t(`myInfo:customerInfo.submitForm${endpoint}`)
    });

    setUpdateUserData(true);
  }

  const updateUserForm = (e: ChangeEvent<HTMLInputElement>): void => {
    const value: string = e.target.value;
    const elementName: string = e.target.name;
    const currentFormState = formState;

    switch (elementName) {
      case 'mail':
        currentFormState.email = value;
        setValidEmail(validateEmail(value));
        break;
      case 'phone':
        currentFormState.number = value;
        setValidNumber(validatePhoneNumber(value));
        break;
    }
    setDataUpdated(false);
    setUpdateError(false);
    updateFormState((prev: UpdateInfoForm) => ({ ...prev, currentFormState }));
  }

  const customerId = customer.customerIdChild ? customer.customerIdChild : customer.customerId;

  const formToDisplay = (
    <form onSubmit = { (e): void => { handleFormSubmit(e) } }>
      <div className="row">
        <div className="form-item col-6">
          <label htmlFor={`user-${customerId}-phone-${endpoint}`}> { t("common:general.phone") } </label>
          <input type="text" name="phone" id={`user-${customerId}-phone-${endpoint}`} value = { formState.number } onChange = { (e): void => updateUserForm(e) } />
          <span className="contact-error">{(!validNumber || !formState.number) && t('common:contactform.phone')}&nbsp;</span>
        </div>
        <div className="form-item col-6">
          <label htmlFor={`user-${customerId}-email-${endpoint}`}> { t("common:general.email") } </label>
          <input type="email" id={`user-${customerId}-email-${endpoint}`} name="mail" value = { formState.email } onChange = { (e): void => updateUserForm(e) } />
          <span className="contact-error">{!validEmail && t('common:contactform.email')}&nbsp;</span>
        </div>
      </div>
      { updateUserData && <Loading size="small" /> }
      { dataUpdated && <p aria-live="polite">{ t('common:messages.updateSuccess') }</p> }
      { updateError && <p aria-live="polite">{ t('common:messages.updateError') }</p> }
      <button type = "submit" className="button-blue" aria-label={t(`myInfo:customerInfo.submitForm${endpoint}`)} disabled={(!validEmail || !validNumber) ? true : false}>{ t("common:general.save") }</button>
    </form>
  );


  return(
    <div className="form">
      { formToDisplay }
    </div>
  )
}

export default UpdateMyInfo;
