import React from 'react';
import { useBarcode  } from 'react-barcodes';

type BarcodeProp = {
  code: string;
}

const Barcode:  React.FunctionComponent<BarcodeProp> = (props: BarcodeProp) => {
  const code = props.code;

  const { inputRef } = useBarcode({
    value: code,
    options: {
      format: 'CODE128C',
      width: 2,
      height: 200,
      background: '#f4f5f9',
      displayValue: false
    }
  });



  return(
    <>
      <canvas ref={inputRef} className='barcode'/>
      <h4>{code}</h4>
    </>
  )
}

export default Barcode;
