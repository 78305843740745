import React from 'react';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import DetailsDropdown from '../Common/DetailsDropdown';
import CustomerContractDetails from './CustomerContractDetails';
// Types.
import { Contract, Delivery } from '../../types/contract';
// Images.
import { contractIcon } from '../../icons';
// Utils.
import { setPriceFormat } from '../../utils/billing';
import { generateAddress } from '../../utils/address';
import { shouldDisplayDelivery } from '../../utils/contracts';

type contractProps = {
  contract: Contract;
  customerType: string;
}

/**
 * The title for and internals of a contract dropdown.
 */
const ContractDetails: React.FunctionComponent<contractProps> = (props: contractProps) => {
  const { t } = useTranslation([]);
  const { contract, customerType } = props;
  const dateformat = 'DD.MM.YYYY';
  let contractName = '';
  // Generate contract name from the address.
  if (contract.meteringPoint) {

    const meteringPoint = contract.meteringPoint;

    contractName = generateAddress(meteringPoint, false);
  }
  // If there is no address we just show the groupname.
  else {
    contractName = contract.contractGroup.contractGroupName;
  }

  const title = (
    <>{contractName}</>
  );

  let contractDeliveryName = `${contract.contractType.contractTypeName} ${contract.contractGroup.contractGroupName}`;
  const activeDeliveries: Delivery[] = [];
  let nextContract: Delivery | undefined;
  let previousContracts: Delivery[] = [];
  // The following arrays are used because the api has a... curious way of identifying which discounts apply to which products
  // and when to show discounts. Plus sometimes the client doesn't want prices to be shown but do want the element there etc.
  // These are the discount elementNo, the correct one is identified with use of elementsWithDiscountsRET array.
  const possibleDiscountElementsRET = [
    11,
    13,
    14,
    26,
    27,
    28
  ];
  // These are the elements that can have discounts and identify which of the possibleDiscountElementsRET elementNo to use.
  const elementsWithDiscountsRET = [
    1,
    5,
    8
  ];
  // These are elements that we should show but not show the price of.
  const elementsNoPriceRET = [
    16,
    17,
    18,
    19,
    20
  ];
  // Elements where we don't show the details if it is a DH contract.
  const hideDetailsDH = [
    8,
    11,
    29,
    31,
    33
  ];
  // Special case tarrifs.
  const spotTarrifsNoElementsProd = [
    7, 87, 99, 100
  ];
  const spotTarrifsNoElementsStg = [
    7, 87, 142, 144
  ];

  let spotTarrifsNoElements = spotTarrifsNoElementsProd;
  if (process.env.REACT_APP_ENV === 'stg') {
    spotTarrifsNoElements = spotTarrifsNoElementsStg;
  }

  const hideElementsRET = [
    10, 12, 15, 16, 17, 18, 19, 20
  ];
  const hideElementsDSO = [
    112
  ];
  let discountToShow = 0;
  let showDetails = true;

  if (contract.endpoint === 'dh' && hideDetailsDH.includes(contract.contractGroup.contractGroupNo)) {
    showDetails = false;
  }

  if (contract.deliveries) {
    contract.deliveries.map((delivery): undefined => {

      if (elementsWithDiscountsRET.includes(delivery.elementNo)) {
        discountToShow = delivery.elementNo;
      }

      if (moment().isBetween(delivery.fromDate, delivery.toDate)) {
        // If the contract has an end date set then we make sure that the contract we are looking at is not one that would
        // have started after that end date.
        if (!contract.endDate || moment(delivery.toDate).isSameOrBefore(contract.endDate)) {
          // Sometimes the contract may have ended and the end date is the same as the current date so contracts in the expired contracts
          // section may show active contracts. In this case we simply put the contract in the previous contracts section.
          if (contract.contractStatus.contractStatusNo.toLowerCase() !== 's') {
            activeDeliveries.push(delivery);
          }
          else {
            previousContracts.push(delivery);
          }
        }
      }
      else if (moment().isBefore(delivery.fromDate)) {
        if (!nextContract && (!contract.endDate || moment(delivery.toDate).isSameOrBefore(contract.endDate))) {
          nextContract = delivery;
        }
        else if (nextContract && (moment(delivery.fromDate).isBefore(nextContract.fromDate))) {
          nextContract = delivery
        }
      }
      else {
        if (moment(delivery.fromDate).isBefore(delivery.toDate)) {
          previousContracts.push(delivery);
        }
      }
      return undefined;
    });

    if (previousContracts.length > 1) {
      previousContracts.sort((a, b) => (a.fromDate < b.fromDate) ? 1 : -1 );
    }
    if (activeDeliveries.length > 0) {
      contractDeliveryName = `${activeDeliveries[0].externalName}`;
    }
    else if (previousContracts.length > 0) {
      contractDeliveryName = previousContracts[0].externalName;
    }
  }

  const contractTable: JSX.Element[] = [];
  let previousContractsTables: JSX.Element[] = [];
  let nextContractTable = (<></>);

  const contractDetails: JSX.Element[] = [];

  // By default all of the descriptions are the same so we just set it as the first one.
  let contractDescription;
  if (activeDeliveries[0] && activeDeliveries[0].externalDescription && contract.endpoint === 'ret') {
    contractDescription = activeDeliveries[0].externalDescription;
  }

  let dateTo: Moment | undefined = contract.endDate ? moment(contract.endDate) : undefined;
  let startDate = moment(contract.startDate).format(dateformat);
  let invoiceComment = '';
  activeDeliveries.map((delivery, key): undefined => {

    // For some very specific contracts, Ekosähkö and Lumituuli we also want to show the invoice comment.
    // The values were supplied by client.
    if (contract.endpoint === 'ret' && delivery.tariffNo === 82 && delivery.elementNo === 9 && contract.contractGroup.contractGroupNo === 2 && contract.invoiceComment) {
      invoiceComment = `${contract.invoiceComment}<br />Yhdellä Lumituulen osakkeella saat 500 kWh osakkuussähköä ja loput tarvitsemastasi sähköstä toimitetaan Ekosähkönä.`;
    }
    // For ret if there is an elementNo 10 then it is temporary so we want to have the extended description that was set for it in the backend.
    if (contract.endpoint === 'ret' && delivery.elementNo === 10) {
      contractDescription = delivery.externalDescription;
    }
    let priceUnit;
    switch (delivery.priceUnit) {
      case 'EUR/mnth':
        priceUnit = 'eur/kk';
        break;
      case '€/meters':
        priceUnit = 'eur/metri';
        break;
      case 'c/kWh':
        priceUnit = 'snt/kWh';
        break;
      case 'EUR/pcs':
        priceUnit = 'eur';
        break;
      case '€/MWh':
        priceUnit = 'eur/MWh';
        break;
      default:
        priceUnit = delivery.priceUnit;
    }
    let formattedPrice;
    // If the current elementNo isn't included in the possible discounts we show it.
    let shouldShowElement = true;

    // By default we hide all the possible discount elements.
    if (contract.endpoint === 'ret') {
      shouldShowElement = !possibleDiscountElementsRET.includes(delivery.elementNo);
    }
    else {
      shouldShowElement = shouldDisplayDelivery(delivery);
    }

    // If it is in the discount list we will check if it is the correct one.
    if (contract.endpoint === 'ret' && !shouldShowElement && moment().isBetween(delivery.fromDate, delivery.toDate)) {
      if (discountToShow === 8) {
        if (delivery.elementNo === 11 || delivery.elementNo === 26) {
          shouldShowElement = true;
        }
      }
      else if (discountToShow === 5) {
        if (delivery.elementNo === 13 || delivery.elementNo === 27) {
          shouldShowElement = true;
        }
      }
      else if (discountToShow === 1) {
        if (delivery.elementNo === 14 || delivery.elementNo === 28) {
          shouldShowElement = true;
        }
      }
    }
    // I don't know what this is but for some reason we can't show any of these elements for tariffNo 86.
    if (contract.endpoint === 'ret' && delivery.tariffNo === 86) {
      const dontShowSpecialCase = [10, 11, 12, 13, 14, 15];
      if (dontShowSpecialCase.includes(delivery.elementNo)) {
        shouldShowElement = false;
      }
    }
    const dsoGroups = [1, 2, 3, 17, 18, 19, 20, 21];
    if (contract.endpoint === 'dso' && dsoGroups.includes(contract.contractGroup.contractGroupNo)) {
      const retSpecialHidden = [7, 8];
      if (retSpecialHidden.includes(delivery.tariffNo)) {

      }
    }
    if (spotTarrifsNoElements.includes(delivery.tariffNo)) {
      const dontShowSpotCase = [4];
      if (dontShowSpotCase.includes(delivery.elementNo)) {
        shouldShowElement = false;
      }
    }
    // Special DSO not to show.
    if (contract.endpoint === 'dso') {
      const specialCaseHideDSO = [1, 6, 7, 8, 79, 80];
      if (delivery.tariffNo === 103) {
        const dontShowDSO = [ 1, 11 ];
        if (dontShowDSO.includes(delivery.elementNo)) {
          shouldShowElement = false;
        }
      }
      else if (specialCaseHideDSO.includes(delivery.tariffNo) && delivery.elementNo === 2) {
        shouldShowElement = false;
      }
    }
    // Special DH not to show.
    if (contract.endpoint === 'dh') {
      const dontShowDH = [8, 11, 29, 31, 33];
      if (dontShowDH.includes(contract.contractGroup.contractGroupNo)) {
        shouldShowElement = false;
      }
    }

    if(contract.endpoint === 'ret' && hideElementsRET.includes(delivery.elementNo)) {
      shouldShowElement = false;
    }
    if(contract.endpoint === 'dso' && hideElementsDSO.includes(delivery.elementType)) {
      shouldShowElement = false;
    }

    if (shouldShowElement) {
      if (contract.endpoint !== 'ret' || delivery.elementNo !== 10) {
        if (delivery.totalPriceInclVat || delivery.totalPriceInclVat === 0 || delivery.totalPrice || delivery.totalPrice === 0) {
          // There seems to be a spelling mistake in the endpoint "acticeToday".
          const deliveryPrice = customerType === 'business' ? delivery.totalPrice : delivery.totalPriceInclVat;
          if (((contract.endpoint === 'dso' || contract.endpoint === 'ret') && !elementsNoPriceRET.includes(delivery.elementNo)) || (contract.endpoint === 'dh' && delivery.acticeToday === 1)) {
            formattedPrice = setPriceFormat(deliveryPrice.toFixed(2).toString());
            // For some DH there is no regular contractDeliveryName set. I'm not sure what to set it to so we went with the first delivery name.
            if (contractDeliveryName === undefined || (contractDeliveryName === 'undefined' && contract.endpoint === 'dh')) {
              contractDeliveryName = delivery.tariffName;
            }
          }
        }
        if (contract.endpoint === 'ret' && delivery.elementName.toLowerCase() === 'energia' && (delivery.tariffName.trim().toLowerCase() === 'loiste spot' || delivery.tariffName.trim().toLowerCase() === 'loiste spot fallback' || delivery.tariffName.trim().toLocaleLowerCase() === 'etupuhdas pörssi')) {
          contractDetails.push(
            <div className="table-row" key = { key }>
              <span>{ delivery.elementName }</span>
              <span>Spot-tuntihinta</span>
            </div>
          );
        }
        else if (formattedPrice && !(contract.endpoint === 'dso' && delivery.elementName.toLowerCase() === 'liittymismaksu')) {
          contractDetails.push(
            <div className="table-row" key = { key }>
              <span>{ delivery.elementName }</span>
              <span>{ `${formattedPrice} ${priceUnit}` }</span>
            </div>
          );
        }
        else if (contract.endpoint === 'ret' && elementsNoPriceRET.includes(delivery.elementNo)) {
          contractDetails.push(
            <div className="table-row" key = { key }>
              <span>{ delivery.elementName }</span>
            </div>
          );
        }
        if (contract.endpoint === 'ret' && possibleDiscountElementsRET.includes(delivery.elementNo) && delivery.toDate) {
          contractDetails.push(
            <div className="table-row" key = { `${key}-dateTo` }>
              <span>{ t('contracts:contracts.discountTo') }</span>
              <span>{ `${moment(delivery.toDate).format(dateformat)} ${t('contracts:contracts.until')}` }</span>
            </div>
          );
        }
      }
    }
    // Special case contractDeliveryName for DSO contracts that shouldn't have elements shown but should have modified name.
    if (contract.endpoint === 'dso' && (contract.contractGroup.contractGroupName.toLowerCase() === 'lsv liittymissopimus' && delivery.externalName.toLowerCase() === 'verkkopalvelusopimus')) {
      contractDeliveryName = 'Liittymissopimus';
    }
    // Because of how their system works, these contract groups have start dates from when the building was created.
    // as such if it is in group 7 or 15 we need to put the start date to the day the contract was signed.
    const dsoStartDate = [7, 15];
    const dhStartDate = [8, 11, 29, 31, 33];
    if ((contract.endpoint === 'dso' && dsoStartDate.includes(delivery.contrctGroup)) || (contract.endpoint === 'dh' && dhStartDate.includes(delivery.contrctGroup))) {
      startDate = moment(contract.dateOfSignature).format(dateformat);
    }
    else if (delivery.fromDate) {
      startDate = moment(delivery.fromDate).format(dateformat);
    }

    dateTo = moment(delivery.tariffToDate).subtract(1, 'd');
    return undefined;
  });

  if(contract.endpoint === 'dso' && contract.contractType.contractTypeName.toLowerCase() === 'site connection el' && contract.contractGroup.contractGroupName.toLowerCase() === 'lsv liittymissopimus') {
    contractDeliveryName = 'Liittymissopimus';
  }
  let endDate = dateTo ? dateTo.format(dateformat) : undefined;
  if ((dateTo && dateTo.isSame('2073-05-31', 'day')) || !endDate) {
    endDate = t('contracts:contracts.ongoing');
  }

  if (contractDetails.length > 0 || (contract.endpoint === 'dso' && contractTable.length === 0) || (contract.endpoint === 'dh' && contractTable.length === 0)) {
    // Just a double check, if the contract is closed then we don't show any active contracts in a closed section.
    if (contract.contractStatus.contractStatusNo.toLowerCase() !== 's') {
      contractTable.push(
        <div className="table table-responsive" key = { `` }>
          <div className="table-header"><span> { t('contracts:contracts.currentContractGroup') } </span> <span>{}</span></div>
          {contractTable.length < 1 && <div className="table-row"><span> { t('contracts:contracts.startDate') } </span> <span>{ startDate }</span></div>}
          {contractTable.length < 1 && <div className="table-row"><span> { t('contracts:contracts.endDate') } </span> <span>{ endDate }</span></div>}
          {showDetails && contractDetails}
        </div>
      );
    }
  }

  if (nextContract) {
    nextContractTable = (
      <div className="table table-responsive">
        <div className="table-header"><span> { t('contracts:contracts.nextContract') } </span> <span>{nextContract.externalName}</span></div>
        <div className="table-row"><span> { t('contracts:contracts.startDate') } </span> <span>{moment(nextContract.tariffFromDate).format(dateformat)}</span></div>
      </div>
    );
  }

  if (previousContracts.length > 0) {
    // Since we have all tariffs for each contract sometimes it would cause many of the same contract to be shown.
    // Thus we just filter out to get unique enddates of the previous contracts.
    previousContracts = previousContracts.filter((v,i,a)=>a.findIndex(t=>(t.tariffToDate === v.tariffToDate))===i);
    previousContractsTables = previousContracts.map((previousContract, key) => {
      let startDate = moment(previousContract.tariffFromDate).format(dateformat);
      const dateTo = moment(previousContract.tariffToDate);
      if (contract.endpoint !== 'ret' && (previousContract.contrctGroup === 7 || previousContract.contrctGroup === 15)) {
        startDate = moment(previousContract.signatureDate).format(dateformat);
      }
      let endDate = dateTo.format(dateformat)
      if (dateTo.isSame('2073-06-01', 'day')) {
        endDate = t('contracts:contracts.ongoing');
      }
      const previousContractTable = (
        <div className="table-sub-wrapper" key = {key}>
          <div className="table-header"><span> { t('contracts:contracts.previousContract') } </span> <span>{previousContract.externalName}</span></div>
          <div className="table-row"><span> { t('contracts:contracts.startDate') } </span> <span>{ startDate }</span></div>
          <div className="table-row"><span> { t('contracts:contracts.endDate') } </span> <span>{ endDate }</span></div>
        </div>
      );
      return previousContractTable;
    });
  }

  // Here we replace the desctiption name with the correct one. We do it here just incase the description was changed to the extended description.
  // Sometimes the string we replace is upper case, sometimes lower. We don't want to set the whole string to one or the other so we just
  // check it twice.
  if (activeDeliveries.length > 0 && contractDescription) {
    contractDescription = contractDescription.replace('#tariffextnamecustomerlang_exfb#', `${activeDeliveries[0].externalName}`);
    contractDescription = contractDescription.replace('#TARIFFEXTNAMECUSTOMERLANG_EXFB#', `${activeDeliveries[0].externalName}`);
  }

  const details = (
    <div className="accordion-content">
      <div className="accordion-inner">
        <div className="details">
          <div className="icon">
            <img src={contractIcon} alt=""/>
          </div>
          <div className="details-content">
            <h4 className="h2">{ contractDeliveryName }</h4>
            {contractDescription && <p>{ contractDescription }</p>}
            {invoiceComment !== '' && <p dangerouslySetInnerHTML={{ __html: invoiceComment}} />}
          </div>
        </div>
        { contractTable }
        { nextContractTable }
        {previousContractsTables.length > 0 && <div className="table table-responsive">
          { previousContractsTables }
        </div>}
        <CustomerContractDetails contract = { contract } />
        {customerType !== 'business' && <span className="incTax">{t('contracts:consumption.incTax')}</span>}
      </div>
    </div>
  );

  return(
    <div className="contract">
      <DetailsDropdown title = { title } details = { details } isChild = {true} />
    </div>
  );
}

export default ContractDetails;
