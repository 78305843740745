import { AxiosResponse } from 'axios';

import API from '../Api';
// Types.
import { loginCustomer } from '../types/customer';


export const oidcAuth = async (token: string): Promise<loginCustomer> => {

  const customer: AxiosResponse<loginCustomer> = await API({
      method: 'get',
      url: `/oidcAuthorization/`,
      headers: {
        code: token,
      }
    });

  return customer.data;
}
