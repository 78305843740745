import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

// images
import liftupImage1 from '../../images/contact-liftup-1.jpg';
import liftupImage2 from '../../images/contact-liftup-2.jpg';
import liftupImage3 from '../../images/liftup-default-2.jpg';
// types
import { SimpleUser } from '../../types/customer';

type ContactsProps = {
  activeUser: SimpleUser;
}

const Contacts: React.FunctionComponent<ContactsProps> = (props: ContactsProps) => {
  const { t } = useTranslation([]);
  const { activeUser } = props;
  const [endpoint, setEndpoint] = useState<string>('');

  React.useEffect(() => {
    if(activeUser.userId > 0 && activeUser.userIdDSO && activeUser.userIdDSO > 0) {
      setEndpoint('retDso');
    }
    else {
      setEndpoint(activeUser.endpoint);
    }
  }, [activeUser]);

  return (
    <div className="liftups customer-service">
      <div className="liftup contact col-4 col-md-6 direction-column">
        <div className="top">
          <img src={liftupImage1} alt=""/>
        </div>
        <div className="bottom">
          <h3> { t(`customerService:liftupOne.${endpoint}.customerserviceTitle`) } </h3>
          {(endpoint === 'ret' || endpoint === 'retDso') &&
            <>
              <p>
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle1`)}</strong><br />
                <a href={`tel:+35880090110`}>0800 90110</a><br />
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle2`)}</strong><br />
                <a href={`tel:+358942461355`}>09 4246 1355</a><br />
                {endpoint === 'retDso' && <>
                  <strong>{t(`customerService:liftupOne.${endpoint}.subtitle3`)}</strong><br />
                  <a href={`tel:+35880092100`}>0800 9 2100</a><br />
                </>}
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle4`)}</strong><br />
                <a href={`tel:+35880092480`}>0800 9 2480</a><br />
                <a href={t(`customerService:liftupOne.${endpoint}.info1link`)}>{ t(`customerService:liftupOne.${endpoint}.info1`) }</a>
              </p>
              <a href={t(`customerService:liftupOne.${endpoint}.info2link`)} className="button">{ t(`customerService:liftupOne.${endpoint}.info2`) }</a>
            </>
          }
          {endpoint === 'dso' &&
            <>
              <p>
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle1`)}</strong><br />
                <a href={`tel:+35810192800`}>010 192 800</a><br />
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle2`)}</strong><br />
                <a href={`tel:+35810192800`}>010 192 800</a>
              </p>
              <p>
                <a href={t(`customerService:liftupOne.${endpoint}.info1link`)}>{ t(`customerService:liftupOne.${endpoint}.info1`) }</a><br/>
                <a href={t(`customerService:liftupOne.${endpoint}.info2link`)}>{ t(`customerService:liftupOne.${endpoint}.info2`) }</a>
              </p>
              <a href={t(`customerService:liftupOne.${endpoint}.info3link`)} className="button">{ t(`customerService:liftupOne.${endpoint}.info3`) }</a>
            </>
          }
          {endpoint === 'dh' &&
            <>
              <p>
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle1`)}</strong><br />
                <a href={`tel:+35810192810`}>010 192 810</a>
              </p>
              <p>
                <a href={`https://verkkoselvitys.fi/`}>{t(`customerService:liftupOne.${endpoint}.subtitle3`)}</a>
              </p>
              <p>
                <strong>{t(`customerService:liftupOne.${endpoint}.subtitle4`)}</strong><br />
                <a href={`tel:+358102261321`}>010 226 1321</a>
              </p>
            </>
          }
        </div>
      </div>

      <div className="liftup contact col-4 col-md-6 direction-column">
        <div className="top">
          <img src={liftupImage2} alt=""/>
        </div>
        <div className="bottom">
          {endpoint === 'ret' &&
            <>
              <h3> { t(`customerService:liftupTwo.${endpoint}.title`) } </h3>
              <p>
                <strong>{t(`customerService:liftupTwo.${endpoint}.subtitle1`)}</strong><br />
                <a href={`tel:+35880092100`}>0800 9 2100</a>
              </p>
              <p>
                <a href={`https://www.loiste.fi/yrityksille-ja-yhteisoille/ota-yhteytta-yritysmyyntiin/ota-yhteytta-yritysmyyntiin`}>{t(`customerService:liftupTwo.${endpoint}.subtitle2`)}</a>
              </p>
              <a href={t(`customerService:liftupTwo.${endpoint}.ctaUrl`)} className="button">{t(`customerService:liftupTwo.${endpoint}.ctaText`)}</a>
            </>
          }
          {endpoint === 'dso' &&
            <>
              <h3> { t(`customerService:liftupTwo.${endpoint}.title`) } </h3>
              <p>{t(`customerService:liftupTwo.${endpoint}.info1`)}</p>
              <a href={t(`customerService:liftupTwo.${endpoint}.ctaUrl`)} className="button">{t(`customerService:liftupTwo.${endpoint}.ctaText`)}</a>
            </>
          }
          {endpoint === 'dh' &&
            <>
              <h3> { t(`customerService:liftupTwo.${endpoint}.title`) } </h3>
              <p>
                {t(`customerService:liftupTwo.${endpoint}.info1`)}
              </p>
              <a href={t(`customerService:liftupTwo.${endpoint}.ctaUrl`)} className="button">{t(`customerService:liftupTwo.${endpoint}.ctaText`)}</a>
            </>
          }
          {endpoint === 'retDso' &&
            <>
              <h3> { t(`customerService:liftupTwo.${endpoint}.customerserviceTitle`) } </h3>
              <p>
                <strong>{t(`customerService:liftupTwo.${endpoint}.subtitle1`)}</strong><br />
                <a href={`tel:+35810192800`}>010 192 800</a><br />
                <strong>{t(`customerService:liftupTwo.${endpoint}.subtitle2`)}</strong><br />
                <a href={`tel:+35810192800`}>010 192 800</a>
              </p>
              <p>
                <a href={t(`customerService:liftupTwo.${endpoint}.info1link`)}>{ t(`customerService:liftupTwo.${endpoint}.info1`) }</a><br/>
                <a href={t(`customerService:liftupTwo.${endpoint}.info2link`)}>{ t(`customerService:liftupTwo.${endpoint}.info2`) }</a>
              </p>
              <a href={t(`customerService:liftupTwo.${endpoint}.info3link`)} className="button">{ t(`customerService:liftupTwo.${endpoint}.info3`) }</a>
            </>
          }
        </div>
      </div>   

      <div className="liftup contact col-4 col-md-6 direction-column">
        <div className="top">
          <img src={liftupImage3} alt=""/>
        </div>
        <div className="bottom">
          <h3> { t(`customerService:liftupThree.${endpoint}.title`) } </h3>
            {endpoint === 'dso' &&
              <>
                <p>{t(`customerService:liftupThree.${endpoint}.info1`)}</p>
                <p>
                  <Trans i18nKey={`customerService:liftupThree.${endpoint}.info2`}>
                    . <Link to="/myinfo">{{outageLink: t(`customerService:liftupThree.${endpoint}.outageLink`)}}</Link>
                  </Trans>
                </p>
                <p>{t(`customerService:liftupThree.${endpoint}.info3`)}</p>
                <a href="http://hairioinfo.kajave.fi/Keskeytyskartta/outagemap.html" className="button">{t(`customerService:liftupThree.${endpoint}.outageMap`)}</a>
              </>
            }
            {endpoint !== 'dso' &&
              <>
                <p>{t(`customerService:liftupThree.${endpoint}.info1`)}</p>
                <a href={t(`customerService:liftupThree.${endpoint}.ctaUrl`)} className="button">{t(`customerService:liftupThree.${endpoint}.ctaText`)}</a>
              </>
            }
        </div>
      </div>
    </div>
  );
}

export default Contacts;
