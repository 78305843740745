import React from 'react';
import {ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, Legend, ResponsiveContainer} from 'recharts';
import _ from 'lodash';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';

// Types.
import { GraphTooltipType } from '../../types/consumptionRates';
import { graphInovices } from '../../types/billing';
// Utils.
import { setPriceFormat } from '../../utils/billing';

const CustomTooltip: React.FunctionComponent<GraphTooltipType> = ({ active, payload, label }: GraphTooltipType) => {
  const { t } = useTranslation([]);

  if (active && payload) {

    const details: Array<JSX.Element> = [];

    for (const datapoint of payload) {

      details.push(<p key={datapoint.dataKey} className="intro">{ ` ${t(`billing:${datapoint.name}`)}: ${setPriceFormat(datapoint.value.toString())} €`}</p>);
    }

    return (
      <div className="custom-tooltip">
        <p className="label">{`${label}`}</p>
        {details}
      </div>
    );
  }
  return null;
}

const BillGraph: React.FunctionComponent<graphInovices> = (props: graphInovices) => {
  const { invoices, startDate, endDate } = props;
  const { t } = useTranslation([]);

  const billingData = [];

  // We are supplied newest -> oldest so we need to make the line in reverse order.
  const invoicesReversed = _.cloneDeep(invoices).reverse();
  for (const invoice of invoicesReversed) {

    if(invoice.invoiceGroup === 'ret') {
      if (Moment(invoice.dueDate).isBetween(startDate, endDate, undefined, '[]')) {
        const point = {
          'name': invoice.dueDate,
          'invoiceGroup': invoice.invoiceGroup,
          'amountRet': invoice.invoiceSum
        }
        billingData.push(point);
      }
    }
    if(invoice.invoiceGroup === 'dso') {
      if (Moment(invoice.dueDate).isBetween(startDate, endDate, undefined, '[]')) {
        const point = {
          'name': invoice.dueDate,
          'invoiceGroup': invoice.invoiceGroup,
          'amountDso': invoice.invoiceSum
        }
        billingData.push(point);
      }
    }
    if(invoice.invoiceGroup === 'dh') {
      if (Moment(invoice.dueDate).isBetween(startDate, endDate, undefined, '[]')) {
        const point = {
          'name': invoice.dueDate,
          'invoiceGroup': invoice.invoiceGroup,
          'amountDh': invoice.invoiceSum
        }
        billingData.push(point);
      }
    }
  }

  const tempChartStyles = {
    lineHeight: '40px',
  }

  return(
    <ResponsiveContainer width={"100%"} height={400} /* aspect={10/4} */>
      <ComposedChart data={billingData} >
        <Legend verticalAlign="top" wrapperStyle={tempChartStyles} iconType='square' />
        <Tooltip content={<CustomTooltip />} />
        <XAxis dataKey="month" stroke="#7B7B7B" tick = { false } />
        <YAxis domain={[(): number => (0), (dataMax: number): number => (Math.ceil(dataMax / 10)*10)]} stroke="#7B7B7B"/>
        <CartesianGrid />
        {billingData.some(e => e.invoiceGroup === 'ret') && <Line connectNulls name={`${t(`billing:graph.amountRet`)}`} type="monotone" dataKey="amountRet" stroke="#82ca9d" strokeWidth={5} />}
        {billingData.some(e => e.invoiceGroup === 'dso') && <Line connectNulls name={`${t(`billing:graph.amountDso`)}`} type="monotone" dataKey="amountDso" stroke="#8884d8" strokeWidth={5} />}
        {billingData.some(e => e.invoiceGroup === 'dh') && <Line connectNulls name={`${t(`billing:graph.amountDh`)}`} type="monotone" dataKey="amountDh" stroke="#d33390" strokeWidth={5} />}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default BillGraph;
