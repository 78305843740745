import { AxiosResponse } from 'axios';

import API from '../Api';
// Types.
import { AdditionalOwners } from '../types/contract';


export const fetchAdditionalOwners = async (userId: number, contractNo: number, endpoint: string): Promise<(AdditionalOwners | undefined)[]> => {

  const additionalOwners: AxiosResponse<(AdditionalOwners | undefined)[]> = await API({
    method: 'get',
    url: `additionalOwners/${endpoint}/${userId}/${contractNo}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  return additionalOwners.data;
}
