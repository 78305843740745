import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

type ConsumptionProps = {
  path?: string;
}

const Consumption: React.FunctionComponent<ConsumptionProps> = () => {
  const { t } = useTranslation([]);

  return(
    <>
      <div className="row">
        <div className="title">
          <h1 aria-live="polite">{t('contracts:consumption.consumptionTitle')}</h1>
        </div>
        {/* Offset for ingress. Then text is centered correctly on larger screens */}
        <div className="col-2"></div>
        <div className="ingress col-8">
          <p><Trans i18nKey="contracts:consumption.consumptionInfoIngress">. <a href="https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/" target="_blank" rel="noopener noreferrer">{{link1: t(`contracts:consumption.consumptionInfoIngressLink1`)}}</a></Trans></p>
        </div>
        <div className="col-2"></div>
      </div>
    </>
  );
}

export default Consumption;