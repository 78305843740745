import React from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Cookies from 'universal-cookie';

import './css/App.scss';

import CookieBanner from './components/Common/CookieBanner';
import CurrentPage from './components/Navigation/CurrentPage';
// Utils.
import { inIframe } from './utils/login';


const App:  React.FunctionComponent = () => {
  const [ showBanner, setShowBanner ] = React.useState(true);
  const [ allowCookies, setAllowCookies ] = React.useState(false);
  const [ allowReqCookies, setAllowReqCookies ] = React.useState(false);
  const cookies = new Cookies();
  // Cookie lifetime in seconds, this is 30 days.
  let cookieLifetime = 2592000;
  // If we have a duration set in .env we use that instead.
  if (process.env.REACT_APP_COOKIE_LIFETIME && !isNaN(parseInt(process.env.REACT_APP_COOKIE_LIFETIME))) {
    cookieLifetime = parseInt(process.env.REACT_APP_COOKIE_LIFETIME);
  }

  React.useEffect(() => {
    if (cookies.get('allowed')) {
      setAllowCookies(true);
      setShowBanner(false);
    }
    else if (cookies.get('allowReq')) {
      setAllowReqCookies(true);
      setShowBanner(false);
    }
  }, [cookies]);

  React.useEffect(() => {
    if (allowCookies) {
      cookies.set('allowed', true, {maxAge: cookieLifetime});
      // Google analytics.
      if (process.env.REACT_APP_GA_CODE) {
        ReactGA.initialize(process.env.REACT_APP_GA_CODE, {
          gaOptions: {
            allowLinker: true,
            siteSpeedSampleRate: 100
          }
        });
        ReactGA.ga('require', 'linker');
        ReactGA.ga('linker:autoLink', ['omaenergia.loiste.fi', 'energiaseuranta.loiste.fi']);
      }
      // G4 Google analytics.
      if (process.env.REACT_APP_G4_GTM) {
        const tagManagerArgs = {
          gtmId: process.env.REACT_APP_G4_GTM
        };
        TagManager.initialize(tagManagerArgs);
      }
    }
    else if (allowReqCookies) {
      cookies.set('allowReq', true, {maxAge: cookieLifetime});
    }
    else {
      cookies.remove('allowed');
      cookies.remove('allowReq');
    }
  }, [allowCookies, allowReqCookies, cookies, cookieLifetime]);

  const isIframe = inIframe();

  return (
    <div className="App">
      {showBanner && !isIframe && <CookieBanner setAllowCookies = {setAllowCookies} setAllowReqCookies = {setAllowReqCookies} setShowBanner = {setShowBanner} />}
      <CurrentPage />
    </div>
  );
}

export default App;
