import React from 'react';
import { useTranslation } from 'react-i18next';

import MyBillsTableRow from './MyBillsTableRow';

// Types.
import { Invoice, myBillsTableTypes, tableSorterConfig, tableSorter } from '../../types/billing';

// https://www.smashingmagazine.com/2020/03/sortable-tables-react/

const useSortableData = (items: Invoice[]): tableSorter => {
  const [sortConfig, setSortConfig] = React.useState<tableSorterConfig>();

  const sortedItems = React.useMemo((): Invoice[] => {
    const sortableItems = [...items];
    if (sortConfig !== undefined) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: keyof Invoice): void => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};


const MyBillsTable: React.FunctionComponent<myBillsTableTypes> = (props: myBillsTableTypes) => {
  const { t } = useTranslation([]);
  const [ invoiceFilterDateStart, setinvoiceFilterDateStart ] = React.useState<string>('');
  const [ invoiceFilterDateEnd, setinvoiceFilterDateEnd ] = React.useState<string>('');
  const [ invoiceFilterCompany, setinvoiceFilterCompany ] = React.useState<string>('');
  const { contracts, invoices, postpone, currentActiveUser, customerType, userRoles, endpoint, desiredPdf, setDesiredPdf, setInvoiceEndpoint, mostRecentInvoiceDate, mostRecentInvoiceNumber } = props;
  const { items, requestSort, sortConfig } = useSortableData(invoices);

  const getClassNamesFor = (name: keyof Invoice): string | undefined => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  return(
    <div className="col table-responsive">
      <div className="col table-responsive">
        <div className="filters">
          <div>
            <div>
              <span>{t('billing:billing.startDate')}</span>
              <input type="date" id="start-date" name="start-date" onChange={(e): void => setinvoiceFilterDateStart(e.target.value)} />
            </div>
            <div>
              <span>{t('billing:billing.endDate')}</span>
              <input type="date" id="end-date" name="end-date" onChange={(e): void => setinvoiceFilterDateEnd(e.target.value)} />
            </div>
          </div>
          {userRoles.length > 1 && <div>
            <button className={`button button-green button-small ${invoiceFilterCompany !== '' && 'button-gray'}`} onClick={(): void => setinvoiceFilterCompany('')} aria-label={t('billing:billing.filterAllLong')}> { t('billing:billing.filterAll') } </button>
            {userRoles.includes('ret') && <button className={`button button-green button-small ${invoiceFilterCompany !== 'ret' && 'button-gray'}`} onClick={(): void => setinvoiceFilterCompany('ret')} aria-label={t('billing:billing.filterElectricLong')}> { t('billing:billing.filterElectric') } </button>}
            {userRoles.includes('dso') && <button className={`button button-green button-small ${invoiceFilterCompany !== 'dso' && 'button-gray'}`} onClick={(): void => setinvoiceFilterCompany('dso')} aria-label={t('billing:billing.filterTransmissionLong')}> { t('billing:billing.filterTransmission') } </button>}
            {userRoles.includes('dh') && <button className={`button button-green button-small ${invoiceFilterCompany !== 'dh' && 'button-gray'}`} onClick={(): void => setinvoiceFilterCompany('dh')} aria-label={t('billing:billing.filterHeatLong')}> { t('billing:billing.filterHeat') } </button>}
          </div>}
        </div>

        <table id="myBills">
          <tbody>
            <tr>
              <th onClick={(): void => requestSort('dueDate')}>{ t('billing:billing.sortDueDate') }<span className={getClassNamesFor('dueDate')} /></th>
              <th onClick={(): void => requestSort('invoiceNo')}>{ t('billing:billing.sortBillNumber') }<span className={getClassNamesFor('invoiceNo')} /></th>
              <th onClick={(): void => requestSort('invoiceSum')}>{ t('billing:billing.sortBillSum') }<span className={getClassNamesFor('invoiceSum')} /></th>
              <th onClick={(): void => requestSort('unpaidSum')}>{ t('billing:billing.sortBillState') }<span className={getClassNamesFor('unpaidSum')} /></th>
              <th>{ t('billing:billing.sortContractType') }</th>
              <th>{ t('billing:billing.sortPdf') }</th>
              <th>{ t('billing:billing.sortBarCode') }</th>
            </tr>
            {items && items.filter(invoice => {
              const filterStart = invoiceFilterDateStart !== '' ? (new Date(invoiceFilterDateStart) <= new Date(invoice.dueDate)) : true;
              const filterEnd = invoiceFilterDateEnd !== '' ? (new Date(invoiceFilterDateEnd) >= new Date(invoice.dueDate)) : true;
              const filterCompany = invoice.invoiceGroup.includes(invoiceFilterCompany);

              if(filterStart && filterEnd && filterCompany) {
                return true;
              }
              return false;
            }).sort((a, b) => (a.dueDate < b.dueDate) ? 1 : -1).map((invoice) => <MyBillsTableRow key={ invoice.invoiceNo } currentActiveUser={currentActiveUser} customerType={customerType} contracts={contracts} endpoint={endpoint} invoice={invoice} postpone={postpone} desiredPdf={desiredPdf} setDesiredPdf={setDesiredPdf} setInvoiceEndpoint={setInvoiceEndpoint} mostRecentInvoiceDate={mostRecentInvoiceDate} mostRecentInvoiceNumber={mostRecentInvoiceNumber} />)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MyBillsTable;
