import { AxiosResponse } from 'axios';

import API from '../Api';
import { ContactInfo } from '../types/contactInfo';

export const postContactForm = async (contactInfo: ContactInfo, userEndpoint: string, contactEndpoint: string, customerId: number): Promise<XMLHttpRequest> => {
  
  const response: AxiosResponse<string> = await API({
      method: 'post',
      url: `/contactForm/${userEndpoint}/${contactEndpoint}/${customerId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      },
      data: {
        contactInfo
      }
    });

  return response.request;
}
