// Types.
import { AvailableUsers } from '../types/customer';

export const initialUsers: AvailableUsers = {
  activeUser: {
    userId: 0,
    userName: '',
    userAddress: [{
      streetAddress: '',
      cityDetails: ''
    }],
    endpoint: 'anon'
  },
  parentUser: {
    userId: 0,
    userName: '',
    userAddress: [{
      streetAddress: '',
      cityDetails: ''
    }],
    endpoint: ''
  }
};
