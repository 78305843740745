import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import slugify from 'slugify';

import CurrentNews from './CurrentNews';
import Loading from '../Common/Loading';
import SidebarLiftup from './SidebarLiftup';
// Actions.
import { fetchNews, fetchArticles } from '../../actions';
// Types.
import { NewsEntry } from '../../types/news';
import { ArticleProps, imageUrl } from '../../types/news';
// Pages.
import TermsPage from '../../pages/terms';
import AccessibilityPage from '../../pages/accessibility';


const Article: React.FunctionComponent<ArticleProps> = (props: ArticleProps) => {
  const [ prevEndPoint, setPrevEndPoint ] = React.useState('');
  const { currentUser } = props;
  const { activeUser } = currentUser.users;
  const { userId, endpoint } = activeUser
  const [ news, setNews ] = React.useState<NewsEntry[]>();
  const [ articles, setArticles ] = React.useState<NewsEntry[]>();
  const [ article, setArticle ] = React.useState<NewsEntry>();
  const { t } = useTranslation([]);

  const [titleToDisplay, setTitleToDisplay] = React.useState<string>(t('common:general.loadingText'));
  const [bodyToDisplay, setBodyToDisplay] = React.useState<(JSX.Element | JSX.Element[] | undefined)[]>([<Loading key={1} />]);

  React.useEffect(() => {
    async function fetchNewsAndArticles(): Promise<void> {

      setTitleToDisplay(`${t('common:general.loadingText')}…`);
      setBodyToDisplay([]);

      const news = await fetchNews(userId, endpoint);
      const articles = await fetchArticles(userId, endpoint);

      let foundArticle = news.find(article => typeof article?.title === 'string' ? (slugify(article?.title).toLowerCase() === props.slug) : false);

      if(!foundArticle) { 
        foundArticle = articles.find(article => typeof article?.title === 'string' ? (slugify(article?.title).toLowerCase() === props.slug) : false);
      }

      if(foundArticle) {
        setArticle(foundArticle);
      }

      setNews(news);
      setArticles(articles);
    }
    if ((endpoint !== prevEndPoint && prevEndPoint !== 'anon') || (article && (!props.static && props.slug !== slugify(article?.title).toLowerCase()))) {
      setPrevEndPoint(endpoint);
      fetchNewsAndArticles();
    }
    if (props.static && !article && props.path === "/terms") {
      setArticle(TermsPage);
    }
    if (props.static && !article && props.path === "/accessibility") {
      setArticle(AccessibilityPage);
    }
  }, [userId, endpoint, prevEndPoint, news, setNews, articles, setArticles, props.slug, props.path, props, article, t]);

  React.useEffect(() => {
    let bodyArray: (JSX.Element | JSX.Element[])[] = [];
    let imageArray: (JSX.Element | undefined)[] = [];

    if (article) {
      const { title, body, images } = article;
      bodyArray = body.map((bodyPart: string, key) => {
        return <div key={key}>{parse(bodyPart)}</div>;
      });

      if (images) {
        imageArray = images.map((image: (imageUrl | undefined), key: number): JSX.Element | undefined => {
          if (image) {
            return (<img key = { `image${key}` } alt =  { image.alt } src = {`${process.env.REACT_APP_DRUPAL_BASE_URL}${image.url}`} />);
          }
          return undefined;
        });
      }
      setTitleToDisplay(title);
      setBodyToDisplay(_(imageArray).zip(bodyArray).flatten().filter().value());
    }
  }, [article]);

  const articleContent = (
    <div className="content" aria-live="polite">
      {article && 
        <>
          <h1 className="article-title" aria-live="polite">{ titleToDisplay }</h1>
          { bodyToDisplay }
        </>
      }
      {!article && <Loading />}
    </div>
  );

  return(
    <div className="row article">
      <div className="col-8">
        {articleContent}
      </div>

      {endpoint !== 'anon' && <div className="col-4"><SidebarLiftup /></div>}

      {news && <div className="col-12">
        <h2 className="sub-heading">{ t('news:article.mayIntrest') }</h2>
        <CurrentNews news = { news } activeArticle = { article } />
      </div>}
    </div>
  );
}

export default Article;
