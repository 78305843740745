import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import slugify from 'slugify';

// Types.
import { NewsProps } from '../../types/news';
import { formatFullDateTime } from '../../utils/billing';


const News:  React.FunctionComponent<NewsProps> = (props: NewsProps) => {
  const { t } = useTranslation([]);
  const { benefits, teaser, news } = props;

  const newsArray = news.map((article, key): JSX.Element | undefined => {
    if (article && article.benefit === benefits) {
      return (
        <Link to={`/article/${slugify(article.title).toLowerCase()}`} className="item" key = { key }>
          <h4>
            { article.title }
            {article.publishDate && <time dateTime={article.publishDate}>{formatFullDateTime(article.publishDate)}</time>}
          </h4>
        </Link>
      );
    }
    return undefined;
  }).filter(news => news);

  const teaserNews = (
    <div id="news" className="tabcontent">
      { newsArray.slice(0, 3) }
      {!benefits && <Link to="/news" className="button button-white button-small">{ t('customerService:news.allNewsLink') }</Link>}
      {benefits && <Link to="/news/benefits" className="button button-white button-small">{ t('customerService:news.allBenefitsLink') }</Link>}
    </div>
  );

  const fullNews = (
    <div id="news" className="tabcontent">
      { newsArray }
    </div>
  );

  return (
    <>
      { teaser && teaserNews }
      { !teaser && fullNews }
    </>
  );
}

export default News;
