import React from 'react';
import { useTranslation } from 'react-i18next';

// Types.
import { CurrentUser } from '../../types/customer';

// Images
import { userGroupIcon, closeMenuIcon } from '../../icons';

type MobileUserSwapProps = {
  currentUser: CurrentUser;
  toggleMobileUserSwap: (value: React.SetStateAction<boolean>) => void;
}


const MobileUserSwap: React.FunctionComponent<MobileUserSwapProps> = (props: MobileUserSwapProps) => {
  const { t } = useTranslation([]);
  const users = props.currentUser.users;

  const availableUsers = [];
  const updateCurrentUser = (suppliedUserId: number, suppliedEndpoint: string): void => {

    let userToUpdate = users.parentUser;
    if (users.childUsers) {
      for (const child of users.childUsers) {
        if (child.userId === suppliedUserId && child.endpoint === suppliedEndpoint) {
          userToUpdate = child;
        }
      }
    }

    props.currentUser.setActiveUser({
      ...users,
      activeUser: userToUpdate
    });
    props.toggleMobileUserSwap(false);
  }

  availableUsers.push(
    <div className="customer" key = { users.parentUser.userId } onClick = { (): void => updateCurrentUser(users.parentUser.userId, users.parentUser.endpoint) }>
      <b>{ users.parentUser.userName }</b><br />
      {t('common:general.customerNumber')}: { users.parentUser.userId }
    </div>
  );

  if (users.childUsers) {
    for (const child of users.childUsers) {
      availableUsers.push(
        <div className="customer" key = {`${child.userName} ${child.userId}`} onClick = { (): void => updateCurrentUser(child.userId, child.endpoint) }>
          <b>{child.userName}</b><br />
          {t('common:general.customerNumber')}: { child.userId }
        </div>
      );
    }
  }  

  return (
    <div>
      <div className="mobile-menu-header"> 
        <div className="user-group-icon">
          <img src={ userGroupIcon } alt=""></img>
        </div>
        <span>{t('header:functional.customership')}</span>
        <div className="close-menu">
          <span onClick={(e): void => {e.preventDefault(); props.toggleMobileUserSwap(false)}}><img src={closeMenuIcon} alt={t('header:functional.closeMenu')} /></span>
        </div>
      </div>
      <div className="links">
        { availableUsers }
      </div>
      <button className="button-small">{t('header:functional.logout')}</button>
    </div>
  );
}

export default MobileUserSwap;
