import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';

// Types.
import { GAEvent } from '../types/cookies';

export const gaPageviewIfAllowed = (page: string): void => {
  const cookie = new Cookies();

  if (cookie.get('allowed')) {
    ReactGA.pageview(page);
  }
}

export const gaEventIfAllowed = (gaEvent: GAEvent): void => {
  const cookie = new Cookies();

  if (cookie.get('allowed')) {
    ReactGA.event(gaEvent);
  }
}