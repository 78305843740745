import React from 'react';
import { useTranslation } from 'react-i18next';

// Types.
import { CookieBannerProps } from '../../types/cookies';

/**
 * The details accordian component. Used to show contracts and user details.
 */
const CookieBanner: React.FunctionComponent<CookieBannerProps> = (props: CookieBannerProps) => {
  const { t } = useTranslation([]);
  const { setShowBanner, setAllowCookies, setAllowReqCookies } = props;

  const rejectCookies = (): void => {
    setAllowCookies(false);
    setShowBanner(false);
  }
  const allowCookies = (): void => {
    setAllowCookies(true);
    setShowBanner(false);
  }
  const allowReqCookies = (): void => {
    setAllowReqCookies(true);
    setShowBanner(false);
  }

  return (
    <div className="cookie-consent-banner">
      <p>
        { t('common:cookies.bannerText')}
        <a href={ process.env.REACT_APP_COOKIE_URL }> {t('common:cookies.bannerLinkText')}</a>
      </p>
      <div>
        <button className="button-blue" onClick = {(): void => allowCookies()}>{ t('common:cookies.allowAll') }</button>
        <button className="button-blue" onClick = {(): void => allowReqCookies()}>{ t('common:cookies.allowRequired') }</button>
        <button onClick = {(): void => rejectCookies()}>{ t('common:cookies.reject') }</button>
      </div>
    </div>
  );
}

export default CookieBanner;
