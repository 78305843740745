import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';

import Flipcard from '../Common/Flipcard';
import LatestInvoice from './LatestInvoice';
import LatestInvoiceBarCode from './LatestInvoiceBarCode';
import BillingHistory from './BillingHistory';
import MyBillsTable from './MyBillsTable';
import Loading from '../Common/Loading';
// Types
import { BillingProps, Ledger, postponeTemplates, postPonableObject, postponeSelectProps, Invoice } from '../../types/billing';
import { flipCardComponent } from '../../types/flipcards';
import { InvoiceDetailsObject, PostponeObject } from '../../types/consumptionRates';
// Actions
import { fetchInvoices, fetchInvoicesByLedger, getInvoicesPostponable, getPostponeTemplates, getPostponedPayments, postponeInvoice } from '../../actions';
import { fetchLedgers } from '../../actions';
import { fetchPdf } from '../../actions';
import { validateToken } from '../../actions';
// Utils
import { generateAddress } from '../../utils/address';
import { setPriceFormat, getCorrectBankAccount, getLedgerDetails, getInvoiceState } from '../../utils/billing';
import { contractIsClosedOrOtherType } from '../../utils/contracts';
import { gaEventIfAllowed, gaPageviewIfAllowed } from '../../utils/googleAnalytics';
// Images
import { invoice } from '../../icons';

const Billing: React.FunctionComponent<BillingProps> = (props: BillingProps) => {
  const { t } = useTranslation([]);
  const [ defaultMeteringPoint, setDefaultMeteringPoint ] = React.useState<string>('');
  const [ activeAddress, setActiveAddress ] = React.useState<string>('');
  const [ activeOptions, setActiveOptions ] = React.useState<JSX.Element[]>([]);
  const [ otherOptions, setOtherOptions ] = React.useState<JSX.Element[]>([]);
  const [ inActiveOptions, setInActiveOptions ] = React.useState<JSX.Element[]>([]);
  const [ desiredPdf, setDesiredPdf ] = React.useState<number[]>();
  const { contracts, currentActiveUser, customerType, selectedMeteringPointDetails, retLedgerDetails, dsoLedgerDetails, dhLedgerDetails, invoiceDetails, endpoint } = props;
  const [ invoiceEndpoint, setInvoiceEndpoint ] = React.useState<string>(endpoint);
  const { invoicesFetched, setInvoicesFetched } = invoiceDetails;
  const [ invoices, setInvoices ] = React.useState<Invoice[] | undefined>(invoiceDetails.invoices);
  const { retLedger, setRetLedger } = retLedgerDetails;
  const { dsoLedger, setDsoLedger } = dsoLedgerDetails;
  const { dhLedger, setDhLedger } = dhLedgerDetails;
  const [ selectedLedger, setSelectedLedger ] = React.useState<number>(0);
  const { userId } = currentActiveUser;
  const [ ledgers, setLedgers ] = React.useState<Ledger[]>();
  const [ pageview, setPageview ] = React.useState<boolean>(false);
  const [ postponeTemplatesLoading, setPostponeTemplatesLoading ] = React.useState<boolean>(false);
  const [ postponing, setPostponing ] = React.useState<boolean>(false);
  const [ postponeTemplatesVisibility, setPostponeTemplatesVisibility ] = React.useState<string>('');
  const [ postponed, setPostponed ] = React.useState<string>('');
  const [ postponeError, setPostponeError ] = React.useState<string>('');
  const [ postponeTemplate, setPostponeTemplate ] = React.useState<postponeTemplates>();
  const [ mostRecentInvoiceDate, setMostRecentInvoiceDate ] = React.useState<string>('');
  const [ mostRecentInvoiceNumber, setMostRecentInvoiceNumber ] = React.useState<string>('');
  const [ userRoles, setUserRoles ] = React.useState<string[]>([]);
  const [ invoicePage, setInvoicePage ] = React.useState<number>(2);
  const [ moreInvoicesAvailable, setMoreInvoicesAvailable ] = React.useState<boolean>(false);
  const [ mostRecentInvoicePostponable, setMostRecentInvoicePostponable ] = React.useState<postPonableObject>();

  React.useEffect(() => {
    if(!pageview) {
      gaPageviewIfAllowed('/billing');
      setPageview(true);
    }
  }, [pageview]);

  React.useEffect(() => {
    async function getUpdatedUserDetails(): Promise<void> {

      const userDetails = await validateToken();

      if(userDetails?.ret?.customerId !== 0 && (userDetails?.ret?.customerId === userId || endpoint === 'ret')) {
        setUserRoles(userRoles => [...userRoles, 'ret']);
      }
      if(userDetails?.dso?.customerId !== 0 && (userDetails?.dso?.customerId === userId || endpoint === 'dso')) {
        setUserRoles(userRoles => [...userRoles, 'dso']);
      }
      if(userDetails?.dh?.customerId !== 0 && (userDetails?.dh?.customerId === userId || endpoint === 'dh')) {
        setUserRoles(userRoles => [...userRoles, 'dh']);
      }
    }

    getUpdatedUserDetails();
  }, [userId, endpoint]);

  React.useEffect(() => {
    setInvoices(invoiceDetails.invoices);
  }, [invoiceDetails]);

  React.useEffect(() => {
    async function fetchDesiredPdf(): Promise<void> {
      if (desiredPdf?.length === 2) {
        let desiredUserId: number = userId;

        if (currentActiveUser.endpoint === 'ret') {
          if (invoiceEndpoint === 'dso' && currentActiveUser.userIdDSO) {
            desiredUserId = currentActiveUser.userIdDSO;
          }
          else if (invoiceEndpoint === 'dh' && currentActiveUser.userIdDH) {
            desiredUserId = currentActiveUser.userIdDH;
          }
        }

        if (desiredUserId) {
          const fileBlob = await fetchPdf(desiredUserId, desiredPdf[0], desiredPdf[1], invoiceEndpoint);

          const file = new Blob([fileBlob], {type: 'application/pdf'});
          const fileUrl = URL.createObjectURL(file);

          const invisiblePdfLink = document.createElement("a");
          document.body.appendChild(invisiblePdfLink);
          invisiblePdfLink.href = fileUrl;
          invisiblePdfLink.download = `${desiredPdf[1]}.pdf`;
          invisiblePdfLink.click();
          window.URL.revokeObjectURL(fileUrl);
        }

        setDesiredPdf(undefined);
      }
    }

    fetchDesiredPdf();
  }, [userId, desiredPdf, invoiceEndpoint, currentActiveUser]);

  React.useEffect(() => {

    const activeOptionsArray = [];
    const otherOptionsArray = [];
    const inActiveOptionsArray = [];
    // These are contract groups other than the normal ones that users may not want to see.
    const otherContractGroupsRet = [10, 22, 23, 24, 25, 31];
    const otherContractGroupsDso = [10, 22, 33, 31];
    const hideInvoiceComment = [7,15,11,8,33,31,29];

    const usedMeteringPoints: string[] = [];

    for (const contract of contracts) {

      const meteringPoint = contract.meteringPoint;
      if (meteringPoint) {

        const address = hideInvoiceComment.includes(contract.contractType.contractTypeNo) ? generateAddress(meteringPoint, false) : generateAddress(meteringPoint, false, contract.invoiceComment);
        let valueDetails = '';
        if (endpoint === contract.endpoint) {
          valueDetails = `${contract.ledgerNo}-${contract.contractNo}---${address}`;
        }
        else if (contract.endpoint === 'dso') {
          valueDetails = `+++${contract.ledgerNo}-${contract.contractNo}`;
        }
        else if (contract.endpoint === 'dh') {
          valueDetails = `++++<<<${contract.ledgerNo}-${contract.contractNo}`
        }

        if (contract.dsoLedger) {
          valueDetails = `${valueDetails}+++${contract.dsoLedger.ledgerNo}-${contract.dsoLedger.contractNo}`
        }
        if (contract.dhLedger) {
          valueDetails = `${valueDetails}<<<${contract.dhLedger.ledgerNo}-${contract.dhLedger.contractNo}`
        }
        if(contract.contractStatus.contractStatusNo.toUpperCase() === 'S') {
          inActiveOptionsArray.push(
            <option value={ valueDetails } key={`${contract.contractNo}-${contract.endpoint}`}>
              { address }
            </option>
          );
        }
        else if ((contract.endpoint === 'ret' && otherContractGroupsRet.includes(contract.contractType.contractTypeNo)) || (contract.endpoint !== 'ret' && otherContractGroupsDso.includes(contract.contractType.contractTypeNo))) {
          otherOptionsArray.push(
            <option value={ valueDetails } key={`${contract.contractNo}-${contract.endpoint}`}>
              { address }
            </option>
          );
        }
        else {
          if (contract.meteringPointId && !usedMeteringPoints.includes(contract.meteringPointId) && !contractIsClosedOrOtherType(contract)) {
            activeOptionsArray.push(
              <option value={ contract.meteringPointId } key={`${contract.contractNo}-${contract.endpoint}`}>
                { address }
              </option>
            );
            usedMeteringPoints.push(contract.meteringPointId);
          }
        }
      }
      else {
        const contractGroup = contract.contractGroup;
        let valueDetails = `${contract.ledgerNo}-${contract.contractNo}---${contractGroup.contractGroupName}`

        if (contract.dsoLedger) {
          valueDetails = `${valueDetails}+++${contract.dsoLedger.ledgerNo}-${contract.dsoLedger.contractNo}`
        }
        if (contract.dhLedger) {
          valueDetails = `${valueDetails}<<<${contract.dhLedger.ledgerNo}-${contract.dhLedger.contractNo}`
        }
        if(contract.contractStatus.contractStatusNo.toUpperCase() === 'S') {
          inActiveOptionsArray.push(
            <option value={ valueDetails } key={`${contract.contractNo}-${contract.endpoint}`}>
              { contractGroup.contractGroupName }
            </option>
          );
        }
        else if ((contract.endpoint === 'ret' && otherContractGroupsRet.includes(contract.contractType.contractTypeNo) )|| (contract.endpoint !== 'ret' && otherContractGroupsDso.includes(contract.contractType.contractTypeNo))) {
          otherOptionsArray.push(
            <option value={ valueDetails } key={`${contract.contractNo}-${contract.endpoint}`}>
              { contractGroup.contractGroupName }
            </option>
          );
        }
        else {
          activeOptionsArray.push(
            <option value={ valueDetails } key={`${contract.contractNo}-${contract.endpoint}`}>
              { contractGroup.contractGroupName }
            </option>
          );
        }
      }
    }

    // If we have no contracts shown then the billing page should say no invoices so we should be good to go.
    let address = '';
    let firstMeteringPoint = '';
    // We want to set the active one to be the first value we see in the list. That means first of the active.
    if (activeOptionsArray.length > 0) {
      address = activeOptionsArray[0].props.children;
      firstMeteringPoint = activeOptionsArray[0].props.value;
    }
    // Or first of the other options.
    else if (otherOptionsArray.length > 0) {
      address = otherOptionsArray[0].props.children;
    }
    // Or first of the inactive options.
    else if (inActiveOptionsArray.length > 0) {
      address = inActiveOptionsArray[0].props.children;
    }

    setActiveAddress(address);
    setDefaultMeteringPoint(firstMeteringPoint);
    // We set invoices fetched to false to trigger a new fetch with the newly set values.
    setInvoicesFetched(false);
    setActiveOptions(activeOptionsArray);
    setOtherOptions(otherOptionsArray);
    setInActiveOptions(inActiveOptionsArray);
  }, [contracts, endpoint, setRetLedger, setDsoLedger, setDhLedger, setActiveAddress, setInvoicesFetched]);

  React.useEffect(() => {
    let unMounted = false;

    // Create an scoped async function in the hook
    async function fetchUserLedgers(): Promise<void> {
      const ledgers = await fetchLedgers(currentActiveUser.userId, endpoint);

      if (ledgers && !unMounted) {
        setLedgers(ledgers);
      }
    }
    // Execute the created function directly
    fetchUserLedgers();

    return (): void => {
      unMounted = true;
    };
  }, [currentActiveUser, endpoint]);

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.persist();

    if(event.target.id === 'address') {
      setSelectedLedger(0);
    }
    if(event.target.id === 'address' && event.target.value === 'all') {
      setRetLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      setDsoLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      setDhLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      selectedMeteringPointDetails.setSelectedMeteringPoint({
        meteringPointId: '',
        startDate: '',
        endDate: ''
      });
    }

    if(event.target.id === 'address' && event.target.value !== 'all') {
      setRetLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      setDsoLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      setDhLedger({
        contractNo: 0,
        ledgerNo: 0,
      });
      for (const contract of contracts) {
        if (contract.meteringPointId === event.target.value) {
          selectedMeteringPointDetails.setSelectedMeteringPoint({
            meteringPointId: contract.meteringPointId,
            startDate: contract.startDate,
            endDate: contract.endDate
          });
          if (contract.endpoint === 'ret' && !contractIsClosedOrOtherType(contract)) {
            setRetLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
          if (contract.endpoint === 'dso' && !contractIsClosedOrOtherType(contract)) {
            setDsoLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
          if (contract.endpoint === 'dh' && !contractIsClosedOrOtherType(contract)) {
            setDhLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
        } else {
          const { retLedger, dsoLedger, dhLedger } = getLedgerDetails(event.target.value);

          setRetLedger(retLedger);
          setDsoLedger(dsoLedger);
          setDhLedger(dhLedger);
        }
      }

      let address = '';
      const newAddress = event.target[event.target.selectedIndex].textContent;
      if(newAddress) { address = newAddress }

      setActiveAddress(address);
      setInvoicesFetched(false);
    }

    if(event.target.id === 'ledger' && event.target.value !== 'all') {
      setInvoicePage(1);
      setActiveAddress('');
      setSelectedLedger(parseInt(event.target.value));
      for (const contract of contracts) {
        if (contract.ledgerNo.toString() === event.target.value) {
          selectedMeteringPointDetails.setSelectedMeteringPoint({
            meteringPointId: '',
            startDate: '',
            endDate: ''
          });
          if (contract.endpoint === 'ret' && !contractIsClosedOrOtherType(contract)) {
            setRetLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
          if (contract.endpoint === 'dso' && !contractIsClosedOrOtherType(contract)) {
            setDsoLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
          if (contract.endpoint === 'dh' && !contractIsClosedOrOtherType(contract)) {
            setDhLedger({
              contractNo: contract.contractNo,
              ledgerNo: contract.ledgerNo,
            });
          }
        } else {
          const { retLedger, dsoLedger, dhLedger } = getLedgerDetails(event.target.value);

          setRetLedger(retLedger);
          setDsoLedger(dsoLedger);
          setDhLedger(dhLedger);
        }
      }
    }
    if(event.target.id === 'ledger' && event.target.value === 'all') {
      for (const contract of contracts) {
        if (contract.meteringPointId === defaultMeteringPoint) {
          if(contract.meteringPoint) {
            setActiveAddress(generateAddress(contract.meteringPoint, false));
          }
          selectedMeteringPointDetails.setSelectedMeteringPoint({
            meteringPointId: contract.meteringPointId,
            startDate: contract.startDate,
            endDate: contract.endDate
          });
        }
      }
      setSelectedLedger(0);
      setRetLedger(retLedgerDetails.retLedger);
      setDsoLedger(dsoLedgerDetails.dsoLedger);
      setDhLedger(dhLedgerDetails.dhLedger);
    }
  }

  let invoicesTable, barCode = (
    <Loading />
  );
  let latestInvoice = (
    <Loading />
  );
  let invoiceTitle = (<p></p>);
  let bankAccountFound = false;
  let pdfButton;

  const getInvoicesByLedger = (): void => {
    async function loadLedgerInvoices(): Promise<void> {
      let invoicesByLedger = await fetchInvoicesByLedger(currentActiveUser.userId, selectedLedger, endpoint, invoicePage);

      if (invoicesByLedger.length > 0) {
        invoicesByLedger = invoicesByLedger.map((invoice) => {
          invoice.contractNo = 0;
          invoice.invoiceGroup = endpoint;
          // All dates have a time of 00:00:00 so we remove that from what we will show the end user.
          const dueDate = invoice.dueDate.split('T')[0];
          const invoiceDate = invoice.invoiceDate.split('T')[0];
          invoice.dueDate = dueDate;
          invoice.invoiceDate = invoiceDate;

          return invoice;
        });

        setInvoices(invoicesByLedger);
        setInvoicePage(2);
      }
    }
    loadLedgerInvoices();
  };

  React.useEffect(() => {
    if(selectedLedger !== 0) {
      getInvoicesByLedger();
    }
  }, [selectedLedger]); // eslint-disable-line

  const fetchMoreInvoices = (test = false): void => {
    async function loadMoreInvoices(test: boolean): Promise<void> {
      let activeLedger = retLedger;

      if (dsoLedger && currentActiveUser.endpoint === 'dso') {
        activeLedger = dsoLedger;
      }
      if (dhLedger && currentActiveUser.endpoint === 'dh') {
        activeLedger = dhLedger;
      }

      let moreInvoices = await fetchInvoices(currentActiveUser.userId, activeLedger.ledgerNo, activeLedger.contractNo, endpoint, invoicePage);

      if (moreInvoices.length > 0) {
        moreInvoices = moreInvoices.map((invoice) => {
          invoice.contractNo = activeLedger.contractNo;
          invoice.invoiceGroup = endpoint;
          // All dates have a time of 00:00:00 so we remove that from what we will show the end user.
          const dueDate = invoice.dueDate.split('T')[0];
          const invoiceDate = invoice.invoiceDate.split('T')[0];
          invoice.dueDate = dueDate;
          invoice.invoiceDate = invoiceDate;

          return invoice;
        });
        if(test) {
          setMoreInvoicesAvailable(true);
        }
        else {
          setMoreInvoicesAvailable(false);
        }
      }
      else {
        moreInvoices = [];
      }
      if(!test) {
        setInvoices(invoices?.concat(moreInvoices));
        setInvoicePage(invoicePage+1);
        setMoreInvoicesAvailable(false);
        fetchMoreInvoices(true);
      }
    }
    loadMoreInvoices(test);
  };

  React.useEffect(() => {
    // check if next page is available
    fetchMoreInvoices(true);
  });

  const postponeTemplateDescription = postponeTemplate?.postponeTemplateDescription;

  const fetchPostponedPayments = (customerId: number, invoiceNo: string, endpoint: string, dueDate: string): void => {
    async function postponedPayments(): Promise<void> {
      const permissionRequest = await getPostponedPayments( customerId, invoiceNo, endpoint );
      if(permissionRequest && permissionRequest.postponedPayments && permissionRequest.postponedPayments.length > 0) {
        setMostRecentInvoiceDate(permissionRequest.postponedPayments[0].dueDate);
        setMostRecentInvoiceNumber(invoiceNo);
      }
      else {
        setMostRecentInvoiceDate(dueDate);
        setMostRecentInvoiceNumber(invoiceNo);
      }
    }
    if(customerId !== 0) {
      postponedPayments();
    }
  };

  React.useEffect(() => {
    if(invoices && invoices[0]) {
      if(invoices[0].hasPaymentAgreement === 1) {
        fetchPostponedPayments(invoices[0].customerId, invoices[0].invoiceNo, invoices[0].invoiceGroup, invoices[0].dueDate)
      }
      else {
        setMostRecentInvoiceDate(invoices[0].dueDate);
        setMostRecentInvoiceNumber(invoices[0].invoiceNo);
      }
    }
  }, [invoices]); // eslint-disable-line

  const fetchPostponeTemplates = (customerId: number, invoiceNo: string, endpoint: string): void => {
    async function postponeTemplates(): Promise<void> {
      const permissionRequest = await getPostponeTemplates( customerId, invoiceNo, endpoint );

      setPostponeTemplate(permissionRequest[0]);
      setPostponeTemplatesLoading(false);
      setPostponeTemplatesVisibility(invoiceNo);
    }
    setPostponeTemplatesLoading(true);
    postponeTemplates();
  };

  const postponeThis = (e: React.MouseEvent<HTMLSpanElement>, customerId: number, invoiceNo: string, endpoint: string, invoiceDate: string): void => {
    e.preventDefault();

    async function postpone(): Promise<void> {
      const permissionRequest = await postponeInvoice( customerId, invoiceNo, endpoint );

      if(permissionRequest.status === 200 && permissionRequest.responseText === '') {
        setPostponeTemplatesVisibility('');
        setPostponed(invoiceNo);
        setPostponing(false);
        fetchPostponedPayments(customerId, invoiceNo, endpoint, invoiceDate);
      }
      else {
        setPostponing(false);
        setPostponeError(t('contracts:consumption:postponeFailed'));
      }
    }
    setPostponeError('');
    setPostponing(true);
    postpone();
  };

  const postponeTemplateSelect: React.FunctionComponent<postponeSelectProps> = ({ customerId, invoiceNumber, invoiceGroup, invoiceDate }) => (
    <div className="postpone-select">
      <div className="detail postpone-selection">
        {postponeError !== '' && <p>{postponeError}</p>}
        <span>{postponeTemplateDescription}</span>
        <span>
          {postponing && <Loading size="small" />}
          {!postponing && <><button className="button button-blue button-small" onClick={(e): void => {
            postponeThis(e, customerId, invoiceNumber, invoiceGroup, invoiceDate);
            gaEventIfAllowed({
              category: 'Eräpäivän siirto',
              action: 'Tallenna eräpäivän siirto'
            });
          }}>{ t('common:general.confirm') }</button>
          <button className="button button-white button-small" onClick={(e): void => {e.preventDefault(); setPostponeTemplatesVisibility('')}}>{ t('common:general.cancel') }</button></>}
        </span>
      </div>
    </div>
  );

  const postpone: PostponeObject = {
    postponed: postponed,
    postponeTemplatesVisibility: postponeTemplatesVisibility,
    postponeTemplatesLoading: postponeTemplatesLoading,
    postponeTemplateSelect: postponeTemplateSelect,
    fetchPostponeTemplates: fetchPostponeTemplates,
    fetchPostponedPayments: fetchPostponedPayments
  }

  React.useEffect(() => {
    let unMounted = false;

    async function postPonable(customerId: number, contractNo: string, invoiceNo: string, endpoint: string): Promise<void> {
      const postponableRequest = await getInvoicesPostponable( customerId, contractNo, invoiceNo, endpoint );

      if(typeof postponableRequest === 'object' && !unMounted) {
        postponableRequest.invoiceNo = invoiceNo;
        setMostRecentInvoicePostponable(postponableRequest);
      }
    }
    if(invoicesFetched && invoices && invoices.length > 0 && !unMounted && invoices[0].customerId !== 0) {
      postPonable(invoices[0].customerId, invoices[0].contractNo.toString(), invoices[0].invoiceNo, invoices[0].invoiceGroup);
    }

    return (): void => {
      unMounted = true;
    };
  }, [invoices, invoicesFetched]);

  if (invoicesFetched && invoices) {
    invoicesTable = <MyBillsTable currentActiveUser = { currentActiveUser } customerType = { customerType } contracts = { contracts } userRoles = { userRoles } invoices = { invoices } endpoint = { endpoint } desiredPdf = { desiredPdf } setDesiredPdf = { setDesiredPdf } setInvoiceEndpoint = { setInvoiceEndpoint } postpone = { postpone } mostRecentInvoiceDate = { mostRecentInvoiceDate } mostRecentInvoiceNumber = { mostRecentInvoiceNumber } />;

    if (invoices.length > 0) {

      const mostRecentInvoice = invoices[0];

      let invoicePayState = t(getInvoiceState(mostRecentInvoice, mostRecentInvoicePostponable));

      invoices.map((invoice): undefined => {

        if (moment(mostRecentInvoice.dueDate).isBefore(invoice.dueDate)) {
          invoicePayState = t(getInvoiceState(invoice, mostRecentInvoicePostponable));
        }

        return undefined;
      });

      const invoiceDetails: InvoiceDetailsObject = {
        contractNumber: mostRecentInvoice.contractNo ? mostRecentInvoice.contractNo.toString() : '',
        customerId: mostRecentInvoice.customerId,
        address: activeAddress,
        amount: mostRecentInvoice.invoiceSum,
        reference: mostRecentInvoice.kid,
        invoiceGroup: mostRecentInvoice.invoiceGroup,
        invoiceState: invoicePayState,
        invoiceDue: mostRecentInvoiceDate,
        invoiceNumber: mostRecentInvoiceNumber,
        postponeable: mostRecentInvoice.postponeable,
        hasPaymentAgreement: mostRecentInvoice.hasPaymentAgreement,
        endpoint: props.endpoint
      }
      if(mostRecentInvoiceNumber === '') {
        setMostRecentInvoiceNumber(mostRecentInvoice.invoiceNo);
      }
      if(mostRecentInvoiceDate === '') {
        setMostRecentInvoiceDate(mostRecentInvoice.dueDate);
      }

      pdfButton = <><button className="button button-white button-small center" onClick = { (): void => { setDesiredPdf([mostRecentInvoice.contractNo, parseInt(mostRecentInvoice.invoiceNo)]); setInvoiceEndpoint(mostRecentInvoice.invoiceGroup) } }>{t('billing:billing.openPdf')}</button>{desiredPdf && desiredPdf[1] === parseInt(mostRecentInvoice.invoiceNo) && <Loading size="small" />}</>

      const formattedSum = `${setPriceFormat(mostRecentInvoice.invoiceSum.toString())} €`;
      invoiceTitle = (<div className="invoice-total-sum">{formattedSum}</div>);

      if (ledgers) {
        const invoiceGroup = invoiceDetails.invoiceGroup;
        let activeLedger = retLedger;

        if (dsoLedger && currentActiveUser.endpoint === 'dso') {
          activeLedger = dsoLedger;
        }
        if (dhLedger && currentActiveUser.endpoint === 'dh') {
          activeLedger = dhLedger;
        }
        // We removed the check for correct contract as that is no longer included in the api response.
        const activeLedgerDetails = _.find(ledgers, {ledgerNo: activeLedger.ledgerNo});

        if (activeLedgerDetails && _.isObject(activeLedgerDetails) && !_.isFunction(activeLedgerDetails)) {
          let invoiceContractNo: number | undefined;
          if (retLedger && invoiceGroup === 'ret') {
            invoiceContractNo = retLedger.contractNo;
          }
          if (dsoLedger && invoiceGroup === 'dso') {
            invoiceContractNo = dsoLedger.contractNo;
          }
          if (dhLedger && invoiceGroup === 'dh') {
            invoiceContractNo = dhLedger.contractNo;
          }
          // Find the correct bank account based on contract number.
          const bankAccount = getCorrectBankAccount(invoiceContractNo, contracts, invoiceGroup);
          if (bankAccount && invoiceDetails.amount >= 0) {
            activeLedgerDetails.BankAccount = bankAccount;
            bankAccountFound = true;
            barCode = (<LatestInvoiceBarCode invoiceDetails = { invoiceDetails } ledgerDetails = { activeLedgerDetails } />);
          }

          if (activeLedgerDetails.paymentType) {
            invoiceDetails.paymentType = activeLedgerDetails.paymentType.paymentTypeNo;
          }
        }
      }
      latestInvoice = (<>
        <LatestInvoice invoiceDetails = { invoiceDetails } customerType = { customerType } postpone = { postpone } />
        {bankAccountFound && barCode}
      </>);
    }
    else {
      latestInvoice = (<p>{t('billing:billing.noInvoices')}</p>);
    }
  }

  const InvoicesFlipcard: flipCardComponent[] = [
    {
      text: <div className="flipcard-header"><img src={ invoice } alt="" className="flipcard-icon"/><div><div className="flipcard-header-right"><h2>{ t('billing:billing.billingFlipcardTitle') }</h2>{ invoiceTitle }</div></div></div>,
      component: latestInvoice,
      bgColor: 'bg-light-gray',
      goToLeft: pdfButton
    }
  ];

  return(
    <>
      <div className="row">
        <div className="title">
          <h1 aria-live="polite">{t('billing:billing.billingTitle')}</h1>
        </div>
        {/* Offset for ingress. Then text is centered correctly on larger screens */}
        <div className="col-2"></div>
        <div className="ingress col-8">
          <p> {t('billing:billing.billingIngress')} </p>
          <p> {t('billing:billing.billingIngress2')} </p>
        </div>
        <div className="col-2"></div>
      </div>

      <div className="row">
        <div className="dropdown col-6 center">
          <h2>{t('contracts:consumption.place')}</h2>
          <div className="select">
            <select id="address" onChange = { handleInputChange } value={ selectedMeteringPointDetails.selectedMeteringPoint?.meteringPointId }>
              <option value="all">{t('billing:billing.showAll')}</option>
              {activeOptions.length > 0 && <optgroup label={t('billing:invoices.activeInvoices')}>{ activeOptions }</optgroup>}
              {otherOptions.length > 0 && <optgroup label={t('billing:invoices.otherInvoices')}>{ otherOptions }</optgroup>}
              {inActiveOptions.length > 0 && <optgroup label={t('billing:invoices.inActiveInvoices')}>{ inActiveOptions }</optgroup>}
            </select>
          </div>
          {ledgers && ledgers.length > 1 &&
            <>
              <h2>{t('billing:billing.ledgers')}</h2>
              <div className="select">
                <select id="ledger" onChange = { handleInputChange } value={ selectedLedger }>
                  <option value="all">{t('billing:billing.showAll')}</option>
                  {ledgers.map(ledger =>
                    <option value={ ledger.ledgerNo } key={ledger.ledgerNo}>{ledger.ledgerNo}{ledger.ledgerName && ` – ${ledger.ledgerName}`}</option>
                  )}
                </select>
              </div>
            </>
          }
        </div>

        {invoices && invoices.length > 0 && <div className="liftups flipcards">
          <Flipcard components = {InvoicesFlipcard}/>
          <BillingHistory invoices = { invoices } activeLedger = { dhLedger ? (dsoLedger ? dsoLedger : dhLedger) : retLedger } />
        </div>}
        { !invoicesFetched && <div className="col-12"><Loading /></div> }
        { invoicesFetched && (!invoices || invoices.length === 0) && <div className="col-12">{t('billing:billing.noInvoices')}</div> }
      </div>

      {invoices && invoices.length > 0 && <div className="row">
        <div className="title">
          <h2>{ t('billing:billing.billingTableTitle') }</h2>
        </div>
        { invoicesTable }
        {moreInvoicesAvailable && <button className="button" onClick={(): void => fetchMoreInvoices()}>{ t('billing:billing.billingTableLoadMore') }</button>}
      </div>}
    </>

  );
}

export default Billing;
