import React from 'react';
import { useTranslation } from 'react-i18next';

type LoadingProps = {
  size?: string;
}

const Loading:  React.FunctionComponent<LoadingProps> = (props: LoadingProps) => {
  const { t } = useTranslation([]);

  return(
    <div className={ `loader ${props.size}` }>
      {t('common:general.loadingText')}…
    </div>
  )
}

export default Loading;
