import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import slugify from 'slugify';

import Loading from './Loading';
// Utils.
import { cutString } from '../../utils/header';
// Images.
import defaultImage1 from '../../images/liftup-default-1.jpg';
import defaultImage2 from '../../images/liftup-default-2.jpg';
import defaultImage3 from '../../images/liftup-default-3.jpg';

// Types.
import { KajaveLinksProps } from '../../types/news';


const KajaveLinks:  React.FunctionComponent = () => {
  const { t } = useTranslation([]);

  const images = [
    defaultImage1,
    defaultImage2,
    defaultImage3
  ]

  const articles = [
    {
        key: 0,
        title: "kajaveLinks:link.title.first",
        image: images[0],
        description: "kajaveLinks:link.desc.first",
        link: "https://www.kajave.fi"
    },
    {
        key: 1,
        title: "kajaveLinks:link.title.second",
        image: images[1],
        description: "kajaveLinks:link.desc.second",
        link: "https://asiakkaille.kajave.fi"
    },
  ]


    let linksToShow = [];
    for (const article of articles) {
    linksToShow.push(<a href={article.link} className="liftup news col-6 col-md-6 direction-column">
          <div className="top">
            <img src={typeof article.image === 'object' ? `${process.env.REACT_APP_DRUPAL_BASE_URL}${article.image}` : images[article.key]} alt=""/>
          </div>
          <div className="bottom">
            <h3>{ t(article.title) }</h3>
            { t(article.description) }
            <span className="button button-white button-small">{ t('common:general.readMore') }</span>
          </div>
        </a>
    )
    }


  return(
    <div className="liftup-container">
      <div className="liftups">
        { linksToShow }
      </div>
    </div>
  )
}

export default KajaveLinks;
