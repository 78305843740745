import React from 'react';

import SingleFaqQuestion from './SingleFaqQuestion';
import { FaqTypes } from '../../types/faq';

const Faqs: React.FunctionComponent<FaqTypes> = (props: FaqTypes) => {
  const { category, questions, categoryShouldBeOpen } = props;
  const [ showQuestions, setShowQuestions ] = React.useState(categoryShouldBeOpen);
  const faqRef = React.useRef<HTMLDivElement>(null);

  const scrollToQuestion = (): void => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  React.useEffect(() => {
    if (showQuestions && faqRef && faqRef.current) {
      scrollToQuestion();
    }
  }, [showQuestions]);

  const preppedQuestions = questions.map((question, key): JSX.Element => {
    return <SingleFaqQuestion key = { `${category}-${key}` } question = { question } category = { category } />
  });

  const faqCategory = (
    <div className="accordion-content open">
      <div className="questions">
        { preppedQuestions }
      </div>
    </div>
  );

  return (
    <div className="accordion-element" aria-live="polite">
      <button className={`accordion-header${showQuestions ? ' open' : ''}`} onClick={(): void => setShowQuestions(!showQuestions)}>
        <h2 className="question" ref = {faqRef}>{ category }</h2>
        <div className="arrow" />
      </button>
      { showQuestions && faqCategory }
    </div>
  );
};



export default Faqs;