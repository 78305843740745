import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Loading from '../Common/Loading';
// Types.
import { Contract, EstimatedAnnualUsage, AdditionalOwners } from '../../types/contract';
// Utils.
import { generateAddress } from '../../utils/address';
// Actions.
import { fetchAdditionalOwners } from '../../actions';


type contractProps = {
  contract: Contract;
}

/**
 * The title for and internals of a contract dropdown.
 */
const CustomerContractDetails: React.FunctionComponent<contractProps> = (props: contractProps) => {
  const [ additionalOwners, setAdditionalOwners ] = React.useState<(string[] | undefined)>();
  const [ fetchingDetails, setFetchingDetails ] = React.useState<boolean>(true);
  const { t } = useTranslation([]);
  const { contract } = props;

  React.useEffect(() => {
    async function getAdditionalOwners(): Promise<void> {
      const additionalOwners: (AdditionalOwners | undefined)[] = await fetchAdditionalOwners(contract.customerId, contract.contractNo, contract.endpoint);

      if (additionalOwners.length > 0) {
        const names: string[] = [];
        additionalOwners.map((owner): undefined => {
          let name = '';
          if (owner) {
            if (owner.firstName) {
              name = owner.firstName;
              if (owner.lastName) {
                name = `${name} ${owner.lastName}`
              }
            }
            else if (owner.lastName) {
              name = owner.lastName;
            }
          }
          if (name !== '') {
            names.push(name);
          }
          return undefined;
        });
        if (names.length > 0) {
          setAdditionalOwners(names);
        }
      }
      setFetchingDetails(false);
    }

    setFetchingDetails(true);
    setAdditionalOwners(undefined);
    getAdditionalOwners();
  }, [contract.customerId, contract.contractNo, contract.endpoint]);

  const contractCustomerDetails: JSX.Element[] = [];

  if (contract.meteringPointDetails) {
    let customerCompany = contract.meteringPointDetails?.powerProvider;
    if (contract.endpoint === 'ret') {
      customerCompany = contract.meteringPointDetails?.gridOwner.name;
    }

    // We need these in a specific order but some of the values may be empty.
    // So we generate the array in steps.
    const contractDetails = [];
    if (additionalOwners) {
      additionalOwners.map((owner): undefined => {
        contractDetails.push({
          translationString: 'additionalOwner',
          value: owner
        });
        return undefined;
      });
    }
    if (contract.meteringPoint) {
      contractDetails.push({
        translationString: 'address',
        value: generateAddress(contract.meteringPoint, false)
      });
    }
    contractDetails.push({
      translationString: 'meteringpointId',
      value: contract.meteringPointDetails.meteringPointId
    });
    if (contract.meteringPointDetails.estimatedAnnualUsage.length > 0) {
      // We define a fake one to avoid used before assigned error.
      const fakeAnual = {
        meteringPointNo: 0,
        counterText: '',
        amount: 0,
        dateFrom: '1970-01-01T00:00:00',
      }
      let activeDay: EstimatedAnnualUsage = fakeAnual;
      let activeNight: EstimatedAnnualUsage = fakeAnual;
      let activeAllTime: EstimatedAnnualUsage = fakeAnual;
      contract.meteringPointDetails.estimatedAnnualUsage.map((estimation): undefined => {
        let isDay = false;
        let isNight = false;
        // Anoyingly the only way to detect if it is night or day is from a string.
        if (estimation.counterText.toLowerCase().includes('day')) {
          isDay = true;
        }
        else if (estimation.counterText.toLowerCase().includes('night')) {
          isNight = true;
        }
        if (isDay && moment(estimation.dateFrom).isAfter(activeDay.dateFrom)) {
          activeDay = estimation;
        }
        else if (isNight && moment(estimation.dateFrom).isAfter(activeNight.dateFrom)) {
          activeNight = estimation;
        }
        else if (!isNight && !isDay && moment(estimation.dateFrom).isAfter(activeAllTime.dateFrom)){
          activeAllTime = estimation;
        }
        return undefined;
      });

      if (moment(activeAllTime.dateFrom).isAfter('1970-01-01T00:00:00')) {
        contractDetails.push({
          translationString: 'yearlyConsumption',
          value: `${activeAllTime.amount} ${t('contracts:customer.consumptionUnit')}`
        })
      }
      if (moment(activeDay.dateFrom).isAfter('1970-01-01T00:00:00')) {
        contractDetails.push({
          translationString: 'yearlyConsumptionDay',
          value: `${activeDay.amount} ${t('contracts:customer.consumptionUnit')}`
        });
      }
      if (moment(activeNight.dateFrom).isAfter('1970-01-01T00:00:00')) {
        contractDetails.push({
          translationString: 'yearlyConsumptionNight',
          value: `${activeNight.amount} ${t('contracts:customer.consumptionUnit')}`
        });
      }
    }
    if(contract.endpoint === 'ret' || (contract.endpoint === 'dso' && customerCompany)) {
      contractDetails.push({
        translationString: `company${contract.endpoint}`,
        value: customerCompany,
      });
    }

    contractDetails.map((element, key): undefined => {
      contractCustomerDetails.push(
        <div key={key}>
          <span>{t(`contracts:customer.${element.translationString}`)}</span>
          <span>{element.value}</span>
        </div>
      )
      return undefined;
    });
  }

  return (
    <div className="contract-customer-details">
      {fetchingDetails && <Loading size = 'small'/>}
      {contractCustomerDetails}
    </div>
  );
}

export default CustomerContractDetails;