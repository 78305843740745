import React from 'react';
import { useTranslation } from 'react-i18next';

import Greetings from './Greetings';
import CurrentNews from '../News/CurrentNews';
// Types.
import { LandingPageProps } from '../../types/customer';
// Utils.
import { gaPageviewIfAllowed } from '../../utils/googleAnalytics';


const LandingPage: React.FunctionComponent<LandingPageProps> = (props: LandingPageProps) => {
  const { t } = useTranslation([]);
  const { currentUser, newsDetails, userHasToken, contractsContainer } = props;
  const { mobileUserSwap, toggleMobileUserSwap } = props;
  const { news } = newsDetails;
  const [ pageview, setPageview ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!pageview) {
      gaPageviewIfAllowed('/landingPage');
      setPageview(true);
    }
  }, [pageview]);

  let liftUpsToShow = (<div></div>);
  if (news) {
    liftUpsToShow = (<CurrentNews news = { news } />);
  }

  let userIdentified = false;
  if (currentUser.users.activeUser.userName !== '') {
    userIdentified = true;
  }

  return(
    <div className="container">
      <div className="row">
        <Greetings currentUser = { currentUser } newsDetails = { newsDetails } userHasToken = { userHasToken } mobileUserSwap={mobileUserSwap} toggleMobileUserSwap={toggleMobileUserSwap} />
      </div>

      { userIdentified &&
        <div>
          { contractsContainer }
        </div>
      }
    </div>
  );
}

export default LandingPage;
