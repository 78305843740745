import { AxiosResponse } from 'axios';

import API from '../Api';
import { Customer, UpdateInfoForm, DetailedChildUser } from '../types/customer';


export const getCustomerDetails = async (userId: number, endpoint: string): Promise<(Customer | undefined)[]> => {

  try {
    const customerData: AxiosResponse<Customer[]> = await API({
      method: 'get',
      url: `/customer/${endpoint}/${userId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      }
    });

  return customerData.data;
  } catch {
  };

  return [];
}

export const postCustomerDetails = async (userId: number, endpoint: string, updateDetails: UpdateInfoForm): Promise<Customer | []> => {

  const customerData: AxiosResponse<Customer> = await API({
    method: 'post',
    url: `/customer/${endpoint}/${userId}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
    data: updateDetails,
    validateStatus: function (status) {
      return status === 200 || status === 404;
    }
  });

  return customerData.data;
}

export const getChildCustomerDetails = async (userId: number, endpoint: string): Promise<DetailedChildUser> => {

  const childCustomerData: AxiosResponse<DetailedChildUser> = await API({
    method: 'get',
    url: `/customerChild/${endpoint}/${userId}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  return childCustomerData.data;
}
