import React from 'react';
import { useLocation } from '@reach/router';

import Faq from './Faq';
import ContactPage from './ContactPage';
import SubMenu from '../Common/SubMenu';

import { CurrentUser } from '../../types/customer';

type CustomerServiceProps = {
  path?: string;
  currentUser: CurrentUser;
}

const CustomerService: React.FunctionComponent<CustomerServiceProps> = (props: CustomerServiceProps) => {
  const { users, setActiveUser } = props.currentUser;
  const { activeUser } = users;
  
  const location = useLocation();
  const mainLocation = location.pathname.split('/')[1];
  const subLocation = location.pathname.split('/')[2];

  const endpoint = activeUser.endpoint;

  const currentCustomerPage = (
    <div className="tab-container">
      {mainLocation === 'customerService' && !subLocation && <ContactPage currentUser = {{ users, setActiveUser }} />}
      {subLocation === 'faq' && <Faq activeUser = { activeUser} />}
    </div>
  );
  return (
    <>
      {endpoint !== 'anon' && <SubMenu endpoint={endpoint} />}
      {currentCustomerPage}
    </>
  );
}

export default CustomerService;
