import React from 'react';
import { useTranslation } from 'react-i18next';

import SidebarLiftup from '../News/SidebarLiftup';

import Faqs from './Faqs';
import Loading from '../Common/Loading';
import { fetchFaqs } from '../../actions';
// Types.
import { questionType } from '../../types/faq';
import { SimpleUser } from '../../types/customer';
// Utils.
import { gaPageviewIfAllowed } from '../../utils/googleAnalytics';


type CustomerServiceProps = {
  path?: string;
  activeUser: SimpleUser;
}

const Faq: React.FunctionComponent<CustomerServiceProps> = (props: CustomerServiceProps) => {
  const { t } = useTranslation([]);
  const [ faqs, setFaqs ] = React.useState<questionType[]>();
  const { userId, endpoint } = props.activeUser;
  const [ pageview, setPageview ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!pageview) {
      gaPageviewIfAllowed('/faq');
      setPageview(true);
    }
  }, [pageview]);

  let openFaq = '';
  if (window.location.pathname.split('/').length >= 4) {
    openFaq = window.location.pathname.split('/')[3];
  }

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchAvailableFaqs(): Promise<void> {

      const faqs = await fetchFaqs(userId, endpoint);

      setFaqs(faqs);
    }
    // Execute the created function directly
    fetchAvailableFaqs();
  }, [userId, endpoint]);

  const contactInformation = (
    <div className="col-4">
      <SidebarLiftup />
    </div>
  );

  const categories: {[key: string]: Array<questionType>} = {};
  const categoryShouldOpen: {[key: string]: boolean} = {};

  if (faqs) {

    faqs.map((faq) => {
      if (faq && faq.category) {
        faq.category.map((category) => {
          if (!categories[category]) {
            categories[category] = [];
          }
          categories[category].push(faq);
          if (openFaq !== '') {
            if (t(`customerService:faq.${openFaq}`) === category) {
              categoryShouldOpen[category] = true;
            }
            else {
              categoryShouldOpen[category] = false;
            }
          }
          return false;
        });
      }
      return false;
    });
  }

  const sortedQuestions: Array<JSX.Element> = [];
  for (const category in categories) {
    sortedQuestions.push(<Faqs key ={ category } category = { category } questions = { categories[category] } categoryShouldBeOpen = { categoryShouldOpen[category] }/>);
  }

  return(
    <div className="row faq">
      <div className="content-header col">
        <h1 aria-live="polite"> {t('customerService:faq.faqTitle')} </h1>
        <div className="ingress">
          <p> {t('customerService:faq.faqIngress')} </p>
        </div>
      </div>
      <div className="accordion col-8">
        <div className="accordion faq-accordion">
          { sortedQuestions.length > 0 ? sortedQuestions : <Loading /> }
        </div>
      </div>

      {endpoint !== 'anon' && contactInformation}
    </div>
  );
}

export default Faq;
