import { AxiosResponse } from 'axios';

import API from '../Api';
// Types.
import { OutageContact, SingleOutageContact } from '../types/customer';


export const fetchOutageNotifications = async (userId: number): Promise<OutageContact[]> => {

  const customerOutageContacts: AxiosResponse<OutageContact[]> = await API({
      method: 'get',
      url: `/notifications/${userId}`,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return customerOutageContacts.data;
}

export const createOutageNotifications = async (userId: number, contactDetails: SingleOutageContact): Promise<XMLHttpRequest> => {

  const postOutageNotifications: AxiosResponse<OutageContact[]> = await API({
    method: 'post',
    url: `/notifications/${userId}`,
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    data: contactDetails,
    validateStatus: function (status) {
      return status === 200 || status === 404;
    }
  });

  return postOutageNotifications.request;
}

export const deleteOutageNotifications = async ( userId: number, contactDetails: SingleOutageContact): Promise<XMLHttpRequest> => {

  const deleteOutageNotifications: AxiosResponse<OutageContact[]> = await API({
    method: 'delete',
    url: `/notifications/${userId}`,
    headers: {
      Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    },
    data: contactDetails,
    validateStatus: function (status) {
      return status === 200 || status === 404;
    }
  });

  return deleteOutageNotifications.request;
}