import React from 'react';

import { customerIconWhite, arrowDown, arrowUp } from '../../icons';

type UserInformationMobileProps = {
  userName: string;
  userNumber: number;
  details: JSX.Element;
}

const UserInformationMobile: React.FunctionComponent<UserInformationMobileProps> = (props: UserInformationMobileProps) => {
  const [ showDetails, toggleMenu ] = React.useState(false);

  return (
    <div className="accordion">
      <div className={ showDetails? "accordion-element open": "accordion-element"}>
        <div className="accordion-header" onClick={(): void => toggleMenu(!showDetails)}>
          <div className="icon">
            <img src={customerIconWhite} alt=""/>
          </div>
          <div>
            <span className="customer-name">{props.userName}</span><br />
            As nro: {props.userNumber}
          </div>
          <img className="arrow" src={ showDetails ? arrowUp : arrowDown } alt=""/>
        </div>
        {showDetails && props.details}
      </div>
    </div>
  );
}

export default UserInformationMobile;
