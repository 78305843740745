import { AxiosResponse } from 'axios';

// Helper.
import API from '../Api';
// Types.
import { NewsEntry } from '../types/news';


export const fetchArticles = async (userId: number, endpoint: string): Promise<NewsEntry[]> => {
  if (!endpoint) {
    endpoint = 'anon';
  }

  const articles: AxiosResponse<NewsEntry[]> = await API({
    method: 'get',
    url: `/article/${endpoint}/${userId}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  return articles.data;
}
