import React from 'react';
import { customerIcon, customerIconWhite } from '../../icons';

type DropdownProps = {
  title: JSX.Element;
  details: JSX.Element;
  isChild?: boolean;
  isContract?: boolean;
}

/**
 * The details accordian component. Used to show contracts and user details.
 */
const DetailsDropdown: React.FunctionComponent<DropdownProps> = (props: DropdownProps) => {
  const [ showDetails, toggleDetails ] = React.useState(false);
  const details = props.details

  return (
    <div className={!showDetails ? "accordion-element closed": "accordion-element open"} aria-expanded={!showDetails ? 'false' : 'true'}>
      <button className={props.isChild ? "accordion-header hasChild" : "accordion-header"} onClick={(): void => toggleDetails(!showDetails)}>
        <div className="icon">
          {!showDetails && <img src={customerIconWhite} alt="" />}
          {showDetails && <img src={customerIcon} alt="" />}
        </div>
        {(!props.isChild || props.isContract) && <h2 className="customer-name">{ props.title }</h2>}
        {(props.isChild && !props.isContract) && <h3 className="customer-name">{ props.title }</h3>}
        <div className="arrow" />
      </button>
      <div className="accordion-content">
        {showDetails && details}
      </div>
    </div>
  );
}

export default DetailsDropdown;
