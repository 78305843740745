import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

// Types.
import { CurrentUser } from '../../types/customer';
// Images
import logo from '../../logo.svg';
import logoKajave from '../../logo_kajave.svg';
import loginLogoOrg from '../../login_logo_loisteyhtiot.svg';
import loginLogoDso from '../../login_logo_kajave.svg';
import loginLogoDh from '../../login_logo_loistelampo.svg';

type FooterProps = {
  links: {
    key: string;
    text: string;
  }[];
  kajaveTheme: boolean;
  currentUser: CurrentUser;
};

const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  const { t } = useTranslation([]);
  const linkItems = props.links;
  const users = props.currentUser.users;

  let userIdentified = false;
  if (users.activeUser.userName !== '') {
    userIdentified = true;
  }

  let activelogo = loginLogoOrg;
  
  /* Changing theme based on user endpoint */
  if (userIdentified) {
    if (props.kajaveTheme === true) {
      activelogo = logoKajave;
    } else {
      activelogo = logo;
    }
  }

  // Generate the links, including adding the active class if we are on that page.
  const menuLinks = linkItems.map(
    (link) => {
      return(
        <Link to={`/${link.key}`} key={link.key} >
          { link.text }
        </Link>
      );
    }
  );

  const loginLinks = [
    {
      src: loginLogoDso,
      title: 'Kajave',
      width: 126,
      height: 50,
      url: 'https://www.kajave.fi'
    },
    {
      src: loginLogoDh,
      title: 'Loiste Lämpö Oy',
      width: 113,
      height: 64,
      url: 'https://www.loiste.fi/kaukolampo'
    }
  ];

  return (
    <div className='footer'>
      <div className="footer-top">
        <div className="row">
          <div className="company-logo">
            <Link to="/"><img src={activelogo} alt="logo"/></Link>
          </div>
          <div className={`footer-top-links${userIdentified ? ' menu' : ' logos'}`}>
            {userIdentified && menuLinks}
            {!userIdentified && loginLinks.map((link) => (
              <a href={link.url} key={link.title}><img src={link.src} width={link.width} height={link.height} alt={link.title} /></a>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-links">
        <div className="row">
          { t("common:footer.text", {year: new Date().getFullYear()}) }
          <Link to="/terms">{ t("common:footer.conditionsLink") }</Link>
          <Link to="/accessibility">{ t("common:footer.accessibilityLink") }</Link>
          <a href={props.kajaveTheme ? 'https://www.kajave.fi/tietosuoja' : 'https://www.loiste.fi/tietosuoja'}>{ t("common:footer.privacyLink") }</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
