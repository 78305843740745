import { AxiosResponse } from 'axios';

// Helper.
import API from '../Api';
// Types.
import { questionType } from '../types/faq';


export const fetchFaqs = async (userId: number, endpoint: string): Promise<questionType[]> => {
  if (!endpoint) {
    endpoint = 'anon';
  }

  const faqs: AxiosResponse<questionType[]> = await API({
    method: 'get',
    url: `/faqs/${endpoint}/${userId}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    }
  });

  return faqs.data;
}
