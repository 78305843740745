import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLocation } from '@reach/router';

import Loading from '../Common/Loading';
import ConsumptionLocation from './ConsumptionLocation';
import Contracts from './Contracts';
import Billing from '../Billing/Billing';
// Actions.
import { fetchContractDetails, fetchInvoices, validateToken } from '../../actions';
// Types.
import { selectedMeteringPointDetails } from '../../types/consumptionRates';
import { Invoice, ContractProps, SortedContracts, ActiveLedger } from '../../types/billing';
import { Contract } from '../../types/contract';
// Utils
import { mapDSODHContracts, filterOutUndefinedContracts, filterOutContractsWithSameStartAndEndAndStateS, getSelectedMeteringPoint, accountError, alphabeticallySortContracts, contractIsClosedOrOtherType } from '../../utils/contracts';


const initialState: Array<SortedContracts> = [];
const initialContracts = {
  userId: 0,
  contracts: [],
  endPoint: ''
};
const initialLedger = {
  ledgerNo: 0,
  contractNo: 0
}

/**
 * Contracts page. Displays a list of contracts.
 */
const ContractsContainer: React.FunctionComponent<ContractProps> = (props: ContractProps) => {
  const { t } = useTranslation([]);
  const [ contracts, setContracts ] = React.useState(initialState);
  const [ individualContracts, setIndividualContracts ] = React.useState(initialState);
  const [ activeContracts, setActiveContracts ] = React.useState<SortedContracts>(initialContracts);
  // const [ activeIndividualontracts, setActiveIndividualontracts ] = React.useState<SortedContracts>(initialContracts);
  const [ contractsFetched, setContractsFetched ] = React.useState(false);
  const [ customerType, setCustomerType ] = React.useState<string>('private');
  const [ selectedMeteringPoint, setSelectedMeteringPoint ] = React.useState<selectedMeteringPointDetails>();
  const [ shouldFetchUserContracts, setFetchUserContracts ] = React.useState(true);
  const [ retLedger, setRetLedger ] = React.useState<ActiveLedger>(initialLedger);
  const [ dsoLedger, setDsoLedger ] = React.useState<ActiveLedger | undefined>();
  const [ dhLedger, setDhLedger ] = React.useState<ActiveLedger | undefined>();
  const [ invoices, setInvoices ] = React.useState<Invoice[]>();
  const [ invoicesFetched, setInvoicesFetched ] = React.useState(false);
  const [ userHasNoContracts, setUserHasNoContracts ] = React.useState<boolean | number>(false);
  const currentUserProps = props.currentUser;
  const currentActiveUser = currentUserProps.users.activeUser;
  const endpoint = currentUserProps.users.activeUser.endpoint;
  const location = useLocation();
  const mainLocation = location.pathname.split('/')[1];

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchUserContracts(): Promise<void> {

      // If we shouldn't fetch contracts, there are no contracts found for the current user or the current userid is 0 (a fake user)
      // We don't need to do anything and just return.
      if (!shouldFetchUserContracts || userHasNoContracts === currentActiveUser.userId || (userHasNoContracts && currentActiveUser.userId === 0)) {
        if (currentActiveUser.userId === 0) {
          setUserHasNoContracts(true);
        }
        return;
      }
      setFetchUserContracts(false);

      let userFoundInContracts = false;
      // We check our stored contracts and see if we need to fetch the current users contracts again.
      for (const contractGroup of contracts) {
        if (contractGroup.userId === currentActiveUser.userId && contractGroup.endPoint === currentActiveUser.endpoint) {
          userFoundInContracts = true;
          break;
        }
      }

      if (!userFoundInContracts && currentActiveUser.userId !== 0) {
        let fetchedContracts = await fetchContractDetails(currentActiveUser.userId , endpoint);
        let filteredFetchedContracts = fetchedContracts.filter(filterOutUndefinedContracts).filter(filterOutContractsWithSameStartAndEndAndStateS);
        let fetchedIndividualContracts = _.cloneDeep(filteredFetchedContracts);
        let dsoContracts: (Contract | undefined)[];
        let dhContracts: (Contract | undefined)[];
        let sortedContracts, sortedIndividualContracts;

        if (currentActiveUser.userIdDSO && fetchedContracts) {
          dsoContracts = await fetchContractDetails(currentActiveUser.userIdDSO, 'dso');

          if (dsoContracts) {
            const dsoContractsFiltered: Contract[] = mapDSODHContracts('dso', dsoContracts, filteredFetchedContracts);

            if (dsoContractsFiltered) {
              fetchedContracts = fetchedContracts.concat(dsoContractsFiltered);
              const individualFetched: Contract[] = dsoContractsFiltered.filter(filterOutUndefinedContracts);
              filteredFetchedContracts = filteredFetchedContracts.concat(individualFetched);
            }
            const individualDSOContracts: Contract[] = dsoContracts.filter(filterOutUndefinedContracts);
            fetchedIndividualContracts = fetchedIndividualContracts.concat(individualDSOContracts);
          }
        }
        // Fetch parent users connected DH contracts.
        if (currentActiveUser.userIdDH && fetchedContracts) {
          dhContracts = await fetchContractDetails(currentActiveUser.userIdDH, 'dh');

          if (dhContracts) {
            const dhContractsFiltered: Contract[] = mapDSODHContracts('dh', dhContracts, filteredFetchedContracts);

            if (dhContractsFiltered) {
              fetchedContracts = fetchedContracts.concat(dhContractsFiltered);
              const individualFetched: Contract[] = dhContractsFiltered.filter(filterOutUndefinedContracts);
              filteredFetchedContracts.concat(individualFetched);
            }
            const individualDSOContracts: Contract[] = dhContracts.filter(filterOutUndefinedContracts);
            fetchedIndividualContracts = fetchedIndividualContracts.concat(individualDSOContracts);
          }
        }

        if (fetchedContracts.length === 0 || filteredFetchedContracts.length === 0) {
          // Fake users have a userId of 0.
          // Setting the user to have an id of 0 counts as false so we explicitly set it to true.
          let userWithNoContracts: number | boolean = currentActiveUser.userId;
          if (userWithNoContracts === 0) {
            userWithNoContracts = true;
          }
          setUserHasNoContracts(userWithNoContracts);
          setContractsFetched(true);
          return;
        }
        else {
          setUserHasNoContracts(false);

          sortedContracts = filteredFetchedContracts.sort((a, b) => alphabeticallySortContracts(a, b));
          sortedIndividualContracts = fetchedIndividualContracts.sort((a, b) => alphabeticallySortContracts(a, b));
        }
        const fetchedSortedContracts = {
          userId: currentActiveUser.userId,
          contracts: sortedContracts,
          endPoint: endpoint
        }
        const fetchedSortedIndividualContracts = {
          userId: currentActiveUser.userId,
          contracts: sortedIndividualContracts,
          endPoint: endpoint
        }
        contracts.push(fetchedSortedContracts);

        individualContracts.push(fetchedSortedIndividualContracts);
        setContracts(contracts);
        setIndividualContracts(individualContracts);
        // setActiveIndividualontracts(fetchedSortedContracts);
        let meteringPointContracts: Contract[] = filteredFetchedContracts;
        // Use power contract instead of site connection for DSO child users
        if (currentActiveUser.endpoint === 'dso') {
          meteringPointContracts = filteredFetchedContracts.filter(contract => contract.contractType.contractTypeName.toLowerCase() === 'power')
        }
        const desiredMeteringPoint = getSelectedMeteringPoint(meteringPointContracts);
        setSelectedMeteringPoint(desiredMeteringPoint);
      }
      else if (currentActiveUser.userId === 0) {
        setUserHasNoContracts(true);
      }
      setContractsFetched(true);
    }
    // Execute the created function directly
    fetchUserContracts();
  }, [shouldFetchUserContracts, contracts, currentActiveUser, endpoint, userHasNoContracts, individualContracts, setUserHasNoContracts]);

  React.useEffect(() => {
    contracts.map((contractGroup): undefined => {
      if (contractGroup.userId === currentActiveUser.userId && contractGroup.endPoint === currentActiveUser.endpoint) {
        setActiveContracts(contractGroup);
        for (const contract of contractGroup.contracts) {
          if (contract.meteringPointId && contract.contractStatus.contractStatusNo.toLowerCase() !== 's') {
            if (contract.contractType.contractTypeName.toLowerCase() === 'site connection el') {
              continue;
            }
            setSelectedMeteringPoint({
              meteringPointId: contract.meteringPointId,
              startDate: contract.startDate,
              endDate: contract.endDate,
            });
            break;
          }
        }
      }
      return undefined;
    });
    individualContracts.map((contractGroup): undefined => {
      if (contractGroup.userId === currentActiveUser.userId && contractGroup.endPoint === currentActiveUser.endpoint) {

        // The user has contracts so we set to false.
        if (contractGroup.contracts.length > 0) {
          setUserHasNoContracts(false);
        }
        // setActiveIndividualontracts(contractGroup);
      }
      return undefined;
    });
  }, [currentActiveUser, contracts, individualContracts]);

  React.useEffect(() => {
    let unMounted = false;

    // Create an scoped async function in the hook
    async function fetchUserInvoices(): Promise<void> {

      let invoices: Invoice[] = [];
      if (!isNaN(retLedger.contractNo) && !isNaN(retLedger.ledgerNo)) {
        invoices = await fetchInvoices(currentActiveUser.userId, retLedger.ledgerNo, retLedger.contractNo, endpoint);
      }
      if (invoices.length > 0) {
        invoices = invoices.map((invoice) => {
          invoice.contractNo = retLedger.contractNo;
          invoice.invoiceGroup = currentActiveUser.endpoint;
          // All dates have a time of 00:00:00 so we remove that from what we will show the end user.
          const dueDate = invoice.dueDate.split('T')[0];
          const invoiceDate = invoice.invoiceDate.split('T')[0];
          invoice.dueDate = dueDate;
          invoice.invoiceDate = invoiceDate;

          return invoice;
        });
      }
      else {
        invoices = [];
      }

      if (dsoLedger && (currentActiveUser.userIdDSO || currentActiveUser.endpoint === 'dso')) {
        const dsoInvoices = await fetchInvoices(currentActiveUser.userIdDSO ? currentActiveUser.userIdDSO : currentActiveUser.userId, dsoLedger.ledgerNo, dsoLedger.contractNo, 'dso');
        dsoInvoices.map((invoice) => {
          invoice.contractNo = dsoLedger.contractNo;
          invoice.invoiceGroup = 'dso';

          if(!invoices.find(inv => inv.invoiceNo === invoice.invoiceNo)) {
            invoices = invoices.concat([invoice]);
          }
          return false;
        });
      }

      if (dhLedger && (currentActiveUser.userIdDH || currentActiveUser.endpoint === 'dh')) {
        const dhInvoices = await fetchInvoices(currentActiveUser.userIdDH ? currentActiveUser.userIdDH : currentActiveUser.userId, dhLedger.ledgerNo, dhLedger.contractNo, 'dh');
        dhInvoices.map((invoice) => {
          invoice.contractNo = dhLedger.contractNo;
          invoice.invoiceGroup = 'dh';

          if(!invoices.find(inv => inv.invoiceNo === invoice.invoiceNo)) {
            invoices = invoices.concat([invoice]);
          }
          return false;
        });
      }

      if (invoices && !unMounted) {
        invoices.sort((a, b) => (a.invoiceDate < b.invoiceDate) ? 1 : -1 );
        setInvoices(invoices);
        setInvoicesFetched(true);
      }
    }
    // Execute the created function directly
    fetchUserInvoices();

    async function updateCustomerType(): Promise<void> {
      const userDetails = await validateToken();

      const activeUserId = currentActiveUser.userId;

      let customerEndpoint;

      if (currentActiveUser.endpoint === 'ret') {
        customerEndpoint = userDetails?.ret;
      }
      if (currentActiveUser.endpoint === 'dso') {
        customerEndpoint = userDetails?.dso;
      }
      if (currentActiveUser.endpoint === 'dh') {
        customerEndpoint = userDetails?.dso;
      }

      const currentCustomerType = customerEndpoint?.customerRoleChild?.find(child => child.customerIdChild === activeUserId)?.customerTypeChild.toLowerCase();

      if (currentCustomerType && !unMounted) {
        setCustomerType(currentCustomerType);
      }
    }

    updateCustomerType();

    return (): void => {
      unMounted = true;
    };
  }, [currentActiveUser, retLedger, dsoLedger, dhLedger, endpoint]);


  React.useEffect(() => {

    if (contractsFetched && userHasNoContracts !== currentActiveUser.userId) {

      let userFound = false;

      for (const contractIndex in contracts) {
        if (contracts[contractIndex].userId === currentActiveUser.userId && contracts[contractIndex].endPoint === currentActiveUser.endpoint) {
          if (contracts[contractIndex].contracts.length > 0) {
            userFound = true;

            // We set the contractNo and ledgerNo so that everything correctly loads.
            // Our initial values aren't applicable for all users.
            for (const contract of contracts[contractIndex].contracts) {
              if (selectedMeteringPoint && contract.meteringPointId === selectedMeteringPoint.meteringPointId) {
                if (contract.endpoint === 'ret' && !contractIsClosedOrOtherType(contract)) {
                  setRetLedger({
                    contractNo: contract.contractNo,
                    ledgerNo: contract.ledgerNo
                  });
                }
                if (contract.endpoint === 'dso' && !contractIsClosedOrOtherType(contract)) {
                  setDsoLedger({
                    contractNo: contract.contractNo,
                    ledgerNo: contract.ledgerNo
                  });
                }
                if (contract.endpoint === 'dh' && !contractIsClosedOrOtherType(contract)) {
                  setDhLedger({
                    contractNo: contract.contractNo,
                    ledgerNo: contract.ledgerNo
                  });
                }
              }
            }

            setActiveContracts(contracts[contractIndex]);
          }
          else {

            contracts.splice(parseInt(contractIndex));
          }
        }
      }
      if (!userFound) {
        if (currentActiveUser.userId !== 0) {
          setFetchUserContracts(true);
          setContractsFetched(false);
        }
      }
    }
  }, [currentActiveUser, contracts, contractsFetched, selectedMeteringPoint, userHasNoContracts]);

  let toDisplay;
  switch (mainLocation){
    case 'contracts':
      toDisplay = (
        <Contracts contracts = { activeContracts.contracts } customerType = { customerType } />
      );
      break;
    case 'billing':
      toDisplay= (
        <Billing contracts = { activeContracts.contracts } currentActiveUser = { currentActiveUser } customerType = { customerType } retLedgerDetails = {{ retLedger, setRetLedger }} dsoLedgerDetails = {{ dsoLedger, setDsoLedger }} dhLedgerDetails = {{ dhLedger, setDhLedger }} selectedMeteringPointDetails = {{ selectedMeteringPoint, setSelectedMeteringPoint }} invoiceDetails = {{ invoicesFetched, setInvoicesFetched, invoices }} endpoint = { endpoint } />
      );
      break;
    case '':
      toDisplay = (
        <>
          <div className="row light-gray-bg">
            { selectedMeteringPoint && <ConsumptionLocation currentActiveUser = { currentActiveUser } contracts = { activeContracts.contracts } endpoint = { endpoint } selectedMeteringPointDetails = {{ selectedMeteringPoint, setSelectedMeteringPoint }} invoices = { invoices } setRetLedger = { setRetLedger } setDsoLedger = { setDsoLedger } setDhLedger = { setDhLedger } /> }
          </div>
        </>
      );
      break;
    // By default we set it to landing page.
    default:
      toDisplay = (<></>);
  }

  // We choose the message to show the user. By default we just say that there are none active.
  let message = (<p>{ t('contracts:contracts.noActiveContracts') }</p>);
  if ((contractsFetched && userHasNoContracts) || currentActiveUser.userId === 0) {
    // On the landing page (mainLocation is '') we have a couple potential messages to show.
    if (mainLocation === '') {
      const displayError = accountError(currentUserProps.users);
      if (displayError) {
        message = (
          <div className="ingress">
            <p>{ t('contracts:contracts.accountIssue') }</p>
          </div>
        );
      }
    }

    // If currentActiveUser.userId is 0 then that means that the user just has access to other user accounts.
    if (currentActiveUser.userId === 0) {
      message = (
        <div className="ingress">
          <p> {t('contracts:contracts.contractsIngress')} <a href="#choose-customership" onClick={(e): void => { e.preventDefault(); document.getElementById("choose-customership")!.focus(); }}>{t('contracts:contracts.contractsIngressLink')}</a>.</p>{ /* eslint-disable-line */}
          <p> {t('contracts:contracts.noContractsLandingPage')} </p>
        </div>
      );
    }
  }

  return(
    <>
      { !contractsFetched && <Loading />}
      { contractsFetched && !userHasNoContracts && toDisplay }
      { contractsFetched && userHasNoContracts && message }
    </>
  );
}

export default ContractsContainer;
