const page = {
  title: "Loiste asiointipalvelun käyttöehdot 18.1.2021",
  body: [
    "<h2>Yleistä</h2>",
    "<p>Näissä ehdoissa Loiste Oy (jäljempänä ”Loiste”) edustaa kaikkia konserniin kuuluvia yhtiöitä (mukaan lukien mutta ei rajoittuen: Loiste Energia Oy, Loiste Sähkönmyynti Oy, Kajave Oy ja Loiste Lämpö Oy). Asiakkaalla tarkoitetaan Loisteen asiakkaita, jotka ovat tehneet Loisteen kanssa sopimuksen. Henkilö on Asiakkaan valtuuttama edustaja, joka käyttää näissä ehdoissa tarkoitettuja palveluja. Jäljempänä Asiakkaaseen ja Henkilöön viitataan myös Käyttäjänä. Käyttäjä hyväksyy nämä käyttöehdot siinä muodossa kuin ne kulloinkin ovat voimassa käyttämällä Palvelua.</p>",
    "<p>Palvelulla tarkoitetaan näissä käyttöehdoissa Loisteen tuottamia tai ylläpitämiä energiankäytön raportointiin, käyttäjätietojen ja sopimusten hallintaan sekä muuhun kuluttaja- ja yritysasiakkaiden asiointiin liittyvää Oma Energia -verkkopalvelua (jäljempänä ”Palvelu”).</p>",
    "<p>Loiste kehittää Palvelua jatkuvasti ja sillä on oikeus tehdä lisäyksiä, muutoksia tai parannuksia Palveluun ja sen sisältöön osana kehitystyötään parhaaksi katsomallaan tavalla ja aikoina. Loisteella on myös milloin tahansa oikeus lisätä tai poistaa Palvelun ominaisuuksia ja lopettaa Palvelun tuottaminen.</p>",
    "<p>Loiste pidättää itselleen oikeuden muuttaa näitä asiointipalvelun ehtoja harkintansa mukaan.</p>",
    "<h2>Palvelun käyttöönotto ja siihen rekisteröityminen</h2>",
    "<p>Palvelun käyttäminen edellyttää Käyttäjältä internet-selainta sekä tarvittavia tietoliikenneyhteyksiä. Käyttäjä liittyy Palveluun rekisteröitymällä käyttäjäksi siihen liittyvillä www-sivuilla ja hyväksymällä siellä nämä Palvelun käyttöehdot itseään sitoviksi. Loiste voi lisäksi edellyttää Käyttäjältä tiettyjen tietojen antamista rekisteröitymisen yhteydessä. Rekisteröitymisen yhteydessä Käyttäjä valitsee itselleen Palvelun käytön mahdollisesti edellyttämän käyttäjätunnuksen ja salasanan.</p>",
    "<p>Loiste määrittelee menetelmät, joilla asiointipalvelu tunnistaa Käyttäjän ennen kirjautumista järjestelmään. Loiste käyttää pankin verkkotunnistuspalvelua Käyttäjän tunnistamisessa. Loiste ei vastaa Käyttäjän ja kolmannen osapuolen välisestä sopimuksesta, kolmannen osapuolen antamista tunnistetiedoista eikä kolmannen osapuolen tunnistuspalvelun toiminnasta.</p>",
    "<p>Käyttöoikeus alkaa, kun Käyttäjä on rekisteröitynyt asiointipalveluun. Loisteella on oikeus Käyttäjän käyttöoikeuden sulkemiseen, jos asiointipalvelua ei käytetty kohtuulliseksi katsottavan ajanjakson aikana tai jos käyttöoikeus muusta erityisestä syystä päättyy.</p>",
    "<h2>Vastuu palvelun sisällöstä</h2>",
    "<p>Loiste ei vastaa palvelun sisällöstä, oikeellisuudesta, täsmällisyydestä tai luotettavuudesta eikä tietojen käytön välittömästi tai välillisesti aiheuttamista vahingoista.</p>",
    "<p>Loiste ei vastaa Palvelussa olevan virheellisen, puutteellisen tai tulkinnanvaraisen tiedon käyttämisestä Käyttäjälle mahdollisesti aiheutuvista vahingoista. Palvelussa annettavia tietoja, ohjeita tai neuvoja ei muutenkaan ole tarkoitettu juridisiksi, kaupallisiksi tai muutoin Loistetta tai muuta palveluntarjoajaa sitoviksi tiedoiksi, ohjeiksi tai neuvoksi, eikä Käyttäjä siten voi vedota niihin. Niiden perusteella ei myöskään voi esittää Loistetta tai muuta palveluntarjoajaa kohtaan vaatimuksia.</p>",
    "<h2>Palvelun käytettävyys</h2>",
    "<p>Loiste pyrkii siihen, että palvelut ovat käyttäjän käytettävissä jatkuvasti ja häiriöittä. Loiste ei kuitenkaan vastaa Palvelun keskeytyksettömästä, oikea-aikaisesta eikä virheettömästä toiminnasta. Loiste ei vastaa Palvelussa ilmenevistä teknisistä vioista, huolto- tai asennustoimista aiheutuvista keskeytyksistä taikka niiden aiheuttamista Palveluun sisältyvän tai muun tiedon muuttumisesta tai katoamisesta eikä kolmansista johtuvista tiedonsiirtoon liittyvistä ongelmista, häiriöistä tai katkoksista tai niiden mahdollisesti aiheuttamista vahingoista Käyttäjälle. Loiste pyrkii rajoittamaan keskeytyksen keston niin lyhyeksi kuin mahdollista.</p>",
    "<p>Loiste pyrkii informoimaan käyttökatkoista ja saattamaan palvelun Käyttäjän käytettäväksi käyttökatkosta tiedon saatuaan. Käyttäjä voi ilmoittaa Loisteelle käyttökatkoista tai -häiriöistä palvelun käytössä Loisteen asiakaspalveluun (asiakaspalvelu@loiste.fi).</p>",
    "<h2>Oikeudet palvelun käyttöön ja sisältöön</h2>",
    "<p>Palvelun käyttö edellyttää rekisteröitymistä sekä pyydettyjen henkilö- ja mahdollisten muiden tunnistetietojen luovuttamista Loisteelle.</p>",
    "<p>Käyttäjä sitoutuu antamaan oikeat tiedot rekisteröinnin yhteydessä sekä pitämään kyseiset tiedot ajan tasalla. Käyttäjänä olevan yhteisön osalta rekisteröitymisen saa tehdä henkilö, jolla on oikeus tehdä yhteisönsä puolesta tämän ja Loisteen väliseen sopimukseen liittyviä oikeustoimia tai muulla tavoin esittää oikeutensa toimia yrityksen tai yhteisön edustajana.</p>",
    "<p>Käytön edellytyksenä on myös voimassa oleva sopimussuhde joko Loiste Oy:n tai johonkin ko. konserniin kuuluvaan yritykseen.</p>",
    "<p>Asiakas on aina vastuussa hakemukseen ja muihin tietoihin valtuuttamiensa henkilöiden oikeuksista ja niiden hallinnoinnista palvelussa. Loiste ei vastaa mahdollisesta vahingosta, joka aiheutuu tunnistetietojen oikeudettomasta käytöstä tai joutumisesta sivullisen haltuun.</p>",
    "<p>Tähän asiointipalveluun liittyvät palvelun tekstien, kuvien, ulkoasun, tunnusmerkkien tai muun sisällön omistusoikeus, tekijänoikeus, tavaramerkki- ja kaikki muut immateriaalioikeudet kuuluvat Loisteelle. Kaikki oikeudet tekijänoikeuksiin ja tavaramerkkeihin on pidätetty.</p>",
    "<p>Palvelun sisältämien tietojen käyttö yksityiseen, ei kaupalliseen tarkoitukseen, on sallittua. Palvelua ja siellä esitettyä aineistoa saa katsella, selata ja tallentaa asiakkaan päätelaitteelle sekä tulostaa osia siitä omaan henkilökohtaiseen käyttöön. Sisällön muuttaminen ja jäljentäminen eivät ole sallittua. Sisältöä ei ole luvallista kopioida tai käyttää muullakaan tavoin ilman Loisteen etukäteen antamaa kirjallista lupaa.</p>",
    "<p>Loisteella on oikeus käyttää Käyttäjän lähettämää aineistoa ja julkaista sitä tai osia siitä. Loisteella ei kuitenkaan ole oikeutta julkaista Käyttäjän lähettämää sähköpostiviestiä tai muuta selvästi ei-julkaistavaksi tarkoitettua aineistoa.</p>",
    "<h2>Käyttäjän velvollisuudet</h2>",
    "<p>Käyttäjä saa palvelun käyttöä varten haltuunsa salasanan ja käyttäjätunnuksen, joiden avulla käyttäjä voi kirjautua palveluun. Salasana ja käyttäjätunnus ovat henkilökohtaisia, ja Käyttäjä vastaa niiden säilyttämisestä ja siitä, etteivät ne päädy ulkopuolisten tietoon tai haltuun. Käyttäjä on vastuussa kaikesta kyseisellä käyttäjätunnuksella ja salasanalla tapahtuneesta palvelun käytöstä.</p>",
    "<p>Käyttäjä on velvollinen ilmoittamaan Loisteelle käyttäjätunnuksensa tai salasanansa katoamisesta taikka joutumisesta kolmannen tietoon tai haltuun. Ilmoitus tehdään sähköpostitse osoitteeseen asiakaspalvelu@loiste.fi.</p>",
    "<p>Loistella on oikeus muuttaa Käyttäjän käyttäjätunnus tai salasana sekä muut Palvelun käytön mahdollisesti edellyttämät tunnukset, mikäli tekniset tai muut hyväksyttävät syyt sitä edellyttävät. Loiste ei ole tunnusten muuttamisen vuoksi korvausvelvollinen.</p>",
    "<h2>Henkilötiedot</h2>",
    "<p>Loisteella on oikeus käsitellä Käyttäjän henkilötietoja kulloinkin voimassa olevan henkilötietolain ja muun soveltuvan lainsäädännön mukaisesti. Käyttäjien henkilötietoja käsitellään ja luovutetaan tietosuoja- ja rekisteriselosteissa kuvatuilla tavoilla. Selosteet löytyvät Loisteen verkkosivuilta.</p>",
    "<p>Palvelu hyödyntää evästeitä tai muita vastaavia tekniikoita vain teknisen toimivuuden varmistamiseksi mm. istuntojen ylläpitämiseen. Käyttäjä voi halutessaan estää evästeiden käyttämisen muuttamalla selainasetuksiaan. Evästeiden poistaminen tai niiden käytön estäminen voi kuitenkin vaikuttaa haitallisesti Sivustojen tai niiden tiettyjen toimintojen käyttämiseen tai jopa estää sen.</p>",
    "<h2>Tietoturva ja tietojen käyttäminen</h2>",
    "<p>Käyttäjä on tietoinen, että verkkoympäristö ja verkkopalvelut eivät ole täysin tietoturvallisia. Käyttäjä vastaa omien tietojärjestelmiensä ja verkkoyhteyksiensä tietoturvan asianmukaisesta hoitamisesta.</p>",
    "<p>Loiste vastaa omien tietojärjestelmiensä osalta siitä, että niiden tietoturva on asianmukaisesti järjestetty. Loisteella on mahdollisuus tarvittaessa ja tietoturvallisuuden varmistamiseksi valvoa järjestelmän käyttöä myös yksittäisen rekisteröityneen käyttäjän osalta ja oikeus keskeyttää asiointipalvelun tarjoaminen, mikäli Asiakkaan käyttämät laitteet, ohjelmat tai tietoliikenneyhteydet vaarantavat palvelun turvallisuuden.</p>",
    "<p>Loiste säilyttää kaikki Käyttäjää koskevat tai hänen lähettämänsä tiedot luottamuksellisina. Loiste järjestää Palvelunsa tietoturvan yleisesti hyväksyttävällä tavalla ja tehokkaasti ja pyrkii asianmukaisin teknisin ratkaisuin estämään asiattomien pääsyn tietojärjestelmiinsä.</p>",
    "<h2>Palvelun käytöstä aiheutuva vahingonkorvausvelvollisuus</h2>",
    "<p>Loiste ei ole vastuussa mistään suorista, epäsuorista, välittömistä tai välillisistä tai vahingonkorvausvelvollisuuteen perustuvista vahingoista tai erityisvahingoista (mukaan lukien muuan ohessa liiketoiminnan keskeytymisestä aiheutuvat vahingot), jotka johtuvat palvelun käyttämisestä tai käytön estymisestä tai palvelun minkäänlaisesta muusta hyödyntämisestä tai hyödyntämättä jättämisestä, riippumatta siitä, perustuvatko vahingot sopimukseen, oikeudenloukkaukseen, huolimattomuuteen tai muuhun perusteeseen, vaikka Loisteelle olisi etukäteen ilmoitettu tällaisten vahinkojen mahdollisuudesta.</p>",
    "<p>Tällä ei supisteta sähkömarkkina- tai kuluttajansuojalainsäädännöstä johtuvia Loisteen velvoitteita. Joka tapauksessa Loisteen vastuu rajoittuu aina korkeintaan pakottavan lainsäädännön mukaiseen vastuuseen.</p>",
    "<p>Käyttäjä sitoutuu korvaamaan Loisteelle kaikki tappiot, kustannukset ja vahingot, joita Loisteelle aiheutuu kolmansien tahojen esittämistä vaatimuksista, jotka perustuvat siihen, että käyttäjä rikkoo näitä ehtoja.</p>",
    "<h2>Oikeuksien ja velvollisuuksien siirtäminen</h2>",
    "<p>Käyttäjällä ei ole oikeutta siirtää Palvelun käyttöoikeutta taikka Palvelusta mahdollisesti tehtyä sopimusta kolmannelle osapuolelle.</p>",
    "<p>Loisteella on oikeus siirtää Palvelu tai sen ylläpito sekä siihen liittyvät vastuut ja velvollisuudet sekä Palvelusta mahdollisesti tehty sopimus samaan konserniin kuuluvalle toiselle yhtiölle. Loisteella on myös oikeus siirtää mainitut vastuut ja velvollisuudet sekä sopimus siinä tapauksessa, että Loiste tai sen liiketoiminta siirretään toiselle yhtiölle kaupan, liiketoimintasiirron, sulautumisen tai jakautumisen seurauksena tai muuhun niihin verrattavan järjestelyn seurauksena.</p>",
    "<h2>Sovellettava lainsäädäntö ja riitojenratkaisu</h2>",
    "<p>Näihin ehtoihin sovelletaan Suomen lakia, lukuun ottamatta sen lainvalintasäännöksiä. Osapuolet pyrkivät ratkaisemaan erimielisyydet ensisijaisesti neuvotteluteitse. Mikäli sovintoratkaisuun ei päästä, näitä ehtoja koskevat erimielisyydet ratkaistaan ensiasteena toimivaltaisessa käräjäoikeudessa.</p>"
  ],
  description: "description",
  category: ["null"],
  images: [undefined],
  publishDate: "1970-01-01",
  unpublishDate: null,
  benefit: false
}

export default page;