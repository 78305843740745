import React from 'react';
import { useTranslation } from 'react-i18next';

// Utils.
import { gaEventIfAllowed } from '../../utils/googleAnalytics';

const Register:  React.FunctionComponent = () => {
  const { t } = useTranslation([]);

  return(
    <div className='col-6 col-lg-5 register'>
      <div>
        <h2 className='title'>{ t('common:register.newUserQuestion') }</h2>
        <div className='newUserDetails content'>
          <p className="intro">{ t('common:register.registerMessage') }</p>
          <a href={`${process.env.REACT_APP_BASE_URL_LOGIN}/register/customeruser`} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Rekisteröidy',
              action: 'Rekisteröidy yksityishenkilönä'
            });
          }}><button>{ t('common:register.registerAs') }</button></a>
          <p>
            { t('common:register.registerDescription') }
          </p>
          <a href={process.env.REACT_APP_URL_REGISTER_COMPANY} onClick={(): void => {
            gaEventIfAllowed({
              category: 'Rekisteröidy',
              action: 'Rekisteröidy yrityksenä'
            });
          }}><button>{ t('common:register.registerAsCompany') }</button></a>
          <p>
            { t('common:register.registerDescriptionCompanies') }
          </p>
        </div>
      </div>
    </div>
  )
}

export default Register;
