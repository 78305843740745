import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../Common/Loading';

// Actions
import { getPostponedPayments } from '../../actions';
import { getInvoicesPostponable } from '../../actions/invoices';
// Types.
import { myBillsTableRowTypes, postPonableObject } from '../../types/billing';
// Utils.
import { formatDate, getVirtualBarCode, getCorrectBankAccount, setPriceFormat, getInvoiceState } from '../../utils/billing';

const MyBillsTableRow: React.FunctionComponent<myBillsTableRowTypes> = (props: myBillsTableRowTypes) => {
  const { t } = useTranslation([]);
  const { currentActiveUser, customerType, contracts, invoice, desiredPdf, setDesiredPdf, setInvoiceEndpoint, mostRecentInvoiceDate, mostRecentInvoiceNumber } = props;
  const [ userId, setUserId ] = React.useState<number>(0);  
  const { postponed, postponeTemplatesVisibility, postponeTemplatesLoading, postponeTemplateSelect, fetchPostponeTemplates } = props.postpone;
  const [ invoiceDate, setInvoiceDate ] = React.useState<string>(invoice.dueDate);
  const [ copyButtonText, setCopyButtonText ] = React.useState<string>(t('billing:barcode.copyShort'));
  const [ invoicePostponable, setInvoicePostponable ] = React.useState<postPonableObject>();

  React.useEffect(() => {
    if (currentActiveUser.endpoint !== invoice.invoiceGroup) {
      if (invoice.invoiceGroup === 'dso' && currentActiveUser.userIdDSO) {
        setUserId(currentActiveUser.userIdDSO);
      }
      else if (invoice.invoiceGroup === 'dh' && currentActiveUser.userIdDH) {
        setUserId(currentActiveUser.userIdDH);
      }
    }
    else {
      setUserId(currentActiveUser.userId);
    }
  }, [currentActiveUser, invoice.invoiceGroup]);

  const customerId = userId;
  const invoiceNumber = invoice.invoiceNo;
  const invoiceGroup = invoice.invoiceGroup;

  React.useEffect(() => {
    let unMounted = false;

    async function postPonable(customerId: number, contractNo: string, invoiceNo: string, endpoint: string): Promise<void> {
      const postponableRequest = await getInvoicesPostponable( customerId, contractNo, invoiceNo, endpoint );

      if(typeof postponableRequest === 'object' && !unMounted) {
        postponableRequest.invoiceNo = invoiceNo;
        setInvoicePostponable(postponableRequest);
      }
    }
    if(customerId !== 0 && invoice.contractNo.toString() !== '' && !unMounted) {
      postPonable(customerId, invoice.contractNo.toString(), invoiceNumber, invoiceGroup);
    }

    return (): void => {
      unMounted = true;
    };
  }, [customerId, invoice.contractNo, invoiceNumber, invoiceGroup]);

  const fetchPostponedPaymentsRow = (customerId: number, invoiceNo: string, endpoint: string): void => {
    async function postponedPayments(): Promise<void> {
      const permissionRequest = await getPostponedPayments( customerId, invoiceNo, endpoint );
      if(permissionRequest && permissionRequest.postponedPayments && permissionRequest.postponedPayments.length > 0) {
        setInvoiceDate(permissionRequest.postponedPayments[0].dueDate);
      }
    }
    if(customerId !== 0) {
      postponedPayments();
    }
  };

  React.useEffect(() => {
    if(invoice.hasPaymentAgreement === 1) {
      fetchPostponedPaymentsRow(customerId, invoiceNumber, invoiceGroup)
    }
    if(invoice.hasPaymentAgreement !== 1 && mostRecentInvoiceNumber === invoiceNumber && mostRecentInvoiceDate !== invoiceDate) {
      setInvoiceDate(mostRecentInvoiceDate);
    }
  }, [invoice.hasPaymentAgreement, customerId, invoiceNumber, invoiceDate, invoiceGroup, mostRecentInvoiceDate, mostRecentInvoiceNumber]);

  const invoicePaid = t(getInvoiceState(invoice, invoicePostponable));

  const flippedDate = formatDate(invoiceDate);
  const formattedPrice = `${setPriceFormat(invoice.invoiceSum.toString())} €`;

  const invoiceType = t(`billing:billing.${invoice.invoiceGroup}Type`);
  const invoicePdf = <><a href="#root" onClick = { (e): void => { e.preventDefault(); setDesiredPdf([invoice.contractNo, parseInt(invoice.invoiceNo)]); setInvoiceEndpoint(invoice.invoiceGroup); } } aria-label={`${t('billing:billing.openPdfByDate')} ${flippedDate}`}>{t('billing:billing.openPdf')}</a>{desiredPdf && desiredPdf[1] === parseInt(invoice.invoiceNo) && <Loading size="small" />}</>;

  const bankAccount = getCorrectBankAccount(invoice.contractNo, contracts, invoiceGroup);
  let code = '';
  let barcodeButton: JSX.Element = <></>;
  if (bankAccount) {
    code = getVirtualBarCode(bankAccount, invoice.invoiceSum, invoice.kid, invoiceDate);
  }

  if (code.length === 54) {
    const copyToClipboard = (str: string): void => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      setCopyButtonText(t('billing:barcode.copied'));
      setTimeout((): void => {
        setCopyButtonText(t('billing:barcode.copyShort'));
      }, 3000);
    }
    barcodeButton = <a href="#root" onClick={(e): void => {e.preventDefault(); copyToClipboard(code)}}>{copyButtonText}</a>;
  }

  return (
    <tr>
      <td>{ flippedDate }</td>
      <td>{ invoice.invoiceNo }</td>
      <td>{ formattedPrice }</td>
      <td>
        { invoicePaid }
        {invoicePaid !== t('billing:billing.unpaid') && invoicePaid !== t('billing:billing.paid') && invoicePostponable?.postponable === 1 && customerType !== 'business' && postponed !== invoiceNumber && postponeTemplatesVisibility !== invoiceNumber && !postponeTemplatesLoading && <button className="postponeInvoice" onClick={(): void => fetchPostponeTemplates(customerId, invoiceNumber, invoiceGroup)}>{ t("contracts:consumption.postponeBill") } &rsaquo;</button>}
        {invoicePostponable?.postponable === 1 && customerType !== 'business' && postponed === invoiceNumber && <span className="postponeInvoice postponed">{ t("contracts:consumption.postponed") }</span>}
        {invoicePostponable?.postponable === 1 && customerType !== 'business' && postponeTemplatesLoading && <Loading size="small" />}
        {invoicePostponable?.postponable === 1 && customerType !== 'business' && !postponeTemplatesLoading && postponeTemplatesVisibility === invoiceNumber && postponeTemplateSelect({customerId, invoiceNumber, invoiceGroup, invoiceDate})}
      </td>
      <td>{ invoiceType }</td>
      <td>{ invoicePdf }</td>
      <td>{ code && barcodeButton}</td>
    </tr>
  );
}

export default MyBillsTableRow;
