import Moment from 'moment';

// Types.
import { Contract } from '../types/contract';
import { LedgerDetails, Invoice, postPonableObject } from '../types/billing';

export const formatDate = (dateTime: string): string => {
  return Moment(new Date(dateTime)).format('DD.MM.YYYY');
}
export const formatFullDateTime = (dateTime: string): string => {
  return Moment(new Date(dateTime)).format('dd, DD.MM.YYYY - HH:mm');
}

export const setPriceFormat = (suppliedPriceString: string): string => {
  let formattedPrice: string;

  const price = suppliedPriceString.split('.');
  if (price.length === 2) {
    formattedPrice = `${price[0]},${price[1].length === 1 ? `${price[1]}0` : price[1]}`
  }
  else {
    formattedPrice = suppliedPriceString;
  }

  return formattedPrice;
}

/**
 * Loops the contracts and finds the active one so that we can determine the contract group no.
 *
 * @param contractNo number
 * @param contracts Contract[]
 */
const getActiveContract = (contractNo: number, contracts: Contract[], endpoint: string): Contract | undefined => {
  let activeContract;
  contracts.map((contract): undefined => {
    if (contract.contractNo === contractNo && contract.endpoint === endpoint) {
      activeContract = contract;
    }
    return undefined;
  });

  return activeContract;
}

/**
 * This function gets the correct bank code to use in barcode generation
 *
 * @param contractNo number
 * @param contracts Contrat[]
 * @param endpoint string
 */
export const getCorrectBankAccount = (contractNo: number | undefined, contracts: Contract[], endpoint: string): string | undefined => {
  // The following arrays are contract groups that belong to each different bank code.
  // dso & dh share the same endpoint.
  const retSM = [1, 3, 5, 7, 14, 16, 17, 18, 19, 20];
  const retES = [2, 4, 6, 11, 13];
  const dso = [1, 2, 3, 7, 15, 16, 17, 18, 19, 20, 21];
  const dhLL = [4, 8, 11, 12, 13, 33];
  const dhOL = [5, 23, 29, 31];

  if(contractNo) {
    const activeContract = getActiveContract(contractNo, contracts, endpoint);
    const activeContractGroupNo = activeContract?.contractGroup.contractGroupNo;
    let bankCode: string | undefined = '';

    if (activeContractGroupNo) {
      if (endpoint === 'ret') {
        if (retSM.includes(activeContractGroupNo)) {
          bankCode = process.env.REACT_APP_RET_BANKCODE_LOISTE_SM;
        }
        else if (retES.includes(activeContractGroupNo)) {
          bankCode = process.env.REACT_APP_RET_BANKCODE_LOISTE_ES;
        }
      }
      else if (endpoint === 'dso' || endpoint === 'dh') {
        if (dso.includes(activeContractGroupNo)) {
          bankCode = process.env.REACT_APP_DSO_BANKCODE_KAJAVE;
        }
        else if (dhLL.includes(activeContractGroupNo)) {
          bankCode = process.env.REACT_APP_DH_BANKCODE_LL;
        }
        else if (dhOL.includes(activeContractGroupNo)) {
          bankCode = process.env.REACT_APP_DH_BANKCODE_OL;
        }
      }
    }


    return bankCode;
  }
}

/**
 * Supplied a string returns ledger details and addresses.
 *
 * @param valueDetails A formatted string.
 */
export const getLedgerDetails = (valueDetails: string): LedgerDetails => {
  const retAndDso = valueDetails.split('+++');
  const dh = valueDetails.split('<<<');
  const value = retAndDso[0].split('---');
  const ledgerDetails = value[0].split('-');
  const ledgerNo = ledgerDetails[0];
  const contractNo = ledgerDetails[1];
  const address = value[1];
  const retLedger = {
    ledgerNo: parseInt(ledgerNo),
    contractNo: parseInt(contractNo)
  }

  let dsoLedger, dhLedger;

  if (retAndDso[1]) {
    const secondaryLedgerDetails = retAndDso[1].split('-');
    dsoLedger = {
      contractNo: parseInt(secondaryLedgerDetails[1]),
      ledgerNo: parseInt(secondaryLedgerDetails[0]),
    }
  }
  if (dh[1]) {
    const dhLedgerDetails = dh[1].split('-');
    dhLedger = {
      contractNo: parseInt(dhLedgerDetails[1]),
      ledgerNo: parseInt(dhLedgerDetails[0]),
    }
  }

  return { address, retLedger, dsoLedger, dhLedger };
}

export const getInvoiceState = (invoice: Invoice, invoicePostponable: postPonableObject | undefined): string => {
  const dueDate = invoicePostponable ? invoicePostponable.dueDate : invoice.dueDate;
  const hasPaymentAgreement = invoicePostponable ? invoicePostponable.hasPaymentAgreement : invoice.hasPaymentAgreement;

  if (Moment(dueDate).isBefore(Moment().format('YYYY-MM-DD')) && !hasPaymentAgreement && invoice.unpaidSum > 0) {
    return 'billing:billing.unpaid';
  }
  else if (hasPaymentAgreement && invoice.unpaidSum > 0) {
    return 'billing:billing.postponed';
  }
  else if (invoice.unpaidSum === 0) {
    return 'billing:billing.paid';
  }
  return 'billing:billing.open';
}

export const getVirtualBarCode = (bankAccount: string, invoiceAmount: number, reference: string, invoiceDue: string): string => {
  const bankCode = bankAccount;
  const version = 4;
  const splitAmount = invoiceAmount.toString().split('.');
  let amount = '';

  // Barcode requires the amount to be between 0 and 999999. It also always needs to be 6 didgets long.
  const amountEuros = splitAmount[0].padStart(6, '0');

  // Barcode requires cent values and they need to be 2 didgets long.
  let amountCents
  if (!splitAmount[1]) {
    amountCents = splitAmount[1] = '00';
  }
  else {
    amountCents = splitAmount[1].padEnd(2, '0');
  }
  amount = amountEuros + amountCents;

  // The reference number needs to be a total of 23 characters long.
  const referenceNumber = reference.padStart(23, '0');
  const dueDate = Moment(invoiceDue).format('YYMMDD');

  const code = version + bankCode + amount + referenceNumber + dueDate;

  return code;
}