import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../Common/Loading';
import CustomerDetails from './CustomerDetails';
import { validateToken } from '../../actions';
import { Customer, MyInfoProps, CustomerRoleChild } from '../../types/customer';
// Utils.
import { combineMyInfoChildren } from '../../utils/myinfo';
import { gaPageviewIfAllowed } from '../../utils/googleAnalytics';


const initialState: Array<Customer> = [];


export const MyInfo: React.FunctionComponent<MyInfoProps> = (props: MyInfoProps) => {
  const { t } = useTranslation([]);
  const [ customer, setCustomer ] = React.useState(initialState);
  const [ customerFetched, setCustomerFetched ] = React.useState(false);
  const [ updateUsers, setUpdateUsers ] = React.useState(true);
  const parentUser = props.currentUser.users.parentUser;
  const childUserDetails = props.currentUser.users.childUsers;
  const [ pageview, setPageview ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!pageview) {
      gaPageviewIfAllowed('/myinfo');
      setPageview(true);
    }
  }, [pageview]);

  React.useEffect(() => {
    // Create an scoped async function in the hook
    async function fetchUserDetails(): Promise<void> {
      const customerDetails = await validateToken();

      if (customerDetails && customerDetails.ret && customerDetails.ret.customerId !== 0) {
        // If ret is set properly it always takes prio.
        // We want to make sure that we have the fields that we need so that we don't crash the page.
        if (customerDetails.ret.addresses.length > 0){
          // We can't initialise the state without it being an array so we wrap the result.
          const customerToSet = customerDetails.ret;
          customerToSet.loginEmail = customerDetails.email;
          customerToSet.loginPhone = customerDetails.phone;
          customerToSet.endpoint = 'ret';
          // This works for DH or DSO because we fetch these details from SSN and either of those will return
          // the same details.
          if (customerDetails.dso?.customerId) {
            customerToSet.customerIdDSO = customerDetails.dso.customerId;
            customerToSet.customerInfoDSO = {
              firstName: customerDetails.dso.firstName,
              lastName: customerDetails.dso.lastName,
              email: customerDetails.dso.email,
              mobileNumber: customerDetails.dso.mobileNumber,
              customerId: customerDetails.dso.customerId,
              addresses: customerDetails.dso.addresses,
            }
          }

          let ret: CustomerRoleChild[] = [];
          let dso: CustomerRoleChild[] = [];
          let dh: CustomerRoleChild[] = [];
          if (customerDetails.ret?.customerRoleChild) {
            ret = customerDetails.ret.customerRoleChild;
          }
          if (customerDetails.dso?.customerRoleChild) {
            dso = customerDetails.dso.customerRoleChild;
          }
          if (customerDetails.dh?.customerRoleChild) {
            dh = customerDetails.dh.customerRoleChild;
          }
          customerToSet.customerRoleChild = await combineMyInfoChildren(ret, dso, dh);
          setCustomer([customerToSet]);
          setCustomerFetched(true);
        }
      }
      else if (customerDetails && customerDetails.dso && customerDetails.dso.customerId !== 0) {
        // If ret is not set properly but dso is then we use DSO.
        if (customerDetails && customerDetails.dso && customerDetails.dso.addresses.length > 0){
          // We can't initialise the state without it being an array so we wrap the result.
          const customerToSet = customerDetails.dso;
          customerToSet.loginEmail = customerDetails.email;
          customerToSet.loginPhone = customerDetails.phone;
          customerToSet.endpoint = 'dso';

          let ret: CustomerRoleChild[] = [];
          let dso: CustomerRoleChild[] = [];
          let dh: CustomerRoleChild[] = [];
          if (customerDetails.ret?.customerRoleChild) {
            ret = customerDetails.ret.customerRoleChild;
          }
          if (customerDetails.dso?.customerRoleChild) {
            dso = customerDetails.dso.customerRoleChild;
          }
          if (customerDetails.dh?.customerRoleChild) {
            dh = customerDetails.dh.customerRoleChild;
          }
          customerToSet.customerRoleChild = await combineMyInfoChildren(ret, dso, dh);
          setCustomer([customerToSet]);
          setCustomerFetched(true);
        }
      }
      else {
        // There are some users that have no actual contracts but do have customer roles so then we use the empty ret.
        if (customerDetails && customerDetails.ret && customerDetails.ret.addresses.length > 0){
          // We can't initialise the state without it being an array so we wrap the result.
          const customerToSet = customerDetails.ret;
          customerToSet.loginEmail = customerDetails.email;
          customerToSet.loginPhone = customerDetails.phone;
          customerToSet.endpoint = 'ret';

          let ret: CustomerRoleChild[] = [];
          let dso: CustomerRoleChild[] = [];
          let dh: CustomerRoleChild[] = [];
          if (customerDetails.ret?.customerRoleChild) {
            ret = customerDetails.ret.customerRoleChild;
          }
          if (customerDetails.dso?.customerRoleChild) {
            dso = customerDetails.dso.customerRoleChild;
          }
          if (customerDetails.dh?.customerRoleChild) {
            dh = customerDetails.dh.customerRoleChild;
          }
          customerToSet.customerRoleChild = await combineMyInfoChildren(ret, dso, dh);
          setCustomer([customerToSet]);
          setCustomerFetched(true);
        }
      }
      setUpdateUsers(false);
    }

    if (updateUsers) {
      fetchUserDetails();
    }
  }, [parentUser.userId, childUserDetails, updateUsers]);

  const childUsers: Array<JSX.Element> = [];
  if (customer[0] && customer[0].customerRoleChild) {
    customer[0].customerRoleChild.map((child): undefined => {
      let keyPart = '';
      if (child.endpoint) {
        keyPart = child.endpoint;
      }
      childUsers.push(<CustomerDetails key = {`${child.customerIdChild}${keyPart}`} customerChild = {child} setUpdateUsers = { setUpdateUsers } updateUsers = { updateUsers } />);
      return undefined;
    });
  }

  let customersYouRepresent = (<></>);

  if (childUsers.length > 0) {
    customersYouRepresent = (
      <>
        <div className="title">
          <h2>{ t('myInfo:customerInfo.representedCustomersTitle') }</h2>
        </div>
        <div className="myinfo-represent accordion col">
          {!customerFetched && <Loading />}
          {customerFetched && childUsers}
        </div>
      </>
    );
  }

  return(
    <div className="row">
      <div className="content-header col">
        <h1 aria-live="polite">{ t('myInfo:customerInfo.customerInfoTitle') }</h1>
        <div className="ingress"><p>{t('myInfo:customerInfo.ingress')}</p></div>
      </div>
      <div className="myinfo accordion col">
        {!customerFetched && <Loading />}
        {customerFetched && <CustomerDetails customer = {customer[0]} setUpdateUsers = { setUpdateUsers } updateUsers = { updateUsers } />}
      </div>
      { customersYouRepresent }
    </div>
  );
}

export default MyInfo;
