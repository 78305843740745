import React from 'react';

import Loading from '../Common/Loading';
//Actions.
import { oidcAuth } from '../../actions';
// Types.
import { CurrentUserProps, AvailableUsers } from '../../types/customer';
// Utils.
import { setUserDetails } from '../../utils/login';


const Login: React.FunctionComponent<CurrentUserProps> = (props: CurrentUserProps) => {
  const [ userCode, setUserCode ] = React.useState('');
  const currentUserProps = props.currentUser;
  const { userHasToken, setUserHasToken } = props;

  React.useEffect(() => {

    async function logUserIn(code: string): Promise<void> {
      const userDetails = await oidcAuth(code);

      if (userDetails) {
        let users: AvailableUsers = currentUserProps.users;
        if (userDetails.token && (userDetails.ret || userDetails.dso || userDetails.dh)) {
          users = setUserDetails(userDetails, currentUserProps.users);
          currentUserProps.setActiveUser(users);
          sessionStorage.setItem('token', userDetails.token);
          window.top.location.reload();
        }
      }
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const param = params.get('code');

    if (param) {
      // log user in.
      logUserIn(param);
      // Clean the uri.
      const uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
          const cleanUri = uri.substring(0, uri.indexOf("?"));
          window.history.replaceState({}, document.title, cleanUri);
      }
    }
  }, [userCode, currentUserProps]);

  // We check if the user has a token stored in sessionStorage and if there is we validate it.
  const token = sessionStorage.getItem('token');
  if (token && !userHasToken) {
    setUserHasToken(true);
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const param = params.get('code');
  if (param && !userCode) {
    setUserCode(param);
  }

  return(
    <div className='login'>
      { <Loading /> }
    </div>
  );
}

export default Login;
