import moment from 'moment';
import _ from 'lodash';
// Types.
import { Contract, Delivery, SelectedMeteringPoint } from '../types/contract';
import { generateAddress } from './address';
import { AvailableUsers } from '../types/customer';
// Utils.
import { formatDate } from './billing';

export const filterOutUndefinedContracts = (value: Contract | undefined): value is Contract => {
  return value !==  undefined;
}

export const filterOutContractsWithSameStartAndEndAndStateS = (contract: Contract): contract is Contract => {
  return contract.contractStatus.contractStatusNo.toLowerCase() !== 's' || contract.startDate !== contract.endDate;
}

// These are the contract groups for "other" contract types. Sadly there is no logic here this is just what they are.
const otherContractGroupsRet = [10, 22, 23, 24, 25, 31];
const otherContractGroupsDso = [10, 22, 33, 31];

export const mapDSODHContracts = (endpoint: string, contracts: (Contract | undefined)[], primaryContracts: Contract[]): Contract[] => {

  const contractsMapped = contracts.map((contract: Contract | undefined): Contract | undefined => {

    if (contract && contract.meteringPoint) {
      // The types depend on the endpoint.
      const contractIsOtherType = contract.endpoint.toLowerCase() === 'ret' ? otherContractGroupsRet.includes(contract.contractType.contractTypeNo) : otherContractGroupsDso.includes(contract.contractType.contractTypeNo);
      // If the contract is closed, S, true, else false.
      const contractIsClosed = contract.contractStatus.contractStatusNo.toLowerCase() === 's' ? true : false;
      const contractAddress = generateAddress(contract.meteringPoint, false).toLowerCase();
      let contractMeteringPointId;
      if (contract.meteringPointDetails) {
        contractMeteringPointId = contract.meteringPointDetails.meteringPointId;
      }
      else if (contract.meteringPointId) {
        const idStart = contract.meteringPointId.substring(0,3);
        if (idStart.toLowerCase() === 'kas') {
          contractMeteringPointId = contract.meteringPointId;
        }
      }

      for (const primaryContract of primaryContracts) {

        if (primaryContract && primaryContract.meteringPoint) {
          const primaryIsClosed = primaryContract.contractStatus.contractStatusNo.toLowerCase() === 's' ? true : false;
          const primaryIsOtherType = primaryContract.endpoint.toLowerCase() === 'ret' ? otherContractGroupsRet.includes(primaryContract.contractType.contractTypeNo) : otherContractGroupsDso.includes(primaryContract.contractType.contractTypeNo);
          const primaryAddress = generateAddress(primaryContract.meteringPoint, false).toLowerCase();
          let shouldMerge = false;

          // We currently have "other" contract types seperated (e.g. site connect) so we want to make sure the groups are merged.
          if (contractIsOtherType === primaryIsOtherType) {
            // Non-dh contracts are merged based off of meteringpoint id.
            if (endpoint !== 'dh') {
              let primaryMeteringPointId;
              if (primaryContract.meteringPointDetails) {
                primaryMeteringPointId = primaryContract.meteringPointDetails.meteringPointId;
              }
              else if (primaryContract.meteringPointId) {
                const idStart = primaryContract.meteringPointId.substring(0,3);
                if (idStart.toLowerCase() === 'kas') {
                  primaryMeteringPointId = primaryContract.meteringPointId;
                }
              }

              if (primaryMeteringPointId === contractMeteringPointId) {
                shouldMerge = true;
              }
            }
            // DH contracts are merged using the address.
            else if (primaryAddress === contractAddress) {
              shouldMerge = true;
            }
          }

          // If the endpoont isn't dh and the metering point is the same or if the endpoint is dh and the address is the same then we merge.
          if (shouldMerge) {
            // Both are either open or closed, merge.
            if (primaryIsClosed === contractIsClosed) {
              if (endpoint === 'dso') {
                primaryContract.dsoLedger = {
                  ledgerNo: contract.ledgerNo,
                  contractNo: contract.contractNo,
                };
              }
              else if (endpoint === 'dh') {
                primaryContract.dhLedger = {
                  ledgerNo: contract.ledgerNo,
                  contractNo: contract.contractNo,
                };
              }
              return undefined;
            }
          }
        }
      }
      return contract;
    }
    else {
      return contract;
    }
  });

  const filteredContracts = contractsMapped.filter(filterOutUndefinedContracts);

  return filteredContracts;
}

export const contractIsClosedOrOtherType = (contract: Contract): boolean => {
  const contractIsOtherType = contract.endpoint.toLowerCase() === 'ret' ? otherContractGroupsRet.includes(contract.contractType.contractTypeNo) : otherContractGroupsDso.includes(contract.contractType.contractTypeNo);
  const contractIsClosed = contract.contractStatus.contractStatusNo.toLowerCase() === 's' ? true : false;
  const contractIsPotential = contract.contractStatus.contractStatusNo.toLowerCase() === 'm' ? true : false;
  const contractIsSmallProduction = contract.contractGroup.contractGroupName.toLowerCase().includes('pientuotanto') ? true : false;

  if (contractIsClosed || contractIsOtherType || contractIsPotential || contractIsSmallProduction) {
    return true;
  }
  return false;
}

export const getSelectedMeteringPoint = (suppliedContracts: Contract[]): SelectedMeteringPoint | undefined => {
  let selectedMeteringPoint;

  for (const contract of suppliedContracts) {
    if (contract.meteringPointId && contract.contractStatus.contractStatusNo.toLowerCase() !== 's') {
      if (contract.contractType.contractTypeName.toLowerCase() === 'site connection el') {
        continue;
      }
      selectedMeteringPoint = {
        meteringPointId: contract.meteringPointId,
        startDate: contract.startDate,
        endDate: contract.endDate,
      };
      break;
    }
  }
  return selectedMeteringPoint;
}

export const getContractDateTo = (contract: Contract): string => {

  // If the supplied contract has an end date, it has ended so we return that date.
  if (contract.endDate) {
    return '';
  }
  let correctDelivery: Delivery | undefined;
  if (contract.endpoint === 'ret') {
    // Check if we have a fixed price element.
    contract.deliveries?.map((delivery): undefined => {
      let fixedPricedelivery = false;

      if (delivery.elementNo === 10) {
        fixedPricedelivery = true;
      }

      if (fixedPricedelivery) {
        correctDelivery = delivery;
      }
      else {
        const now = moment();
        if (moment(now).isBetween(delivery.tariffFromDate, delivery.tariffToDate)) {
          correctDelivery = delivery;
        }
      }

      return undefined;
    });
  }

  if (correctDelivery?.tariffToDate) {
    return formatDate(correctDelivery.tariffToDate);
  }

  return '';
}

/**
 * Helps to deal with the madness that is the sorting of contracts and contract types.
 *
 * @param contracts
 * @param endpoint
 */
export const filterGreetingsContracts = (contracts: (Contract | undefined)[], endpoint: string): Contract|undefined => {
  const activeContractCodes = ['a', 'm', 'o'];
  const dsoContractGroups = [1, 2, 3, 17, 18, 19, 20, 21];
  const dsoNameChangeGroups = [7, 15];
  const activeContracts: Contract[] = [];
  let desiredContract;

  contracts.map((contract): undefined => {
    if (contract) {
      if (activeContractCodes.includes(contract?.contractStatus.contractStatusNo.toLowerCase())){
        activeContracts.push(contract);
      }
    }
    return undefined;
  });

  if (activeContracts.length > 0) {
    if (activeContracts.length > 1) {
      for (const contract of activeContracts) {
        let correctType = false;
        if (endpoint === 'ret') {
          contract.deliveries?.map((delivery): undefined => {
            if (delivery.elementNo === 10) {
              correctType = true;
            }
            return undefined;
          });
        }
        if(!correctType) {
          if (dsoContractGroups.includes(contract.contractGroup.contractGroupNo)) {
            correctType = true;
            contract.contractGroup.contractGroupName = 'Verkkopalvelusopimus';
          }
          else if (dsoNameChangeGroups.includes(contract.contractGroup.contractGroupNo)) {
            correctType = true;
            contract.contractGroup.contractGroupName = 'Liittymissopimus';
          }
        }
        if (correctType) {
          desiredContract = contract;
          break;
        }
      }
    }
    else {
      desiredContract = activeContracts[0];
    }
  }

  return desiredContract;
}

/**
 * Determines based off of the many rules if a specific delivery should be displayed or not.
 *
 * @param delivery an individual delivery that we need to determine if we should show or not.
 */
export const shouldDisplayDelivery = (delivery: Delivery): boolean => {
  let shouldBeDisplayed = false;

  if (delivery.toDate && moment(delivery.toDate).isAfter(moment())) {
    shouldBeDisplayed = true;
  }

  return shouldBeDisplayed;
}

/**
 * Determines based off of available users if there is an issue with the account and thus which message we should show the user.
 *
 * @param users
 */
export const accountError = (users: AvailableUsers): boolean => {
  let isError = false;
  if (users.parentUser.userId === 0 && (!users.childUsers || users.childUsers.length === 0)) {
    isError = true;
  }

  return isError;
}

export const contractRename = (suppliedGroupName: string): string => {
  const replaceContractNames: any = { // eslint-disable-line
    "loiste sähkösopimus": "Sähkösopimus",
    "loiste vakuudet": "Vakuus",
    "eko sähkösopimus": "Sähkösopimus",
    "lsv siirtosopimus": "Verkkopalvelusopimus",
    "lsv liittymissopimus": "Liittymissopimus",
    "lsv vakuudet": "Vakuus",
    "lol liittymäsopimus,lämpö": "Kaukolämmön liittymissopimus",
    "lol kaukolämpösopimus": "Kaukolämmön toimitussopimus",
    "eko vakuudet": "Vakuus",
    "aurinkopaneeli": "Aurinkopaneeli"
  };
  const regex = new RegExp(Object.keys(replaceContractNames).join("|"), "gi");
  const group = _.cloneDeep(suppliedGroupName).toLowerCase();

  let groupName = group.replace(regex, function(match): any { return replaceContractNames[match]; }) // eslint-disable-line
  if (suppliedGroupName.toLocaleLowerCase() === groupName) {
    groupName = suppliedGroupName;
  }

  return groupName;
}

export const alphabeticallySortContracts = (a: Contract, b: Contract): number => {
  const nameA = a.meteringPoint?.streetName?.toLowerCase();
  const nameB = b.meteringPoint?.streetName?.toLowerCase();

  if(nameA && nameB) {
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  } else {
    return 0;
  }
}
