const page = {
  title: "Saavutettavuusseloste",
  body: [
    "<p>Loiste-yhtiöt pyrkii takaamaan verkkosivustonsa saavutettavuuden digitaalisten palvelujen tarjoamisesta annetun lain sekä EU:n saavutettavuusdirektiivin mukaisesti. Tämä dokumentti koskee sähköistä Oma Energia -asiointipalvelua.</p>",
    "<h2>Sivuston saavutettavuus</h2>",
    "<p>Sähköinen asiointipalvelu Oma Energia täyttää WCAG 2.1:ssä esitetyt A- ja AA-tason saavutettavuusvaatimukset suurelta osin.</p>",
    "<p>Palvelussa on ainakin seuraavia puutteita:</p>",
    "<ul>"+
      "<li>Sivustolla on kuvia, joilta puuttuu vaihtoehtoinen teksti eli tekstivastine</li>"+
      "<li>Joillakin sivuilla samaa linkkitekstiä käytetään linkeissä, jotka ohjaavat eri kohteisiin</li>"+
      "<li>Laskut-sivun Laskuhistoria-taulukko ei ole mobiilikäyttäjälle saavutettava</li>"+
      "<li>Sivustolla ei ole useita vaihtoehtoisia tapoja löytää sisältöä. Sivustolla ei ole hakutoimintoa, sillä navigointi on pyritty luomaan mahdollisimman selkeäksi.</li>"+
      "<li>Sivustolla on linkkejä, jotka avautuvat uuteen välilehteen tai siirtävät käyttäjän toiseen palveluun, joista käyttäjä ei saa tietoa ennen linkin avaamista</li>"+
    "</ul>",
    "<p>Edellä esitetyt puutteet pyritään korjaamaan mahdollisimman pian. Saavutettavuusselostetta täydennetään sitä mukaa, kun havaittuja puutteita on korjattu.</p>",
    "<h2>Saavutettavuusselosteen laadinta</h2>",
    "<p>Tämä saavutettavuusseloste on laadittu 18.1.2021. Saavutettavuusarvio perustuu sivuston ylläpitäjän omaan testaukseen ja arvioon.</p>",
    "<h2>Ilmoita saavutettavuuspuutteesta</h2>",
    "<p>Jos huomaat sivustolla saavutettavuusongelmia, anna meille palautetta! Teemme parhaamme puutteen korjaamiseksi ja saavutettavien verkkosivustojen takaamiseksi. Saavutettavuutta koskevia kyselyitä, puutteita ja kehitysehdotuksia voi lähettää Asiakaspalvelu-sivun palautelomakkeella. Vastaamme 14 päivän kuluessa.</p>",
    '<p>Jos et ole tyytyväinen saamaasi vastaukseen tai et saa vastausta lainkaan kahden viikon aikana, voit tehdä ilmoituksen Etelä-Suomen aluehallintovirastoon (ESAVI). ESAVI:n <a href="https://www.saavutettavuusvaatimukset.fi/">saavutettavuusvaatimukset.fi</a> -sivuilla on ohjeet ja osoitteet jatkotoimenpiteisiin.</p>',
    "<h2>Valvontaviranomaisen yhteystiedot</h2>",
    "<p>Etelä-Suomen aluehallintovirasto,<br />"+
      "Saavutettavuuden valvonnan yksikkö<br />"+
      '<a href="https://www.saavutettavuusvaatimukset.fi/">www.saavutettavuusvaatimukset.fi</a><br />'+
      '<a href="mailto:saavutettavuus@avi.fi">saavutettavuus@avi.fi</a><br />'+
      'puh. <a href="tel:+358295016000">0295 016 000</a> (vaihde)</p>'
  ],
  description: "description",
  category: ["null"],
  images: [undefined],
  publishDate: "1970-01-01",
  unpublishDate: null,
  benefit: false
}

export default page;