import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';

import DetailsDropdown from '../Common/DetailsDropdown';
import UpdateMyInfo from './UpdateMyInfo';
import Loading from '../Common/Loading';
// Types.
import { OuttageNotifications } from '../Contact/OuttageNotifications';
import { CustomerProps } from '../../types/customer';
import { CustomerAddress } from '../../types/address';
// Utils.
import { generateAddress } from '../../utils/address';
import { formatDate } from '../../utils/billing';

const prepName = (nameArray: (string|number)[]): string => {
  let customerName = '';
  nameArray.map((part: string|number|undefined): undefined => {
    if (part) {
      customerName = `${customerName} ${part}`;
    }
    return undefined
  });

  return customerName;
}
// Even though due to other types we know that the props sent are all strings, it doesn't like that so
// (props: CustomerProps) => string means string in this case.
const generateName: (props: CustomerProps, companyName: string) => string = (props: CustomerProps, companyName: string) => {
  const { customer, customerChild } = props;
  let customerName = '';
  if (customerChild) {
    const nameArray = [
      customerChild.customerFirstNameChild,
      customerChild.customerLastNameChild,
    ];
    if (customerChild.endpoint) {
      nameArray.push(companyName);
    }
    customerName = prepName(nameArray);
  }
  else if (customer) {
    const nameArray = [
      customer.firstName,
      customer.lastName];
      customerName = prepName(nameArray);
  }

  return customerName;
}

type AddressDetails = {
  address: CustomerAddress;
  upcomingAddress: string;
}

const getCorrectAddress = (addresses: CustomerAddress[]): AddressDetails => {
  let currentAddress = addresses[0];
  let upcomingAddress = '';
  addresses.map((address): undefined => {
    if (moment(address.fromDate).isBefore(moment())) {
      currentAddress = address;
    }
    else {
      upcomingAddress = address.fromDate;
    }
    return undefined;
  });

  const addressDetails = {
    address: currentAddress,
    upcomingAddress: upcomingAddress
  }

  return addressDetails;
}

const CustomerDetails: React.FunctionComponent<CustomerProps> = (props: CustomerProps) => {
  const [ updateUserDetails, setUpdateUserDetails ] = React.useState<boolean>(false);
  const { customer, customerChild, updateUsers, setUpdateUsers } = props;
  const { t } = useTranslation([]);
  let companyName = '';
  if (customerChild) {
    companyName = t(`common:company.${customerChild.endpoint}`);
  }
  const name = generateName(props, companyName);


  // If the user is not a child user it will have an email and mobile number.
  let customerNumber, mobileNumber, email, address, zipCode, city, country, postBox, endpoint, multipleRoles;
  let dsoDetails = (<></>);
  let upcomingAddress = '';

  if (customer) {
    let currentAddress = customer.addresses[0];
    if (customer.addresses.length > 1) {
      const addressDetails = getCorrectAddress(customer.addresses);
      currentAddress = addressDetails.address;
      upcomingAddress = addressDetails.upcomingAddress;
    }

    customerNumber= customer.customerId;
    mobileNumber = customer.mobileNumber;
    email = customer.email;
    address = generateAddress(currentAddress, true);
    zipCode = currentAddress['zipCode'];
    city = currentAddress['cityName'];
    country = currentAddress['countryName'];
    postBox = currentAddress['postOfficeBox'];
    endpoint = customer.endpoint;
    multipleRoles = (customer.customerId && customer.customerIdDSO) || (customer.customerId && customer.customerIdDH) || (customer.customerIdDSO && customer.customerIdDH);

    if (customer.customerInfoDSO) {
      let upcomingDSOAddress = '';
      let currentDSOAddress = customer.customerInfoDSO.addresses[0];
      if (customer.customerInfoDSO.addresses.length > 1) {
        const addressDetailsDSO = getCorrectAddress(customer.addresses);
        currentDSOAddress = addressDetailsDSO.address;
        upcomingDSOAddress = addressDetailsDSO.upcomingAddress;
      }

      dsoDetails = (
        <>
          <div className="details-info">
            <p>
              <Trans i18nKey="myInfo:customerInfo.contact">
                . <strong>{{company: t(`common:company.dso`)}}</strong>
              </Trans>
            </p>
          </div>
          <div className="phone detail col-3"> <label> { t("common:general.phone") } </label><p> {customer.customerInfoDSO.mobileNumber} </p></div>
          <div className="email detail col-3"> <label> { t("common:general.email") } </label><p> {customer.customerInfoDSO.email} </p></div>
          <div className="customerNumber detail col-6"> <label> { t("common:general.customerNumber") } </label><p> {customer.customerInfoDSO.customerId} </p></div>
          {address && <div className="address detail col-3"> <label> { t("common:general.address") } </label><p>{generateAddress(currentDSOAddress, true)}</p></div>}
          {postBox && <div className="postBox detail col-3"> <label> { t("common:general.postBox") } </label><p>{currentDSOAddress.postOfficeBox}</p></div>}
          <div className="zipcode detail col-3"> <label> { t("common:general.zipcode") } </label><p> {currentDSOAddress['zipCode']} </p></div>
          <div className="city detail col-3"> <label> { t("common:general.city") } </label><p> {currentDSOAddress['cityName']} </p></div>
          <div className="country detail col-3"> <label> { t("common:general.country") } </label><p> {currentDSOAddress['countryName']} </p></div>
          { upcomingDSOAddress !== '' && <div className="upcoming detail col-3"><label> { t("common:general.upcoming") } </label><p> {formatDate(upcomingDSOAddress)} </p></div> }
        </>
      );
    }
  }
  else if (customerChild && customerChild.address && customerChild.address.length > 0) {
    let currentAddress = customerChild.address[0];
    if (customerChild.address.length > 1) {
      const addressDetails = getCorrectAddress(customerChild.address);
      currentAddress = addressDetails.address;
      upcomingAddress = addressDetails.upcomingAddress;
    }
    customerNumber = customerChild.customerIdChild
    mobileNumber = customerChild.mobileNumber;
    email = customerChild.email;
    address = generateAddress(currentAddress, true);
    zipCode = currentAddress['zipCode'];
    city = currentAddress['cityName'];
    postBox = currentAddress['postOfficeBox'];
    country = currentAddress['countryName'];
    endpoint = customerChild.endpoint;
  }

  const title = (
    <>{name}</>
  );

  let outtage = <></>;
  let loginUser = <></>;
  let userId = customer?.customerId;
  if (customer?.customerIdDSO) {
    userId = customer.customerIdDSO;
  }
  else if (customerChild?.endpoint) {
    userId = customerChild.customerIdChild;
  }

  if (customer?.loginEmail || customer?.loginPhone) {
    loginUser = (
      <>
        <div className="details-info">
          <p> { t(`myInfo:customerInfo.ingressLogin`) } </p>
        </div>
        <div className="email detail col-3"> <label> { t("common:general.email") } </label><p> {customer.loginEmail} </p></div>
        <div className="phone detail col-3"> <label> { t("common:general.phone") } </label><p> {customer.loginPhone} </p></div>
        <div className="update-button"><a href= { `${process.env.REACT_APP_UPDATE_LOGIN}` } className="button button-small button-white">{ t('myInfo:customerInfo.updateLogin') }</a></div>
      </>
    );
  }

  let customerDetails = (
    <>
      <div className="details-info">
        <p> { t(`myInfo:customerInfo.ingress${endpoint}`) } </p>
        {multipleRoles &&
          <p>
            <Trans i18nKey="myInfo:customerInfo.contact">
              . <strong>{{company: t(`common:company.${endpoint}`)}}</strong>
            </Trans>
          </p>
        }
      </div>
      <div className="phone detail col-3"> <label> { t("common:general.phone") } </label><p> {updateUsers && <Loading size = 'small'/>}{!updateUsers && mobileNumber} </p></div>
      <div className="email detail col-3"> <label> { t("common:general.email") } </label><p> {updateUsers && <Loading size = 'small'/>}{!updateUsers && email} </p></div>
      <div className="customerNumber detail col-6"> <label> { t("common:general.customerNumber") } </label><p> {customerNumber} </p></div>
      {address && <div className="address detail col-3"> <label> { t("common:general.address") } </label><p> {address} </p></div>}
      {postBox && <div className="postbox detail col-3"> <label> { t("common:general.postbox") } </label><p> {postBox} </p></div>}
      <div className="zipcode detail col-3"> <label> { t("common:general.zipcode") } </label><p> {zipCode} </p></div>
      <div className="city detail col-3"> <label> { t("common:general.city") } </label><p> {city} </p></div>
      <div className="country detail col-3"> <label> { t("common:general.country") } </label><p> {country} </p></div>
      { upcomingAddress !== '' &&  <div className="upcoming detail col-3"><label> { t("common:general.upcoming") } </label><p> {formatDate(upcomingAddress)} </p></div> }
      {dsoDetails}
      {endpoint !== 'ret' && <button onClick = { (): void => { setUpdateUserDetails(true) }}>{ t('myInfo:customerInfo.updateDetails') }</button>}
    </>
  );

  if (updateUserDetails && customer) {
    customerDetails = (
      <>
        <UpdateMyInfo customer = { customer } toggleForm = { setUpdateUserDetails } setUpdateUsers = { setUpdateUsers } />
      </>
    );
  }
  else if (updateUserDetails && customerChild) {
    if (customerChild.email) {
      customerDetails = (
        <>
          <UpdateMyInfo customerChild = { customerChild } toggleForm = { setUpdateUserDetails } setUpdateUsers = { setUpdateUsers } />
        </>
      )
    }
  }

  const userIsDSO = (customer?.endpoint === 'dso' || customerChild?.endpoint === 'dso' || customer?.customerIdDSO);
  if (userId && userIsDSO) {
    outtage = <OuttageNotifications customerId = { userId } />
  }

  const details = (
    <>
      <div className="accordion-inner">
        <div className="details">
          <div className="details-content">
            { loginUser }
            { customer?.customerId !== 0 && customerDetails }
          </div>
        </div>
      </div>
      <div className="accordion-bottom">
        { outtage }
      </div>
    </>
  );

  return(
    <div className="accordion">
      <DetailsDropdown title = { title } details = { details } isChild = { customerChild ? true : false } />
    </div>
  );
}

export default CustomerDetails;
