import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../Common/Loading';

// Actions
import { postContactForm } from '../../actions';
// Types
import { ContactInfo } from '../../types/contactInfo';
import { CurrentUser } from '../../types/customer';
// Utils
import { validateEmail, validatePhoneNumber } from '../../utils/contact';
import { gaEventIfAllowed } from '../../utils/googleAnalytics';

type ContactFormProps = {
  currentUser: CurrentUser;
}

const ContactForm: React.FunctionComponent<ContactFormProps> = (props: ContactFormProps) => {
  const { t } = useTranslation([]);

  const defaultForm = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    message: ''
  }

  const { activeUser } = props.currentUser.users;
  const { endpoint, userId, userIdDSO, userIdDH } = activeUser;
  const [formStatus, setFormStatus] = useState<boolean>(true);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<string[]>([]);
  const [ validEmail, setValidEmail ] = useState<boolean>(false);
  const [ validPhone, setValidPhone ] = useState<boolean>(false);
  const [ userRoles, setUserRoles ] = useState<string[]>([]);
  const [ userIdState, setUserIdState ] = useState<number>(userId);
  const [form, setForm] = useState<ContactInfo>(defaultForm);

  React.useEffect(() => {
    const checkUser = (): void => {
      if(userId !== userIdState) {
        setUserRoles([]);
        setUserIdState(userId);
      }

      if(userId > 0) {
        setUserRoles(userRoles => [...userRoles, endpoint.toUpperCase()]);
        if(form.role === '') {
          setForm({...defaultForm, role: endpoint.toUpperCase()});
        }
      }
      if(userIdDSO && userIdDSO > 0) {
        setUserRoles(userRoles => [...userRoles, 'DSO']);
        if(userId === 0 && form.role === '') {
          setForm({...defaultForm, role: 'DSO'});
        }
      }
      if(userIdDH && userIdDH > 0) {
        setUserRoles(userRoles => [...userRoles, 'DH']);
        if(userId === 0 && form.role === '') {
          setForm({...defaultForm, role: 'DH'});
        }
      }
    }
    if (userId !== 0) {
      if (form.role === '' || userId !== userIdState) {
        setFormStatus(true);
        checkUser();
      }
    }
    else {
      if(formStatus === true) {
        setFormStatus(false);
      }
    }
  }, [userId, userIdState, userIdDSO, userIdDH, defaultForm, endpoint, form.role, formStatus]);

  const updateForm = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>): void => {
    const { target } = event;
    const { value } = target;
    const fieldName: keyof ContactInfo = target.name as keyof ContactInfo;
    const newForm: ContactInfo = {...form};

    switch (fieldName){
      case 'email':
        setValidEmail(validateEmail(value));
        if(!validEmail && !messages.includes(t('common:contactform.email'))) {
          messages.push(t('common:contactform.email'));
          setMessages(messages);
        }
        else if(validEmail) {
          setMessages(messages.filter(message => message !== t('common:contactform.email')));
        }
        break;
      case 'phone':
        setValidPhone(validatePhoneNumber(value));
        if(!validPhone && !messages.includes(t('common:contactform.phone'))) {
          messages.push(t('common:contactform.phone'));
          setMessages(messages);
        }
        else if(validPhone) {
          setMessages(messages.filter(message => message !== t('common:contactform.phone')));
        }
        break;
    }

    setForm(newForm);
    newForm[fieldName] = value;
  }

const submitForm = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setMessages([]);
    setFormLoading(true);

    gaEventIfAllowed({
      category: 'Yhteydenotto',
      action: 'Yhteydenottolomake lähetetty'
    });

    const hasEmptyValue = Object.values(form).some(value => value === '');
    if (!hasEmptyValue && validEmail && validPhone) {
      const contactFormRequest = await postContactForm(form, endpoint, form.role, userId);

      if(contactFormRequest.status === 200) {
        setForm(defaultForm);
        setMessages([t('common:contactform.success')]);
        setFormLoading(false);
        setFormStatus(false);
      }
      else if(contactFormRequest.status === 201) {
        setForm(defaultForm);
        setMessages([t('common:contactform.created')]);
        setFormLoading(false);
        setFormStatus(false);
      }
      else {
        setMessages([t('common:contactform.error')]);
        setFormLoading(false);
      }
    }
    if(hasEmptyValue) {
      messages.push(t('common:contactform.empty'));
      setMessages(messages);
      setFormLoading(false);
    }
    if(!validEmail) {
      messages.push(t('common:contactform.email'));
      setMessages(messages);
      setFormLoading(false);
    }
    if(!validPhone) {
      messages.push(t('common:contactform.phone'));
      setMessages(messages);
      setFormLoading(false);
    }
  }

  const uniqueRoles = Array.from(new Set(userRoles));

  return (
    <form id="contact-form" className='contact-form' onSubmit={submitForm} >
      <div className="form-title"><h2>{ t('customerService:contact-us.contactFormTitle') }</h2></div>

      {formLoading && <div className="message contact-message"><Loading /></div>}
      {formStatus && !formLoading && <div className="form-items">
        <div className="form-item col-6">
          <label htmlFor="firstname">{ t('common:general.firstName') } *</label>
          <input type="text" id="firstname" name="firstName" onChange={updateForm} onBlur={updateForm} value={form.firstName} required />
        </div>
        <div className="form-item col-6">
          <label htmlFor="lastname">{ t('common:general.lastName') } *</label>
          <input type="text" id="lastname" name="lastName" onChange={updateForm} onBlur={updateForm} value={form.lastName} required />
        </div>
        <div className="form-item col-6">
          <label htmlFor="phone">{ t('common:general.phone') } *</label>
          <input type="tel" placeholder="+358" id="phone" name="phone" onChange={updateForm} onBlur={updateForm} value={form.phone} required />
          <span>{t('common:contactform.phone')}</span>
        </div>
        <div className="form-item col-6">
          <label htmlFor="email">{ t('common:general.email') } *</label>
          <input type="email" id="email" name="email" onChange={updateForm} onBlur={updateForm} value={form.email} required />
          {messages.includes(t('common:contactform.email')) && <span aria-live="polite">{t('common:contactform.email')}</span>}
        </div>
        {uniqueRoles.length > 1 && <div className="form-item col-6">
          <label htmlFor="role">{ t('common:general.role') } *</label>
          <div className="select">
            <select id="role" name="role" onChange={updateForm} onBlur={updateForm}>
              {userRoles.includes('RET') && <option value="RET">{ t('common:contactform.roleRet') }</option>}
              {userRoles.includes('DSO') && <option value="DSO">{ t('common:contactform.roleDso') }</option>}
              {userRoles.includes('DH') && <option value="DH">{ t('common:contactform.roleDh') }</option>}
            </select>
          </div>
        </div>}
        <div className="form-item col-12">
          <div className="message">
            <label htmlFor="message" className="h3">{ t('customerService:contact-us.contactTextArea') } *</label>
            <textarea id="message" name="message" onChange={updateForm} onBlur={updateForm} value={form.message} required />
          </div>
        </div>
        {messages.length !== 0 && <div className="message contact-message" aria-live="polite">{messages.map((message, key) => <p key={key}>{message}</p>)}</div>}
        <div className="form-item col-12 submit">
          <button className="button button-large button-white center" disabled={messages.length === 0 ? false : true}> { t('customerService:contact-us.submitButton') } </button>
        </div>
      </div>}
      {!formStatus && !formLoading &&
        <div className="message contact-message" aria-live="polite">
          {messages}
          {userId === 0 && <a href="#choose-customership" onClick={(e): void => { e.preventDefault(); document.getElementById("choose-customership")!.focus(); }}>{`${t('contracts:contracts.contractsIngressLink').charAt(0).toUpperCase()}${t('contracts:contracts.contractsIngressLink').slice(1)}`}</a>}{ /* eslint-disable-line */}
        </div>
      }
    </form>
  );
}

export default ContactForm;
