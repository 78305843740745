import React from 'react';
import { useTranslation } from 'react-i18next';

import ContractDetails from './ContractDetails';
import DetailsDropdown from '../Common/DetailsDropdown';
// Types.
import { Contract } from '../../types/contract';
// Utils.
import { contractRename } from '../../utils/contracts';
import { gaPageviewIfAllowed } from '../../utils/googleAnalytics';


type ContractProps = {
  contracts: Contract[];
  customerType: string;
}


/**
 * Contracts page. Displays a list of contracts.
 */
const Contracts: React.FunctionComponent<ContractProps> = (props: ContractProps) => {
  const { t } = useTranslation([]);
  const { contracts, customerType } = props;
  const [ pageview, setPageview ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!pageview) {
      gaPageviewIfAllowed('/contracts');
      setPageview(true);
    }
  }, [pageview]);

  const contractGroups: {[key: string]: Array<JSX.Element>} = {};
  const expiredContractGroups: {[key: string]: Array<JSX.Element>} = {};
  const groupedContracts: Array<JSX.Element> = [];
  const expiredGroupedContracts: Array<JSX.Element> = [];

  if (contracts) {
    for (const contract of contracts) {
      const contractGroup = contract.contractGroup.contractGroupName.toLowerCase();

      if (contract.contractStatus.contractStatusNo.toLowerCase() === 's') {
        if (!expiredContractGroups[contractGroup]) {
          expiredContractGroups[contractGroup] = [];
        }
        expiredContractGroups[contractGroup].push(<ContractDetails key={contract.contractNo} contract = { contract } customerType = { customerType } />);
      }
      else {
        if (!contractGroups[contractGroup]) {
          contractGroups[contractGroup] = [];
        }
        contractGroups[contractGroup].push(<ContractDetails key={contract.contractNo} contract = { contract } customerType = { customerType } />);
      }
    }

    for (const groupName in contractGroups) {
      const groupTitle = contractRename(groupName);
      const singleGroup = <DetailsDropdown key = { groupTitle } title = { <span className="title">{groupTitle}</span> } details = { <div className="children-accordion-inner"> { contractGroups[groupName] } </div> } isChild = { true } isContract = { true}  />
      groupedContracts.push(singleGroup);
    }
    for (const groupName in expiredContractGroups) {
      const groupTitle = contractRename(groupName);
      const singleGroup = <DetailsDropdown key = { groupTitle } title = { <span className="title">{groupTitle}</span> } details = { <div className="children-accordion-inner"> { expiredContractGroups[groupName] } </div> } isChild = { true } isContract = { true}  />
      expiredGroupedContracts.push(singleGroup);
    }
  }

  let expiredContracts = (<></>);

  if (expiredGroupedContracts.length > 0) {
    expiredContracts = (
      <div className="row">
        <div className="content-header col">
          <h1 aria-live="polite"> { t('contracts:contracts.expiredContractsTitle') }</h1>
          <div className="ingress"><p> {t('contracts:contracts.contractsIngress')} <a href="#choose-customership" onClick={(e): void => { e.preventDefault(); document.getElementById("choose-customership")!.focus(); }}>{t('contracts:contracts.contractsIngressLink')}</a>.</p></div>{ /* eslint-disable-line */}
        </div>
        <div className="contracts accordion col">
          { expiredGroupedContracts }
        </div>
      </div>
    );
  }

  return(
    <>
      <div className="row">
        <div className="content-header col">
          <h1 aria-live="polite"> { t('contracts:contracts.activeContractsTitle') }</h1>
          <div className="ingress"><p> {t('contracts:contracts.contractsIngress')} <a href="#choose-customership" onClick={(e): void => { e.preventDefault(); document.getElementById("choose-customership")!.focus(); }}>{t('contracts:contracts.contractsIngressLink')}</a>.</p></div>{ /* eslint-disable-line */}
        </div>
        <div className="contracts accordion col">
          { groupedContracts }
        </div>
      </div>
      { expiredContracts }
    </>
  );
}

export default Contracts;
