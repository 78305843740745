import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import BillGraph from '../Graphs/BillGraph';
import Loading from '../Common/Loading';
// Types.
import { invoiceHistory } from '../../types/billing';
// Images.
import { savings } from '../../icons';
// Utils.
import { formatDate } from '../../utils/billing';
import { Link } from '@reach/router';


const BillingHistory:  React.FunctionComponent<invoiceHistory> = (props: invoiceHistory) => {
  const { t } = useTranslation([])
  const [ startDate, setStartDate ] = React.useState('1970-01-01');
  const [ endDate, setEndDate ] = React.useState('');
  const { invoices, activeLedger } = props;

  React.useEffect(()=> {

    let now = Moment().format('YYYY-MM-DD')

    if (invoices && invoices.length > 0) {
      if (Moment(invoices[0].dueDate).isBefore(now)) {
        now = invoices[0].dueDate;
      }
      if(startDate === '1970-01-01') {
        setStartDate(invoices[invoices.length - 1].dueDate);
      }
    }

    setEndDate(now);
  },[invoices, startDate]);

  let graphComponent = (
    <Loading />
  );
  if (invoices) {
    graphComponent = <BillGraph invoices = { invoices } startDate = { startDate } endDate = { endDate } activeLedger = { activeLedger } />
  }

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {

    if (event.target.name === 'start-date') {
      setStartDate(event.target.value);
    }
    else {
      setEndDate(event.target.value);
    }
  }

  const flippedStartDate = formatDate(startDate);
  const flippedEndDate = formatDate(endDate);

  return(
    <div className="liftup billing-graph direction-column col-6">
      <div className="inner">
        <div className="top col">
          <img src={ savings } className="icon" alt=""></img>
          <h2>{ t('billing:billing.billingGraphTitle') }</h2>
        </div>
        <div className="filters">
          <div>
            <div>
              <span>{t('billing:billing.startDate')}</span>
              <input type="date" id="start-date" name="start-date" value = { startDate }  onChange={ handleFilterChange } />
            </div>
            <div>
              <span>{t('billing:billing.endDate')}</span>
              <input type="date" id="end-date" name="end-date" value = { endDate } onChange={ handleFilterChange } />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="euro">€</div>
          { graphComponent }
        </div>
        <div className="billing-dates">
          <span>{ flippedStartDate }</span>
          <span>{ flippedEndDate }</span>
        </div>
        <div className="col">
          <Link to="/consumption" className="button button-white button-small">{ t('billing:billing.consumptionLink') }</Link>
        </div>
      </div>
    </div>
  )
}

export default BillingHistory;
