import { AxiosResponse } from 'axios';

import API from '../Api';
import { Contract } from '../types/contract';


export const fetchContractDetails = async (userId: number, endpoint: string): Promise<(Contract | undefined)[]> => {

  try {
    const contracts: AxiosResponse<Contract[]> = await API({
      method: 'get',
      url: `/contracts/` + endpoint + '/' + userId,
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

    const contractData = contracts.data.map((dataPoint) => {
      dataPoint.endpoint = endpoint;

      return dataPoint;
    });

  return contractData;
  } catch {
  };

  return [];
}

