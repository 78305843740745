import React, { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import MobileUserSwap from './MobileUserSwap';
import { cutString } from '../../utils/header';
// Types.
import { CurrentUser } from '../../types/customer';
// Images.
import logo from '../../logo.svg';
import logoKajave from '../../logo_kajave.svg';
import loginLogoOrg from '../../login_logo_loisteyhtiot.svg';
import { menuIconWhite, closeMenuIcon, userGroupIcon, homeIcon, facebookIcon, instagramIcon, twitterIcon, youtubeIcon, linkedinIcon, padlock } from '../../icons';

type LinkProps = {
  to: string;
}
type NavLinkProps = {
  className: string;
}

const NavLink: any = (props: LinkProps) => ( // eslint-disable-line
  <Link
    {...props}
    getProps={({ isPartiallyCurrent, location, href }): NavLinkProps => {
      return {
        className: isPartiallyCurrent || (location.pathname === '/article' && href === '/news') ? 'active' : ''
        }
      }
    }
  />
);

export function useWindowSize(): number[] {
  const[ size, setSize] = useState([window.innerWidth]);
  useEffect(() => {
    const handleResize = (): void => {
      setSize([window.innerWidth]);
    };
    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return size;
}

type HeaderProps = {
  links: {
    key: string;
    text: string;
  }[];
  currentUser: CurrentUser;
  mobileUserSwap: boolean;
  toggleMobileUserSwap: (value: React.SetStateAction<boolean>) => void;
};

const Header: React.FunctionComponent<HeaderProps> = (props: HeaderProps) => {
  const { t } = useTranslation([]);
  const windowWidth = useWindowSize()[0];
  const isMobile = windowWidth < 768;
  const [ displayMenu, toggleMenu ] = React.useState(false);
  const { mobileUserSwap, toggleMobileUserSwap } = props;
  const linkItems = props.links;
  const users = props.currentUser.users;
  // Navigation will drop to different row when using smaller screens
  // and that is what we check here
  let smallScreen = false;
  
  if(windowWidth < 1250) {
    smallScreen = true;
  }
  else {
    smallScreen = false;
  }

  // This isn't for authorisation, it is for identification, permissions are checked in the backend.
  let userIdentified = false;
  if (users.activeUser.userName !== '') {
    userIdentified = true;
  }

  const mobileChangeUserClick = (): void => {
    toggleMobileUserSwap(!mobileUserSwap);
    toggleMenu(!displayMenu);
  }

  const logUserOut = (): void => {
    sessionStorage.removeItem('token');
    if (process.env.REACT_APP_LOGOUT_URL) {
      window.location.href = process.env.REACT_APP_LOGOUT_URL;
    }
  }

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement>): void => {

    event.persist();
    const suppliedUserId = parseInt(event.target.value.split('-')[0]);
    const endpoint = event.target.value.split('-')[1];

    let userToUpdate = users.parentUser;
    if (users.childUsers) {
      for (const child of users.childUsers) {

        if (child.userId === suppliedUserId && child.endpoint === endpoint) {
          userToUpdate = child;
        }
      }
    }

    props.currentUser.setActiveUser({
      ...users,
      activeUser: userToUpdate
    });
  }

  const mobileMenuHeader = (
    <div className="mobile-menu-header">
      <div className="home-button">
        <Link to="/"><img src={homeIcon} onClick={(): void => { toggleMenu(!displayMenu) }} alt=""/></Link>
      </div>
      <div className="close-menu">
        <Link to="/" onClick={(): void => toggleMenu(!displayMenu)}><img src={closeMenuIcon} alt={t('header:functional.closeMenu')} /></Link>
      </div>
    </div>
  );

  const mobileMenuFooter =(
    <div className="mobile-menu-footer">
      <div className="socialmedia-buttons">
        <img className="socialmedia-icon" src={facebookIcon} alt="Facebook"/>
        <img className="socialmedia-icon" src={instagramIcon} alt="Instagram"/>
        <img className="socialmedia-icon" src={twitterIcon} alt="Twitter"/>
        <img className="socialmedia-icon" src={youtubeIcon} alt="YouTube"/>
        <img className="socialmedia-icon" src={linkedinIcon} alt="LinkedIn"/>
      </div>
      <div className="questions"><p>{t('header:navigation.questions')}</p></div>
      <div className="button button-white" onClick = {(): void => logUserOut()}>{t('header:functional.logout')}</div>
      { users.childUsers && <Link to="/" className="button button-green" onClick={ (): void => mobileChangeUserClick() }>{t('header:functional.swapUser')}</Link> }
    </div>
  );

  // Generate the links, including adding the active class if we are on that page.
  const menuLinks = linkItems.map(
    (link) => {
      return(
        <NavLink to={`/${link.key}`}
          key = { link.key }
          onClick={ (): void => toggleMenu(false) } >
          { link.text }
        </NavLink>
      );
    }
  );

  const menuItems = (
    <div className='links'>
      {menuLinks}
      { isMobile && mobileMenuFooter }
    </div>
  );

  const mobileMenuButton = (
    <div className="mobile-menu-icons">
      <button className="switch-user-icon" onClick={(): void => toggleMobileUserSwap(!mobileUserSwap)}><img src={userGroupIcon} alt={t('header:functional.openUserMenu')} /></button>
      <button className="mobile-menu-icon" onClick={(): void => toggleMenu(!displayMenu)}><img src={menuIconWhite} alt={t('header:functional.openMenu')} /></button>
    </div>
  );

  const availableUsers = [];
  if (users.activeUser.userId !== users.parentUser.userId|| users.activeUser.endpoint !== users.parentUser.endpoint) {
    // If user name is too long, then it will be cutted for shorter
    const parentUserName = cutString( users.parentUser.userName, 21 );
    availableUsers.push(<option key = { users.parentUser.userId } value = { users.parentUser.userId + '-' + users.parentUser.endpoint }> { parentUserName } </option>);
  }
  if (users.childUsers) {
    for (const child of users.childUsers) {

      if (users.activeUser.userId !== child.userId || users.activeUser.endpoint !== child.endpoint) {
        // If user name is too long, then it will be cutted for shorter
        const childCustomerName = cutString( child.userName, 21 );
        availableUsers.push(<option key= { child.userId + child.endpoint } value = { child.userId + '-' + child.endpoint }> {childCustomerName} </option>);
      }
    }
  }

  // If user name is too long, then it will be cutted for shorter
  const activeUserName = cutString( users.activeUser.userName, 21 );
  
  const userSelect = (
    <div className="select">
      <select id="choose-customership" onChange = { handleInputChange }>
        <option value="Choose"> { activeUserName } </option>
        { availableUsers }
      </select>
    </div>
  );

  const customership = (
    <div className="customership">
      <div className="inner">
        <button className="logout" onClick = {(): void => logUserOut()}><img src={padlock} alt="" />{t('header:functional.logout')}</button>
        <div className="user-group">
          <img src={userGroupIcon} alt="" />
        </div>
        <div className="container">
          <b className="customer-name">{activeUserName}</b>
          <p className="customer-number">{t('common:general.customerNumber')}: {users.activeUser.userId}</p>
          { users.childUsers && userSelect }
        </div>
      </div>
    </div>
  );

  let menuclass = 'menu';
  if (isMobile) {
      menuclass = 'mobile-menu';
  }
  if (mobileUserSwap) {
    menuclass = 'mobile-menu user-switching';
  }
  if (!isMobile && smallScreen) {
    menuclass = "menu small-screen-menu";
  }

  /* Changing theme based on user endpoint */
  const kajaveTheme = true;

  const loggedInContent = (
    <>
      { isMobile && mobileMenuButton }

      {/*  Displaying mobile menu and on mobile*/}
      { isMobile &&
        <div className={menuclass}>
          { displayMenu && mobileMenuHeader }
          { displayMenu && menuItems }
          { mobileUserSwap && <MobileUserSwap currentUser = { props.currentUser } toggleMobileUserSwap  = { toggleMobileUserSwap } />}
        </div>
      }

      {/* Displaying user change selection and menu below that if user has small screen */}
      { smallScreen && !isMobile &&
        <div className={menuclass}>
          { userSelect }
          <div className="logout" onClick = {(): void => logUserOut()}><img src={padlock} alt="" />{t('header:functional.logout')}</div>
        </div>
      }

      {/*  Displaying normal navigation bar and on wider screens*/}
      { !smallScreen && !isMobile &&
        <>
          <div className={menuclass}>
            { menuItems }
          </div>
          { customership }
        </>
      }

      { smallScreen && !isMobile && <div className="menu small-screen-menu small-screen-menu-bottom"> { menuItems }  </div>}
    </>
  );

  const header = (
    <div className={`header${userIdentified && smallScreen ? ' has-small-screen-menu' : ''}`}>
      <div className='company-logo'>
        <div className="row">
          <Link to="/"><img src={ userIdentified ? (kajaveTheme ? logoKajave : logo) : loginLogoOrg } alt={kajaveTheme ? 'Kajave' : 'Loiste'} /></Link>
        </div>
      </div>
      {userIdentified && loggedInContent}      
    </div>
  );

  return (
    header
  );
}

export default Header;
