import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import slugify from 'slugify';

// Types.
import { ArticlesProps } from '../../types/news';


const Articles:  React.FunctionComponent<ArticlesProps> = (props: ArticlesProps) => {
  const { t } = useTranslation([]);
  const { teaser, news } = props;

  let newsArray: JSX.Element[] = [];
  if (news) {
    newsArray = news.filter(Boolean).map((article, index) => {
      return (
        <Link to={`/article/${slugify(article.title).toLowerCase()}`} className="item" key = { index }>
          <h4>{ article.title && article.title }</h4>
        </Link>
      )
    });
  }
  const teaserNews = (
    <div id="news" className="tabcontent">
      { newsArray.slice(0, 3) }
      <div className="button button-white button-small">{ t('customerService:news.allNewsLink') }</div>
    </div>
  );

  const fullNews = (
    <div id="news" className="tabcontent">
      { newsArray }
    </div>
  );

  return (
    <div>
      { teaser && teaserNews }
      { !teaser && fullNews }
    </div>
  );
}

export default Articles;
