import React, { ChangeEvent, FormEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import slugify from 'slugify';

import Loading from '../Common/Loading';
// Actions.
import { fetchContractDetails, fetchOutageNotifications, createOutageNotifications, deleteOutageNotifications } from '../../actions';
// Types.
import { Contract } from '../../types/contract';
import { OutageContact, SingleOutageContact } from '../../types/customer';
// Utils.
import { generateAddress } from '../../utils/address';
import { validateEmail, validatePhoneNumber } from '../../utils/contact';
import { filterOutUndefinedContracts } from '../../utils/contracts';
import { gaEventIfAllowed } from '../../utils/googleAnalytics';

type CommunicationProps = {
  customerId: number;
}
type ContactList = {
  name: string;
  mail: string;
  number: string;
  isNewMail: boolean;
  isNewNumber: boolean;
}

const notificationTypeIds = [
  180,  // Primary
  130,  // First secondary.
  131   // Second secondary.
];

const electricityContractFilters = (contract: Contract | undefined): Contract | undefined => {
  const electricityContracts = [1, 2, 3, 17, 18, 19, 20, 21];
  if (contract?.contractGroup && electricityContracts.includes(contract?.contractGroup.contractGroupNo)) {
    return contract;
  }
}


export const OuttageNotifications: React.FunctionComponent<CommunicationProps> = (props: CommunicationProps) => {
  const [ showForm, setShowForm ] = React.useState<boolean>(false);
  const [ customerContracts, setCustomerContracts ] = React.useState<Contract[]>();
  const [ contactDetails, setContactDetails ] = React.useState<OutageContact[]>();
  const [ activeContract, setActiveContract ] = React.useState<number>();
  const [ contactList, setContactList ] = React.useState<ContactList[]>([{name: '', mail: '', number: '', isNewMail: true, isNewNumber: true}]);
  const [ validEmail, setValidEmail ] = React.useState<boolean[]>([]);
  const [ validNumber, setValidNumber ] = React.useState<boolean[]>([]);
  const [ updateLine, setUpdateLine ] = React.useState<[number, string] | undefined>();
  const [ updatedLine, setUpdatedLine ] = React.useState<string>('');
  const [ deleteLine, setDeleteLine ] = React.useState<[number, string] | undefined>();
  const [ contractsFetched, setContractsFetched ] = React.useState<boolean>(false);
  const [ updateLineStatus, setUpdateLineStatus ] = React.useState<(number | undefined)[]>([]);
  const { t } = useTranslation([]);
  const { customerId } = props;

  React.useEffect(() => {
    async function fetchContactDetails(): Promise<void> {
      const fetchedContracts = await fetchContractDetails(customerId, 'dso');
      const filterElectricityOnly = fetchedContracts.filter(electricityContractFilters);
      const filteredFetchedContracts = filterElectricityOnly.filter(filterOutUndefinedContracts);
      const fetchedOutageNotifications = await fetchOutageNotifications(customerId);

      setCustomerContracts(filteredFetchedContracts);
      setContactDetails(fetchedOutageNotifications);
      setContractsFetched(true);

      for (const contract of filteredFetchedContracts) {
        if (contract.meteringPoint) {
          setActiveContract(contract.contractNo);
          break;
        }
      }
    }

    if (!customerContracts) {
      setContractsFetched(false);
      fetchContactDetails();
    }
  }, [customerId, customerContracts]);

  React.useEffect(() => {
    if (contactDetails) {
      const activeContactDetails: ContactList[] = [];

      contactDetails.map((contact): undefined => {
        if (contact.contractNo === activeContract) {
          let mail = '';
          let number = '';
          const name = contact.name;
          let isNewMail = true;
          let isNewNumber = true;
          contact.notificationChannels.map((channel): undefined => {

            if (channel.isEnabled) {
              if (channel.notificationChannelType === 'Email') {
                mail = channel.notificationAddress;
                isNewMail = false;
              }
              else if (channel.notificationChannelType === 'Mobile') {
                number = channel.notificationAddress;
                isNewNumber = false;
              }
            }
            return undefined;
          });
          activeContactDetails.push({name, mail, number, isNewMail, isNewNumber});
          activeContactDetails.sort((a: ContactList, b: ContactList) => {
            let comparison = 0;
      
            if (a.name === 'Sähkökatko') {
              comparison = -1;
            }
            else if (b.name === 'Sähkökatko') {
              comparison = 1;
            }
      
            return comparison;
          });
        }
        return undefined;
      });

      setContactList(activeContactDetails);
      setValidEmail([true, true, true]);
      setValidNumber([true, true, true]);
    }
  }, [activeContract, contactDetails, setContactList]);

  React.useEffect(() => {

    async function updateContactDetails(): Promise<void> {

      if (updateLine !== undefined && activeContract !== undefined) {

        const contactLine: SingleOutageContact = {
          contractNo: activeContract,
          notificationTypeId: notificationTypeIds[updateLine[0]]
        }
        if (updateLine[1] === 'mail') {
          contactLine.mail = contactList[updateLine[0]].mail;
          contactLine.isNewMail = contactList[updateLine[0]].isNewMail;
        }
        else {
          contactLine.number = contactList[updateLine[0]].number;
          contactLine.isNewNumber = contactList[updateLine[0]].isNewNumber;
        }

        // In the front end we don't care what the end method will be and just post to our backend.
        const result = await createOutageNotifications(customerId, contactLine);

        if (result.status === 200) {
          const status = [...updateLineStatus];
          status[updateLine[0]] = result.status;
          setUpdateLineStatus(status);
          setUpdatedLine(updateLine[1]);
        }
        setUpdateLine(undefined);
      }
    }

    if (updateLine !== undefined) {
      updateContactDetails();
    }
  }, [updateLine, contactList, activeContract, customerId, updateLineStatus]);

  React.useEffect(() => {
    async function deleteContactDetails(): Promise<void> {

      if (deleteLine !== undefined && activeContract !== undefined) {
        const contactLine: SingleOutageContact = {
          contractNo: activeContract,
          notificationTypeId: notificationTypeIds[deleteLine[0]],
          isNewMail: false,
          isNewNumber: false
        }

        if (deleteLine[1] === 'mail') {
          contactLine.mail = contactList[deleteLine[0]].mail;
        }
        else {
          contactLine.number = contactList[deleteLine[0]].number;
        }

        // DELETE
        const result = await deleteOutageNotifications(customerId, contactLine);
        if (result.status === 200) {
          const status = [...updateLineStatus];
          status[deleteLine[0]] = result.status;
          setUpdateLineStatus(status);
          setUpdatedLine(deleteLine[1]);

          const list = contactList;
          if(deleteLine[1] === 'mail') {
            list[deleteLine[0]].mail = '';
            list[deleteLine[0]].isNewMail = true;
          }
          if(deleteLine[1] === 'number') {
            list[deleteLine[0]].number = '';
            list[deleteLine[0]].isNewNumber = true;
          }

          setContactList(list);
        }
        setDeleteLine(undefined);
      }
    }

    if (deleteLine !== undefined) {
      deleteContactDetails();
    }
  }, [deleteLine, contactList, activeContract, customerId, updateLineStatus]);

  const handleAddressChange = (event: ChangeEvent<HTMLSelectElement>): void => {
    event.persist();

    setActiveContract(parseInt(event.target.value));
  }

  const addContactUser = (): void => {
    setContactList([...contactList, {name: '', mail: '', number: '', isNewMail: true, isNewNumber: true}]);
  };

  const updateContactForm = (e: ChangeEvent<HTMLInputElement>, index: number): void => {
    const value: string = e.target.value;
    const name: string = e.target.name;
    const list = [...contactList];
    const validEmails = [...validEmail];
    const validNumbers = [...validNumber];
    const lineStatus = [...updateLineStatus];
    lineStatus[index] = undefined;
    setUpdateLineStatus(lineStatus);
    setUpdatedLine('');

    switch (name){
      case 'mail':
        list[index].mail = value;
        validEmails[index] = validateEmail(value);

        break;
      case 'number':
        list[index].number = value;
        validNumbers[index] = validatePhoneNumber(value);
        break;
    }
    setContactList(list);
    setValidEmail(validEmails);
    setValidNumber(validNumbers);
  };

  const submitContactForm = (e: FormEvent<HTMLFormElement>, index: number, name: string): void => {
    e.preventDefault();
    setUpdateLineStatus([]);

    gaEventIfAllowed({
      category: 'Omien tietojen päivittäminen',
      action: 'Keskeytysviestinnän yhteystiedot tallennettu',
      label: name
    });

    // If the current email or number are is valid.
    let validatedElement = false;
    if (name === 'mail') {
      validatedElement = validEmail[index];
    }
    else {
      validatedElement = validNumber[index];
    }
    if (validatedElement) {
      setUpdateLine([index, name]);
    }
  };


  const deleteRow = (e: MouseEvent<HTMLButtonElement>, index: number, name: string): void => {
    e.preventDefault();

    setDeleteLine([index, name]);
  };

  const addressOptions: JSX.Element[] = [];
  if (customerContracts) {
    customerContracts.map((contract, key): undefined => {
      if (contract.meteringPoint) {
        const address = generateAddress(contract.meteringPoint, false);
        addressOptions.push(<option key = { key } value = { contract.contractNo }>{ address }</option>);
      }
      return undefined;
    });
  };

  let formToDisplay;
  if (contactList.length > 0) {

    formToDisplay = contactList.map((channel, key) => {
      return (
        <div key = { `outtageForm-${key}` }>
          <h6>{t(`customerService:outtage.person${key}`)}</h6>
          <div className="form-group" key = { key }>
            <form onSubmit = { (e): void => submitContactForm(e, key, 'mail') }>
              <div className="form-item">
                <label htmlFor={`${slugify(channel.name)}-email`}>{ t(`customerService:outtage.email`) }</label>
                <div>
                  <input className="inline" type="email" id={`${slugify(channel.name)}-email`} name = 'mail' value = { channel.mail } onChange = { (e): void => updateContactForm(e, key) } />
                  { !validEmail[key] && <span className="outage-error">{t('common:contactform.email')}</span>}
                </div>
                <div className="buttons">
                  <button type = "submit" className="button-small button-blue" aria-label={`${t('customerService:outtage.update')} ${t(`customerService:outtage.email`)}`} disabled={!validEmail[key] ? true : false}>{ t('customerService:outtage.update') }</button>
                  <button onClick = { (e): void => deleteRow(e, key, 'mail') } className="button-small" aria-label={`${t('customerService:outtage.delete')} ${t(`customerService:outtage.email`)}`}>{ t('customerService:outtage.delete') }</button>
                </div>
              </div>
            </form>
            { updateLine && updateLine[0] === key && updateLine[1] === 'mail' && <Loading size="small" /> }
            { updatedLine === 'mail' && updateLineStatus[key] === 200 && <p aria-live="polite">{ t('customerService:outtage.updateSuccessful') }</p> }
            { updatedLine === 'mail' && updateLineStatus[key] && updateLineStatus[key] !== 200 && <p aria-live="polite">{t('customerService:outtage.updateError')}</p> }
            <form onSubmit = { (e): void => submitContactForm(e, key, 'number') }>
              <div className="form-item">
                <label htmlFor={`${slugify(channel.name)}-phone`}>{ t(`customerService:outtage.mobile`) }</label>
                <div>
                  <input className="inline" type="text" id={`${slugify(channel.name)}-phone`} name = 'number' value = { channel.number } onChange = { (e): void => updateContactForm(e, key) } />
                  {(!validNumber[key] || !channel.number) && <span className="outage-error">{t('common:contactform.phone')}</span>}
                </div>
                <div className="buttons">
                  <button type = "submit" className="button-small button-blue" aria-label={`${t('customerService:outtage.update')} ${t(`customerService:outtage.mobile`)}`} disabled={!validNumber[key] ? true : false}>{ t('customerService:outtage.update') }</button>
                  <button onClick = { (e): void => deleteRow(e, key, 'number') } className="button-small" aria-label={`${t('customerService:outtage.delete')} ${t(`customerService:outtage.mobile`)}`}>{ t('customerService:outtage.delete') }</button>
                </div>
              </div>
            </form>
            { updateLine && updateLine[0] === key && updateLine[1] === 'number' && <Loading size="small" /> }
            { updatedLine === 'number' && updateLineStatus[key] === 200 && <p aria-live="polite">{ t('customerService:outtage.updateSuccessful') }</p> }
            { updatedLine === 'number' && updateLineStatus[key] && updateLineStatus[key] !== 200 && <p aria-live="polite">{t('customerService:outtage.updateError')}</p> }
          </div>
        </div>
      )
    });
  }

  const addressOptionSelect = (
    <div className="dropdown col-6 center">
      <div className="select">
        <select id="address" onChange = { handleAddressChange }>
          { addressOptions }
        </select>
      </div>
    </div>
  );

  const contactForm = (
    <div className="contact-details">
      <div className="title">
        <h4 className="h2"> { t("contracts:consumption.place") }</h4>
      </div>
      { addressOptionSelect }
      <h5>{t('myInfo:contact.gridInturuptionNotification')}</h5>
      { formToDisplay }
      {contactList.length < 3 && <button onClick = { (): void => { addContactUser(); document.getElementById('address')!.focus(); } }>{ t('customerService:outtage.addContact') }</button>}{ /* eslint-disable-line */}
    </div>
  );

  // If there are no options then we don't want to show anything.
  let form = (<></>);
  // Show a loading if contracts haven't been fetched.
  if (!contractsFetched) {
    form = (<Loading size = 'small' />);
  }
  // If there are options then we will show the form.
  if (addressOptions.length > 0) {
    form = (
      <div className="outtage">
        <h3>{t('myInfo:contact.gridInturuptionNotificationOptions')}</h3>

        <button className="button button-white submit" onClick = { (): void => setShowForm(!showForm) }>{t('myInfo:gridInterruption.editUserInfo')}</button>
        { showForm && contractsFetched && contactForm }
        { showForm && !contractsFetched && <Loading /> }
      </div>
    );
  }


  return(
    form
  );
}
