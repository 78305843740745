import { AxiosResponse } from 'axios';

import API from '../Api';


export const fetchPdf = async (userId: number, contractNo: number, billNumber: number, endpoint: string): Promise<Blob> => {

  const billDetails: AxiosResponse<Blob> = await API({
      method: 'get',
      url: `/billPdf/${endpoint === 'dh' ? 'dso' : endpoint}/${userId}/${contractNo}/${billNumber}`,
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
      }
    });

  return billDetails.data;
}
