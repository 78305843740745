import React from 'react';
import { useTranslation } from 'react-i18next';

type InfoboxProps = {
  endpoint: string;
}

const Infobox: React.FunctionComponent<InfoboxProps> = (props: InfoboxProps) => {
  const { endpoint } = props;
  const { t } = useTranslation([]);
  return (
    <div className="right col-9">
      <h1 className="h2" aria-live="polite">{ t(`customerService:contact-us.contactTitle`) }</h1>
      <p> { t(`customerService:contact-us.${endpoint}.contactUsParagraphOne`)} </p>
      <p> { t(`customerService:contact-us.${endpoint}.contactUsParagraphTwo`)} </p>
      <p> { t(`customerService:contact-us.${endpoint}.contactUsParagraphThree`)} </p>
    </div>
  );
}
export default Infobox;
