import React, { ChangeEvent } from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import Loading from '../Common/Loading';
// Actions.
import { getInvoicesPostponable } from '../../actions/invoices';
import { validateToken } from '../../actions';
// Types.
import { ConsumptionLocationProps, Invoice, postPonableObject } from '../../types/billing';
// Images.
import { energyConsumptionIcon, electricBills } from  '../../icons';
// Utils.
import { setPriceFormat } from '../../utils/billing';
import { generateAddress } from '../../utils/address';
import { contractIsClosedOrOtherType } from '../../utils/contracts';


const ConsumptionLocation:  React.FunctionComponent<ConsumptionLocationProps> = (props: ConsumptionLocationProps) => {
  const { contracts, currentActiveUser, endpoint, selectedMeteringPointDetails, invoices, setRetLedger, setDsoLedger, setDhLedger } = props;
  const { t } = useTranslation([]);
  const [ customerType, setCustomerType ] = React.useState<string>('private');
  const addressOptions: Array<JSX.Element> = [];
  const usedAddresses: Array<string> = [];
  const hideInvoiceComment = [7,15,11,8,33,31,29];

  const [postponableInvoices, setPostponableInvoices] = React.useState<postPonableObject[]>([]);

  React.useEffect(() => {
    let unMounted = false;

    async function updateCustomerType(): Promise<void> {
      const userDetails = await validateToken();

      const activeUserId = currentActiveUser.userId;

      let customerEndpoint;
      
      if (currentActiveUser.endpoint === 'ret') {
        customerEndpoint = userDetails?.ret;
      }
      if (currentActiveUser.endpoint === 'dso') {
        customerEndpoint = userDetails?.dso;
      }
      if (currentActiveUser.endpoint === 'dh') {
        customerEndpoint = userDetails?.dso;
      }

      const currentCustomerType = customerEndpoint?.customerRoleChild?.find(child => child.customerIdChild === activeUserId)?.customerTypeChild.toLowerCase();

      if (currentCustomerType && !unMounted) {
        setCustomerType(currentCustomerType);
      }
    }

    updateCustomerType();

    return (): void => {
      unMounted = true;
    };
  }, [currentActiveUser, endpoint]);

  contracts.map((contract): undefined => {
    // Only show active contracts.
    if (contract.meteringPoint && contract.meteringPointId && !contractIsClosedOrOtherType(contract)) {
      const streetAddress = hideInvoiceComment.includes(contract.contractType.contractTypeNo) ? generateAddress(contract.meteringPoint, false) : generateAddress(contract.meteringPoint, false, contract.invoiceComment);
      if (!usedAddresses.includes(streetAddress)) {
        addressOptions.push(<option key = { `${contract.contractNo}-${contract.meteringPointId}` } value = { contract.meteringPointId }>{ streetAddress }</option>)
        usedAddresses.push(streetAddress);
      }
    }
    return undefined;
  });

  // Sort the contracts alphabetically by address.
  addressOptions.sort(function(a, b) {
    const addressA = a.props.children.toLowerCase();
    const addressB = b.props.children.toLowerCase();
    return (addressA < addressB) ? -1 : (addressA > addressB) ? 1 : 0;
  });

  const handleInputChange = (event: ChangeEvent<HTMLSelectElement>): void => {

    event.persist();

    setRetLedger({
      contractNo: 0,
      ledgerNo: 0,
    });
    setDsoLedger({
      contractNo: 0,
      ledgerNo: 0,
    });
    setDhLedger({
      contractNo: 0,
      ledgerNo: 0,
    });
    for (const contract of contracts) {
      if (contract.meteringPointId === event.target.value) {
        selectedMeteringPointDetails.setSelectedMeteringPoint({
          meteringPointId: contract.meteringPointId,
          startDate: contract.startDate,
          endDate: contract.endDate
        });
        if (contract.endpoint === 'ret' && !contractIsClosedOrOtherType(contract)) {
          setRetLedger({
            contractNo: contract.contractNo,
            ledgerNo: contract.ledgerNo,
          });
        }
        if (contract.endpoint === 'dso' && !contractIsClosedOrOtherType(contract)) {
          setDsoLedger({
            contractNo: contract.contractNo,
            ledgerNo: contract.ledgerNo,
          });
        }
        if (contract.endpoint === 'dh' && !contractIsClosedOrOtherType(contract)) {
          setDhLedger({
            contractNo: contract.contractNo,
            ledgerNo: contract.ledgerNo,
          });
        }
      }
    }
  }

  let currentRet: JSX.Element | boolean = (false);
  let currentDso: JSX.Element | boolean = (false);
  let currentDh: JSX.Element | boolean = (false);

  if (invoices && invoices.length === 0) {
    currentRet = (<div className="title"> <h2> { t("contracts:consumption.noLastMonth") } </h2> </div>);
  }
  if (invoices && invoices[0]) {
    const filterOutUndefined = (value: Moment.Moment | undefined): value is Moment.Moment => {
      return value !==  undefined;
    }
    const retDates = invoices.map((invoice): Moment.Moment | undefined => {
      if (invoice.invoiceGroup === 'ret') {
        return Moment(invoice.invoiceDate)
      }
      return undefined;
    });
    const dsoDates = invoices.map((invoice): Moment.Moment | undefined => {
      if (invoice.invoiceGroup === 'dso') {
        return Moment(invoice.invoiceDate)
      }
      return undefined;
    });
    const dhDates = invoices.map((invoice): Moment.Moment | undefined => {
      if (invoice.invoiceGroup === 'dh') {
        return Moment(invoice.invoiceDate)
      }
      return undefined;
    });
    const filteredRetDates = retDates.filter(filterOutUndefined);
    const filteredDsoDates = dsoDates.filter(filterOutUndefined);
    const filteredDhDates = dhDates.filter(filterOutUndefined);
    const mostRecentRet = Moment.max(filteredRetDates);
    const mostRecentDso = Moment.max(filteredDsoDates);
    const mostRecentDh = Moment.max(filteredDhDates);
    let newestRetInvoice: Invoice | undefined = undefined;
    let newestDsoInvoice: Invoice | undefined = undefined;
    let newestDhInvoice: Invoice | undefined = undefined;

    for (const invoice of invoices) {
      if (Moment(invoice.invoiceDate).isSame(mostRecentRet) && invoice.invoiceGroup === 'ret') {
        newestRetInvoice = invoice;
      }
      if (Moment(invoice.invoiceDate).isSame(mostRecentDso) && invoice.invoiceGroup === 'dso') {
        newestDsoInvoice = invoice;
      }
      if (Moment(invoice.invoiceDate).isSame(mostRecentDh) && invoice.invoiceGroup === 'dh') {
        newestDhInvoice = invoice;
      }
    };

    const isInvoicePostponable = (customerId: number, contractNo: string, invoiceNo: string, endpoint: string): void => {
      async function postPonable(): Promise<void> {
        const postponableRequest = await getInvoicesPostponable( customerId, contractNo, invoiceNo, endpoint );

        if(typeof postponableRequest === 'object') {
          postponableRequest.invoiceNo = invoiceNo;
          postponableInvoices.push(postponableRequest);

          setPostponableInvoices(postponableInvoices);
        }
      }
      if(customerId !== 0) {
        postPonable();
      }
    };

    if (newestRetInvoice) {
      const newestRetInvoicePrice = `${setPriceFormat(newestRetInvoice.invoiceSum.toString())} €`;

      const newestInvoice = newestRetInvoice;

      isInvoicePostponable(newestInvoice.customerId, newestInvoice.contractNo.toString(), newestInvoice.invoiceNo, newestInvoice.invoiceGroup);

      const postponableInvoice = postponableInvoices.find(inv => inv.invoiceNo === newestInvoice?.invoiceNo);

      currentRet = (
        <div>
          <div className="title"> <h2> { t("contracts:consumption.lastMonthConsumption") } </h2> </div>
          {postponableInvoice && Moment(postponableInvoice.dueDate).isSameOrAfter(Moment().format('YYYY-MM-DD')) && postponableInvoice.hasPaymentAgreement !== 1 && postponableInvoice.postponable === 1 && customerType !== 'business' &&
            <div className="postpone bold-text">
              { t("contracts:consumption.billOpen") }
              <Link to="/billing" className="button button-small">{ t("contracts:consumption.postponeBill") }</Link>
            </div>
          }
          <div className="kWh">{newestRetInvoicePrice}</div>
        </div>
      );
    }
    if (newestDsoInvoice) {
      const newestDsoInvoicePrice = `${setPriceFormat(newestDsoInvoice.invoiceSum.toString())} €`;

      const newestInvoice = newestDsoInvoice;

      isInvoicePostponable(newestInvoice.customerId, newestInvoice.contractNo.toString(), newestInvoice.invoiceNo, newestInvoice.invoiceGroup);

      const postponableInvoice = postponableInvoices.find(inv => inv.invoiceNo === newestInvoice?.invoiceNo);

      currentDso = (
        <>
          <div className="title"> <h2> { t("contracts:consumption.lastMonthTransfer") } </h2> </div>
          {postponableInvoice && Moment(postponableInvoice.dueDate).isSameOrAfter(Moment().format('YYYY-MM-DD')) && postponableInvoice.hasPaymentAgreement !== 1 && postponableInvoice.postponable === 1 && customerType !== 'business' &&
            <div className="postpone bold-text">
              { t("contracts:consumption.billOpen") }
              <Link to="/billing" className="button button-small">{ t("contracts:consumption.postponeBill") }</Link>
            </div>
          }
          <div>
            <div className="kWh">{newestDsoInvoicePrice}</div>
          </div>
        </>
      );
    }
    if (newestDhInvoice) {
      const newestDhInvoicePrice = `${setPriceFormat(newestDhInvoice.invoiceSum.toString())} €`;

      const newestInvoice = newestDhInvoice;

      isInvoicePostponable(newestInvoice.customerId, newestInvoice.contractNo.toString(), newestInvoice.invoiceNo, newestInvoice.invoiceGroup);

      const postponableInvoice = postponableInvoices.find(inv => inv.invoiceNo === newestInvoice?.invoiceNo);
      currentDh = (
        <>
          <div className="title"> <h2> { t("contracts:consumption.lastMonthHeating") } </h2> </div>
          {postponableInvoice && Moment(postponableInvoice.dueDate).isSameOrAfter(Moment().format('YYYY-MM-DD')) && postponableInvoice.hasPaymentAgreement !== 1 && postponableInvoice.postponable === 1 && customerType !== 'business' &&
            <div className="postpone bold-text">
              { t("contracts:consumption.billOpen") }
              <Link to="/billing" className="button button-small">{ t("contracts:consumption.postponeBill") }</Link>
            </div>
          }
          <div>
            <div className="kWh">{newestDhInvoicePrice}</div>
          </div>
        </>
      );
    }
  }

  return(
    <div>
      <div className="title">
        <h2> { t("contracts:consumption.place") } </h2>
      </div>

      <div className="dropdown center">
        <div className="select">
          <select id="address" value={ selectedMeteringPointDetails.selectedMeteringPoint.meteringPointId } onChange = { handleInputChange }>
            { addressOptions }
          </select>
        </div>
      </div>

      <div className="liftups">
        <div className="liftup col-6 direction-column">
          <div className="inner bg-green">
            <div className="icon"> <img src={energyConsumptionIcon} alt=""/> </div>
            <div className="title"><h2>{ endpoint === 'dh' ? t("contracts:consumption.currentMonthConsumption1DH") : t("contracts:consumption.currentMonthConsumption1") }</h2></div>
            <div className="text"><p>{ t("contracts:consumption.currentMonthInfo") }</p></div>
            <a href="https://www.fingrid.fi/sahkomarkkinat/datahub/kirjautuminen-datahubin-asiakasportaaliin/" className="button button-large button-white" target="_blank" rel="noopener noreferrer">{ t("contracts:consumption.consumptionDetailsLink") }</a>
          </div>
        </div>

        <div className="liftup col-6 direction-column">
          <div className="inner bg-light-gray">
            <div className="icon"> <img src={electricBills} alt=""/> </div>
            { !currentRet && !currentDso && !currentDh && <Loading />}
            { currentRet }
            { currentDso }
            { currentDh }
            <div className="text"><p>  { t("contracts:consumption.lastMonthInfo") } </p></div>
            <Link to="/billing" className="button button-large button-white">{ t("contracts:consumption.billsLink") }</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConsumptionLocation;
