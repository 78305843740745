import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import slugify from 'slugify';

import Loading from '../Common/Loading';
// Utils.
import { cutString } from '../../utils/header';
// Images.
import defaultImage1 from '../../images/liftup-default-1.jpg';
import defaultImage2 from '../../images/liftup-default-2.jpg';
import defaultImage3 from '../../images/liftup-default-3.jpg';
// Types.
import { NewsLiftupProps } from '../../types/news';


const CurrentNews:  React.FunctionComponent<NewsLiftupProps> = (props: NewsLiftupProps) => {
  const { news, activeArticle } = props;
  const { t } = useTranslation([]);

  const images = [
    defaultImage1,
    defaultImage2,
    defaultImage3
  ]

  const numberOfArticlesToShow = (news.length > 3) ? 3: news.length;
  const articlesToShow = news.filter(article => article !== activeArticle).slice(0, numberOfArticlesToShow).map((article, key) => {
    let liftUp = (
      <Loading key={key} />
    );

    if (article && article.description && article.images) {
      const description = article.description;
      const cuttedDescription = cutString(description, 300);
      const descriptionToShow = parse(cuttedDescription);
      const articleImages = article.images;

      let liftUpImage;

      if(article.images.length > 0) {
        liftUpImage = articleImages[0];
      }

      liftUp = (
        <Link to={`/article/${slugify(article.title).toLowerCase()}`} className="liftup news col-4 col-md-6 direction-column" key = { key } >
          <div className="top">
            <img src={typeof liftUpImage === 'object' ? `${process.env.REACT_APP_DRUPAL_BASE_URL}${liftUpImage.url}` : images[key]} alt=""/>
          </div>
          <div className="bottom">
            <h3>{ article.title }</h3>
            { descriptionToShow }
            <span className="button button-white button-small">{ t('common:general.readMore') }</span>
          </div>
        </Link>
      );
    }
    return liftUp;
  });

  return(
    <div className="liftup-container">
      <div className="liftups">
        { articlesToShow }
      </div>
    </div>
  )
}

export default CurrentNews;
